import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { media } from '../../utils/rwd';
import { transparentButton } from '../../utils/styled-components/mixins';

export const Wrapper = styled.div`
  background: white;
`;

export const List = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const Item = styled.button`
  ${transparentButton}
  padding: 0;
  cursor: pointer;
  flex: 0 0 100%;
  max-width: 100%;
  ${media.sm`
    flex: 0 0 calc(50%);
  `}
  ${media.lg`
    flex: 0 0 calc(33.333%);
  `}

  :hover {
    img {
      transform: scale(1.2);
    }
  }
`;

export const SectionTitle = styled(Item)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 125px;
  font-weight: 900;
  text-align: center;

  p {
    margin: 0;
    line-height: 0.5;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  padding: 10px;
  background: #fff;
  flex-direction: column;
`;

export const ImageWrapper = styled.button`
  width: 100%;
  position: relative;
  border: none;
  display: inline-block;
  overflow: hidden;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const Name = styled.p`
  font-size: 14px;
  margin: 0;
  font-size: ${({ theme }) => theme.artistLookItem.title.fontSize};
  font-weight: ${({ theme }) => theme.artistLookItem.title.fontWeight};
  font-family: ${({ theme }) => theme.artistLookItem.title.fontFamily};

  font-weight: 700;
  text-transform: uppercase;
  ${media.lg`
    font-weight: 400;
  `}
`;

export const Vanity = styled(Name)`
  text-transform: lowercase;
  font-weight: normal;
  color: grey;
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease;
  vertical-align: middle;
`;

/* MAC STYLES */

export const MacHero = styled.div`
  padding: 64px 36px 61px 36px;
  background: #000000;
  color: #ffffff;

  * {
    color: inherit;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 0;
  }

  ${media.lg`
    padding: 50px 36px;
    h1 {
      font-size: 54px;
      line-height: 64px;
    }
  `}
`;

export const MacList = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 32px 16px;
  gap: 16px;

  ${media.lg`
    padding: 32px 20px;
  `}
`;

export const MacItem = styled.div`
  padding: 0;
  max-width: 100%;
  flex: 0 0 calc(50% - 8px);

  ${media.lg`
    flex: 0 0 calc(33.333% - 12px);
  `}
`;

export const MacImageWrapper = styled.div`
  width: 100%;
  position: relative;
  border: none;
  display: inline-block;
  overflow: hidden;
  :before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`;

export const MacContentWrapper = styled.div`
  display: flex;
  padding: 20px 0 10px 0;
  flex-direction: column;

  ${media.lg`
    padding: 16px 0 50px 0;
  `}
`;

export const MacName = styled.p`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 20px;
  text-transform: uppercase;

  ${media.lg`
    font-size: 24px;
    line-height: 28px;
  `}
`;

export const MacDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 28px;
  ${media.lg`
    margin-bottom: 32px;
  `}
`;

export const MacLink = styled(Link)`
  font-family: AnoBold;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: underline;
  text-transform: uppercase;
  color: #000000;

  :hover {
    color: #000000;
  }
`;
