import React from 'react';

export const LeftCarouselArrow = ({ disabled = false }: { disabled?: boolean }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" cx="30" cy="30" r="30" transform="rotate(-180 30 30)" fill="white" />
    <path
      d="M36 18L24 30L36 42"
      stroke={disabled ? '#747474' : 'black'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export const RightCarouselArrow = ({ disabled = false }: { disabled?: boolean }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle opacity="0.5" cx="30" cy="30" r="30" fill="white" />
    <path
      d="M24 42L36 30L24 18"
      stroke={disabled ? '#747474' : 'black'}
      strokeWidth="2"
      stroke-Linecap="round"
    />
  </svg>
);
