/* eslint-disable no-useless-escape */
// There are a lot of youtube link formats
// https://gist.github.com/rodrigoborgesdeoliveira/987683cfbfcc8d800192da1e73adc486
const ytIdRegex = '(?<id>[a-zA-Z0-9-_]+)';
// dQw4w9WgXcQ
const ytRegex = new RegExp(`^${ytIdRegex}$`);
// www.youtube.com/watch?v=dQw4w9WgXcQ
const ytRegex1 = new RegExp(`youtube\.com\/watch\\?v=${ytIdRegex}`);
// youtube.be/dQw4w9WgXcQ
const ytRegex2 = new RegExp(`youtu\.be\/${ytIdRegex}`);
// www.youtube.com/v/dQw4w9WgXcQ
// www.youtube.com/e/dQw4w9WgXcQ
// www.youtube.com/embed/dQw4w9WgXcQ
const ytRegex3 = new RegExp(`youtube\.com\/(v|e|embed)\/${ytIdRegex}`);

const chooseRegex = (youtube: string): RegExp | undefined =>
  [ytRegex, ytRegex1, ytRegex2, ytRegex3].find((regex) => youtube.match(regex));

const youtubeUrlPrefix = 'https://www.youtube.com/embed/';

export const youtubeIdFromInput = (youtube: string): string => {
  const regex: RegExp | undefined = chooseRegex(youtube);
  if (regex) {
    return youtube.match(regex)?.groups?.id || '';
  }
  return '';
};

export const youtubeUrlFromInput = (youtube: string): string =>
  youtubeUrlPrefix + youtubeIdFromInput(youtube);
