import styled from 'styled-components';
import { EBrand } from '../../typings';
import { HorizontalList } from '../HorizontalList/HorizontalList';

export const List = styled(HorizontalList)`
  display: flex;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 12px;
  gap: 12px;
  overflow: auto;
  width: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  scrollbar-width: none;

  .product-box {
    display: flex;
    max-width: 300px;
    ${({ theme }) =>
      theme.brand === EBrand.Aveda &&
      'min-width: 230px;'} // TODO inelegant way to prevent aveda cards from being distorted
    flex: 1;
  }
`;

export const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  width: 57px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;
