import styled from 'styled-components';
import { Container } from '../Container/Container.styles';
import { Button } from '../Button/Button';
import { media } from '../../utils/rwd';
import { hideScrollBars } from '../../utils/styled-components/mixins';

const GAP_VALUE_MD = 12;
const GAP_VALUE_LG = 40;
const GAP_VALUE_XL = 70;

export const ContentContainer = styled(Container)<{ offsetTop?: number }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  padding: 0;
  gap: ${GAP_VALUE_MD}px;

  ${media.md`
    padding: 20px 20px 0 20px;
    flex-direction: row;
    height: ${({ offsetTop }) => `calc(100vh - ${offsetTop}px)`} ;
  `};

  ${media.lg`
    box-sizing: border-box;
    margin: 0 auto;
    gap: ${GAP_VALUE_LG}px;
  `}

  ${media.xl`
    gap: ${GAP_VALUE_XL}px;
  `}
`;

export const CoverPhotoColumn = styled.div`
  position: relative;
  width: 100%;
  position: relative;
  overflow-y: scroll;
  ${hideScrollBars}

  ${media.md`

    width: calc(58% - ${GAP_VALUE_MD / 2}px);
  `}

  ${media.lg`
    width: calc(58% - ${GAP_VALUE_LG / 2}px);
  `}

  ${media.xl`
    width: calc(58% - ${GAP_VALUE_XL / 2}px);
  `}
`;

export const CoverPhotoWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
`;

export const CoverPhoto = styled.img`
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  img {
    cursor: auto;
  }
`;

export const CarouselArrowWrapper = styled.div<{ arrow: 'left' | 'right' }>`
  position: absolute;
  top: calc(50% - 30px);
  z-index: 1;
  cursor: pointer;
  ${({ arrow }) =>
    arrow === 'left' &&
    `
    left: 15px;
  `}

  ${({ arrow }) =>
    arrow === 'right' &&
    `
  right: 15px;
`}
`;

export const Slide = styled.div`
  width: 190%;
`;

export const Image = styled.img`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ContentColumn = styled.div<{ noPaddingTop: boolean }>`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: ${({ noPaddingTop }) => (noPaddingTop ? '0 20px 20px 20px' : '20px')};
  position: relative;

  ${hideScrollBars}

  ${media.md`
    padding: 0;
    width: calc(42% - ${GAP_VALUE_MD / 2}px);
    padding-right: ${GAP_VALUE_MD}px;
  `}
  ${media.lg`
    padding-right: 0;
    width: calc(42% - ${GAP_VALUE_LG / 2}px);
  `}
  ${media.xl`
    width: calc(42% - ${GAP_VALUE_XL / 2}px);
  `}
`;

export const ContentWrapper = styled.div`
  padding-bottom: 30px;
`;

export const ContentHeading = styled.h2`
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-top: 12px;
  ${media.lg`
    margin-top:0
  `}
`;

export const LookDescription = styled.p`
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0;
`;

export const ProductListWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  gap: ${GAP_VALUE_MD}px;
  padding-bottom: 70px;
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  ${media.sm`
    width: calc(50% - ${GAP_VALUE_MD / 2}px);
  `}

  > div {
    min-width: unset;
  }
`;

export const ClickingArea = styled.button`
  position: absolute;
  top: -10px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 20px;
  background: transparent;
  border: none;
`;

export const Checkbox = styled.div<{ isChecked: boolean }>`
  background: ${({ isChecked }) => (isChecked ? '#000' : '#dbd5d5')};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: ${({ isChecked }) => (isChecked ? '1px solid #000' : '1px solid #a49f9f')};
`;

export const FixedBarWrapper = styled.div`
  z-index: 10;
  height: 75px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 101;
  padding: 0 20px;
  background: white;

  ${media.md`
    position: sticky;
    height: 0px;
    bottom: auto;
    top: calc(100% - 75px);
    width: 100%;
    padding: 0;
  `}
`;

export const FixedBar = styled.div`
  height: 75px;
  width: 100%;
  background: white;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  p {
    font-family: ${({ theme }) => theme.fontFamily.bold};
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    margin: 0;
  }
`;

export const DotsWrapper = styled.div`
  ${media.lg`
  margin-top: 8px;
  `}

  .BrainhubCarousel__dots {
    justify-content: flex-start;
  }
  .BrainhubCarousel__dot:before {
    width: 6px;
    height: 6px;
    background: black;
  }
`;

export const FixedBarButton = styled(Button)``;
