/* eslint-disable */
import React, { useEffect, useState, FC } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';

import * as Ui from './Dropdown.styles';

type TDropdownProps = {
  defaultOpen?: boolean;
  selectedDefault: (option) => boolean;
  options: Array<any>;
  renderTrigger: (selectedOption: any, open: boolean) => JSX.Element;
  renderOption: (option: any) => JSX.Element;
};

export const Dropdown: FC<TDropdownProps> = ({
  defaultOpen = false,
  renderOption,
  renderTrigger,
  options = [],
  selectedDefault,
  ...rest
}) => {
  const [open, setOpen] = useState(defaultOpen);
  const [selected, setSelected] = useState({});
  const ref = useClickOutside(() => setOpen(false));

  useEffect(() => {
    setSelected(options.filter(selectedDefault)[0]);
  }, [selectedDefault]);

  return (
    <Ui.Dropdown ref={ref} {...rest}>
      <div
        onClick={() => {
          if (options.length > 1) {
            setOpen(!open);
          }
        }}
        className="dropdown-trigger"
      >
        {renderTrigger(selected, open)}
      </div>
      {open && (
        <div className="dropdown-list">
          <div>
            {options.map((option) => (
              <div
                onClick={() => {
                  setSelected(option);
                  setOpen(!open);
                }}
              >
                {renderOption(option)}
              </div>
            ))}
          </div>
        </div>
      )}
    </Ui.Dropdown>
  );
};
