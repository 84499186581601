import React, { FC, useCallback } from 'react';
import { Footer, Topbar, CheckoutContext, EBrand, useCurrentPath, breakpoints } from '@elc/common';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { Outlet, RouteObject } from 'react-router-dom';

import { Basket, BasketIcon } from '../basket/basket';
import MacLogoWhite from '../../assets/mac-white-logo.png';
import { Page, Wrapper } from './storeLayout.styles';

import useCheckoutFlow from '../../hooks/useCheckoutFlow';
import footerLinks from '../../constants/footerLinks';
import languages from '../../constants/languages';

const StoreLayout: FC = () => {
  const { t } = useTranslation(['app']);
  const paths = useCurrentPath();
  const handleCheckout = useCheckoutFlow();
  const handleCheckoutClick = () => handleCheckout();
  const handleBannerClickContext = useCallback((path: string) => {
    handleCheckout({ path, source: 'banner' });
  }, []);
  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });

  const shouldHideFooter =
    paths &&
    isSmallerThenMd &&
    paths.find((path) => (path.route as RouteObject & { name: string }).name === 'Look');

  const isTopBarLinkVisible =
    paths &&
    paths?.find((path) =>
      ['Look', 'Product'].includes((path.route as RouteObject & { name: string }).name),
    );

  return (
    <Wrapper>
      <Topbar
        linkVisible={!isTopBarLinkVisible}
        brand={EBrand.Mac}
        text={t('topbar.shop')}
        onClick={handleCheckoutClick}
      >
        <BasketIcon />
      </Topbar>
      <Basket />

      <CheckoutContext.Provider value={handleBannerClickContext}>
        <Page noPadding>
          <Outlet />
        </Page>
      </CheckoutContext.Provider>
      {!shouldHideFooter && (
        <Footer
          size={100}
          logo={MacLogoWhite}
          links={footerLinks}
          languages={languages}
          showDisclaimer
        />
      )}
    </Wrapper>
  );
};

export default StoreLayout;
