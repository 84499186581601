import React from 'react';
import styled from 'styled-components';
import { TRelatedContent } from '../../../typings';
import { ImageWrapper } from '../LooksList.styles';
import { analiticsVideo } from '../../../utils/googleAnalitics';

const VideoPlayer = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const VideoContent = ({ item }: { item: TRelatedContent }) => (
  <ImageWrapper disabled>
    <VideoPlayer
      src={item.url}
      onPlay={(event) => analiticsVideo(event, item.resource_type, item.url)}
      onPause={(event) => analiticsVideo(event, item.resource_type, item.url, false)}
      controls
    />
  </ImageWrapper>
);
