import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { transparentButton } from '../../utils/styled-components/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorsWrapper = styled.div<{ visibleColors: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
    max-width: ${({ visibleColors }) => `calc(30px *  ${visibleColors})`};
    margin: 0 5px;
    overflow: hidden;
    border-radius: 20px;
  }
  .circle {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex-shrink: 0;
    border: 1px solid rgba(0, 0, 0, 0.05);
    position: relative;
    font-size: 0;
    &.active::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 8px;
      left: 10px;
      top: 10px;
    }
  }
`;

export const CloseIcon = styled(MdClose).attrs({
  size: 20,
})`
  position: absolute;
  top: calc(50% - 10px);
  right: calc(50% - 10px);
  cursor: pointer;
  fill: white;
`;

export const ArrowButton = styled.button<{ visible: boolean; direction: 'left' | 'right' }>`
  ${transparentButton}
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  padding: ${({ direction }) => (direction === 'left' ? '10px 0 10px 10px' : '10px 10px 10px 0px')};
  line-height: 13px;
`;

export const Arrow = styled.button<{ direction: 'left' | 'right' }>`
  margin: 0;
  cursor: pointer;
  padding: 0;
  outline: 0;
  z-index: 100;
  top: 20px;
  border: solid black;
  border-width: 0 3px 3px 0;
  height: 13px;
  width: 13px;
  min-width: unset;
  min-height: unset;
  background: transparent;
  transform: ${({ direction }) => (direction === 'left' ? 'rotate(135deg)' : 'rotate(315deg)')};
`;

export const ColorName = styled.p<{ color: string }>`
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  margin: 0 auto;
  font-size: 14px;
  color: ${({ color }) => color};
  margin-top: 7px;
  font-family: AnoBold, Helvetica, Arial, sans-serif;
  text-align: center;
`;
