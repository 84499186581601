import React, { FC, useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { ColorSearch } from '../ColorSearch/ColorSearch';
import { breakpoints } from '../../utils/rwd';

import * as Ui from './ColorPickerExtended.styles';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { TColorWithSkuUid } from '../..';

type TTabsProps = {
  tabs: Array<string>;
  onClick: (tab: string) => void;
};

const Tabs: FC<TTabsProps> = ({ tabs, onClick }) => {
  const [active, setActive] = React.useState('all');
  const tabRef = React.useRef<HTMLDivElement>(null);
  const [buttonVisibility, setButtonVisibility] = React.useState({ right: false, left: false });

  React.useEffect(() => {
    if (tabRef.current) {
      setButtonVisibility((prevState) => ({
        ...prevState,
        right: (tabRef.current?.clientWidth || 0) < (tabRef?.current?.scrollWidth || 0),
      }));

      tabRef?.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Ui.TabWrapper>
      <div
        ref={tabRef}
        style={{
          display: 'flex',
          overflowX: 'hidden',
        }}
      >
        {['all', ...tabs].map((tab) => {
          const isActive = tab === active;

          return (
            <Ui.TabItemWrapper key={tab}>
              <Ui.TabItem
                active={isActive}
                onClick={() => {
                  setActive(tab);
                  onClick(tab);
                }}
              >
                {tab}
              </Ui.TabItem>
            </Ui.TabItemWrapper>
          );
        })}
      </div>
      {buttonVisibility.right && (
        <Ui.RightArrow
          onClick={() => {
            setButtonVisibility({ ...buttonVisibility, left: true });
            if (tabRef?.current?.scrollLeft) {
              tabRef.current.scrollLeft += 50;
            }
          }}
        />
      )}
      {buttonVisibility.left && tabRef?.current?.scrollLeft !== 0 && (
        <Ui.LeftArrow
          onClick={() => {
            setButtonVisibility({ ...buttonVisibility, left: true });
            if (tabRef?.current?.scrollLeft) {
              tabRef.current.scrollLeft -= 50;
            }

            if (tabRef?.current?.scrollLeft === 0) {
              setButtonVisibility({ ...buttonVisibility, left: false });
            }
          }}
        />
      )}
    </Ui.TabWrapper>
  );
};

type TColorPickerExtendedProps = {
  colors: Array<TColorWithSkuUid>;
  onClick: (color: TColorWithSkuUid) => void;
  initialColorSku: string;
};

const ColorPickerExtended = ({
  colors = [],
  onClick,
  initialColorSku,
  ...rest
}: TColorPickerExtendedProps) => {
  const [active, setActive] = React.useState<TColorWithSkuUid | null>(null);

  const [visibleColors, setVisibleColors] = React.useState<Array<TColorWithSkuUid>>([]);
  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });

  React.useEffect(() => {
    setActive(colors.find((color) => color.skuUid === initialColorSku) || colors[0]);
    setVisibleColors(colors);
  }, []);

  const colorCategories: Array<string> = colors
    .reduce((acc, color) => {
      if (color.category_info) {
        return [...acc, color.category_info];
      }
      return acc;
    }, [] as Array<string>)
    .filter((el) => el != null);

  const handleColorSelection = (color) => {
    setActive(color);
    onClick(color);
  };

  const handleCategorySelection = (category) => {
    if (category === 'all') {
      setVisibleColors(colors);
    } else {
      setVisibleColors(colors.filter((color) => color.category_info === category));
    }
  };

  const selectedDefault = useCallback((option) => option.skuUid === active?.skuUid, [active]);

  return (
    <div {...rest}>
      {colorCategories.length > 0 && (
        <Tabs tabs={colorCategories} onClick={handleCategorySelection} />
      )}

      {isSmallerThenMd ? (
        <ColorPicker
          onClick={(color) => handleColorSelection(color)}
          colors={visibleColors}
          defaultActive={active?.name || ''}
          showActiveColor={false}
        />
      ) : (
        <div className="d-flex flex-wrap">
          {visibleColors.map((color) => (
            <Ui.Color
              key={color.id}
              onClick={() => handleColorSelection(color)}
              active={active?.id === color.id}
              color={color.hex_value}
            />
          ))}
        </div>
      )}

      <div style={{ margin: '24px 0' }}>
        <ColorSearch
          colors={colors}
          selectedDefault={selectedDefault}
          onClick={(option) => handleColorSelection(option)}
        />
      </div>
    </div>
  );
};

export default ColorPickerExtended;
