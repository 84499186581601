import styled from 'styled-components';

import { Tabs as TabsComponent, media } from '@elc/common';

export const ContentWrapper = styled.div`
  width: 100%;

  ${media.lg`
    max-width: 1440px;
    padding: 0 80px;
    box-sizing: border-box;
    margin: 0 auto;
  `}
`;

export const Tabs = styled(TabsComponent)`
  margin-top: 20px;
  font-size: 12px;
  font-weight: 700;
  font-family: AnoBold;
  ${media.lg`
    margin-top: 0px;
  `}
`;
