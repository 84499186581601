import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media, breakpoints } from '../../utils/rwd';

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  background: #f1f3f6;
  display: flex;
  flex-direction: column;
`;

export const PrimarySection = styled.h1`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  text-transform: capitalize;
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 16px;
  text-align: center;
  ${media.lg`
    font-size: 60px;
    line-height: 75px;
  `}
`;

export const PrimarySubtitle = styled.h4`
  text-align: center;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 36px;
`;

export const PostsGrid = styled.div`
  display: flex;
  width: 100%;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;

export const PostCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius};
  width: 100%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background: #ffffff;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }

  ${media.md`
  width: calc(50% - 24px);

`}

  ${media.xl`
    width: calc(33% - 24px);
  `}
`;

export const StyledLink = styled(Link)`
  width: 100%;
`;

export const PostImage = styled.div`
  position: relative;
  object-fit: cover;
  width: 100%;
  padding-bottom: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const PostColumn = styled.div`
  padding: 12px 24px;
`;

export const PostTitle = styled.h2`
  font-weight: 400;
  padding: 12px 0 24px 0;
  border-bottom: 1px solid lightgrey;
  text-align: center;
  margin-bottom: 24px;
  text-transform: ${({ theme }) => theme?.learningCenter?.post?.title?.textTransform || 'none'};
`;

export const PostDescription = styled.div`
  font-size: 14px;

  ${media.lg`
    font-size: 16px;
    line-height: 18px;
  `}
`;

export const SecondarySection = styled.h2`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  text-transform: capitalize;
  font-size: 35px;
  line-height: 50px;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 16px;
  text-align: center;
  ${media.lg`
font-size: 45px;
line-height: 60px;
`}
`;

export const SecondaryTitle = styled.h4`
  text-align: center;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 36px;
`;

export const ButtonLink = styled.a`
  align-self: center;
  padding: 16px 80px;
  background: #000000;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  text-transform: ${({ theme }) => theme?.learningCenter?.button?.textTransform || 'none'};
  &:hover {
    color: #ffffff;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.1;
  background-color: ${({ theme }) => theme.colors.black};
  margin: 5px 0px;
`;
