import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ProductDetailsPopup } from './ProductDetailsPopup';
import { EBrand } from '../../typings/enums/Brands';
import { MacProductCard } from './brands/MacProductCard/MacProductCard';
import {
  EInventory,
  SAddToCart,
  SNamedOrderedProdcutDetailsClicked,
  TProduct,
  TSelectedSku,
} from '../../typings';
import { useProductSelection } from '../../hooks/product/useProductSelection';
import { useImageLazyLoading } from '../..';
import { AvedaProductCard } from './brands/AvedaProductCard/AvedaProductCard';

type ProductCardProps = {
  product: TProduct;
  onAddToCart: SAddToCart;
  onProductDetailsClick?: SNamedOrderedProdcutDetailsClicked;
  onModalClose?: () => void;
  onVariantClick?: (selectedSku: TSelectedSku) => void;
  visibleColors?: number;
  onImageClick?: (product: TProduct) => void;
};

export const ProductCard = ({
  product,
  onAddToCart,
  onProductDetailsClick,
  onModalClose,
  onVariantClick,
  ...rest
}: ProductCardProps) => {
  const { brand } = useContext(ThemeContext);
  useImageLazyLoading(`product-image-${product._id}`, true);

  const { selectedSku, handleVariantSelection, variants, type } = useProductSelection(
    product,
    onVariantClick,
  );

  const isActive = selectedSku.inventory.toUpperCase() === EInventory.ACTIVE;

  // test
  const renderPopup = (trigger: JSX.Element) => (
    <ProductDetailsPopup
      trigger={trigger}
      product={product}
      type={type}
      selectedSku={selectedSku}
      handleVariantSelection={handleVariantSelection}
      onAddToCart={onAddToCart}
      variants={variants}
      onProductDetailsClick={onProductDetailsClick}
      onModalClose={onModalClose}
      isActive={isActive}
    />
  );

  // TO DO refactor
  if (brand === EBrand.Mac) {
    return (
      <MacProductCard
        product={product}
        selectedSku={selectedSku}
        type={type}
        variants={variants}
        handleVariantSelection={handleVariantSelection}
        onAddToCart={() => onAddToCart(selectedSku)}
        renderPopup={renderPopup}
        isActive={isActive}
        {...rest}
      />
    );
  }

  return (
    <AvedaProductCard
      product={product}
      selectedSku={selectedSku}
      type={type}
      variants={variants}
      handleVariantSelection={handleVariantSelection}
      onAddToCart={() => onAddToCart(selectedSku)}
      renderPopup={renderPopup}
      isActive={isActive}
      {...rest}
    />
  );
};
