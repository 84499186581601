import React, { FC, useEffect, useState } from 'react';
import {
  isModalOpenSelector,
  modalActions,
  E2EWrapper,
  basketQuantitySelector,
  basketSelector,
  lastAddedItemsSelector,
  AddedToBasketPreview,
  basketAction,
} from '@elc/common';

import { useDispatch, useSelector } from 'react-redux';
import { BasketWrapper, StyledPopup, BasketQuantity } from './basket.styles';
import { Content } from './content';
import { Header } from './header';
import useCheckoutFlow from '../../hooks/useCheckoutFlow';
import { ReactComponent as Icon } from '../../assets/svg/basket-icon.svg';

type TBasketIconProps = {
  colorRevers?: boolean;
};

export const BASKET_MODAL_ID = 'basket-modal-id';

export const BasketIcon: FC<TBasketIconProps> = ({ colorRevers }) => {
  const basketQuantity = useSelector(basketQuantitySelector);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(modalActions.openModal(BASKET_MODAL_ID));
  };

  return (
    <E2EWrapper id="openCartButton">
      <BasketWrapper id="basket-icon" onClick={handleClick} colorRevers={!!colorRevers}>
        <BasketQuantity colorRevers={!!colorRevers} key={basketQuantity}>
          {basketQuantity}
        </BasketQuantity>
        <Icon />
      </BasketWrapper>
    </E2EWrapper>
  );
};

export const Basket = () => {
  const basket = useSelector(basketSelector);
  const basketQuantity = useSelector(basketQuantitySelector);
  const dispatch = useDispatch();
  const isOpen = useSelector(isModalOpenSelector(BASKET_MODAL_ID));
  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });
  const justAddedToBasket = useSelector(lastAddedItemsSelector);

  useEffect(() => {
    if (isOpen) {
      // @ts-ignore
      const [basketIcon] = [...document.querySelectorAll('#basket-icon')].filter(
        (node) => node.offsetWidth,
      );

      const position = basketIcon?.getBoundingClientRect();

      if (position) {
        setPopupPosition({
          top: position.top + position.height + 17,
          right: window.innerWidth - (position.left || 0) - 35,
        });
      }
    }
  }, [isOpen]);

  const handleCheckoutClick = useCheckoutFlow();

  const closeModal = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <AddedToBasketPreview
        items={justAddedToBasket}
        clearLastAddedItems={() => dispatch(basketAction.clearLastAddedItems())}
        openBasket={() => {
          dispatch(modalActions.openModal(BASKET_MODAL_ID));
        }}
      />
      <StyledPopup
        open={isOpen}
        closeOnDocumentClick
        onClose={closeModal}
        popupPostionTop={popupPosition.top}
        arrowPosition={popupPosition.right}
      >
        <div style={{ height: '100%' }}>
          <Header basketQuantity={basketQuantity} onClose={closeModal} />
          <Content basket={basket} onCheckout={handleCheckoutClick} />
        </div>
      </StyledPopup>
    </div>
  );
};
