import React, { ReactNode } from 'react';
import * as Ui from './CenteredCard.styles';

type TCenteredCard = {
  title: ReactNode;
  content: ReactNode;
};

export const CenteredCard = ({ title, content }: TCenteredCard) => (
  <Ui.Wrapper>
    <Ui.Card>
      <Ui.Title>{title}</Ui.Title>
      <Ui.Text>{content}</Ui.Text>
    </Ui.Card>
  </Ui.Wrapper>
);
