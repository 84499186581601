import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import getOrFp from 'lodash/fp/getOr';
import {
  ArtistProfileSection,
  Feedback,
  CenteredCard,
  StatusWrapper,
  EBrand,
  LooksList,
  useQueryParams,
  WelcomePopup,
  analyticsSetPageDataArtist,
  analyticsPromoView,
  analyticsTabSelected,
  createLookLink,
  useAddProductToCart,
} from '@elc/common';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GlobalState, useGlobalState, useThunkDispatch } from '../../store';
import {
  fetchPrivateArtistProfile,
  fetchArtist,
  fetchArtistLook,
} from '../../store/actions/artist.actions';
import { Metatags } from '../../components/Metatags/Metatags';
import { increaseEntriesCounter } from '../../utils/increaseEntriesCounter';
import { BasketIcon } from '../../components/basket/basket';

import {
  artistFavoritesLenghtSelector,
  artistLooksSelector,
  isArtistLoadedSelector,
  relatedContentSelector,
} from '../../store/selectors/artist.selectors';
import { bundleAction } from '../../store/slices/bundle.slice';
import { Favorites } from '../../components/Favorites/Favorites';

import * as Ui from './Artist.styles';

const ArtistScreen: FC = () => {
  const { vanity_url = '' } = useParams<{ vanity_url?: string }>();

  const { token = '', preview, feedback: addFeedback } = useQueryParams();

  const { t } = useTranslation(['app', 'pages']);
  const navigate = useNavigate();
  const dispatch = useThunkDispatch();

  const { status, item: artist } = useGlobalState(getOrFp({} as GlobalState['artist'], 'artist'));
  const isArtistLoaded = useSelector(isArtistLoadedSelector);
  const looks = useSelector(artistLooksSelector);
  const favoritesNumber = useSelector(artistFavoritesLenghtSelector);
  const relatedContent = useSelector(relatedContentSelector);

  const addProductToCart = useAddProductToCart();

  const isDataReady = artist.vanity_url === vanity_url || status !== 200;

  useEffect(() => {
    analyticsSetPageDataArtist(vanity_url);
  }, [vanity_url]);

  useEffect(() => {
    if (token) {
      dispatch(fetchPrivateArtistProfile({ vanity_url, token }));
    } else {
      dispatch(fetchArtist(vanity_url));
    }
  }, [vanity_url, token, preview]);

  useEffect(() => {
    if (!artist.vanity_url || !isDataReady) return;

    if (!preview) {
      increaseEntriesCounter(artist.uid, 'PROFILE');
    }
    // @ts-ignore
    analyticsPromoView(relatedContent.map((c, idx) => [c, idx + looks.length + 1]));
  }, [artist.vanity_url]);

  const handleShopNowClick = (look) => {
    dispatch(bundleAction.setPendingStatusForLook());
    navigate(
      createLookLink(look, preview === 'true', !!addFeedback, token, {
        artistName: artist.vanity_url,
      }),
      {
        state: {
          from: 'artist',
        },
      },
    );
  };

  const getTabsNames = () => {
    const lookName =
      looks.length === 1
        ? `${looks.length} ${t('pages:artist.tabs.looks.singular')} `
        : `${looks.length} ${t('pages:artist.tabs.looks.plural')}`;

    const favoriteName =
      favoritesNumber === 1
        ? `${favoritesNumber} ${t('pages:artist.tabs.favorites.singular')} `
        : `${favoritesNumber || ''} ${t('pages:artist.tabs.favorites.plural')} `;

    return [
      { text: lookName, id: 'looks' },
      { text: favoriteName, id: 'favorites' },
    ];
  };

  if (!isDataReady) {
    return null;
  }

  return (
    <StatusWrapper
      status={status}
      componentsForStatus={{
        403: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenNeeded')}
          />
        ),
      }}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.artistNotFound')} />
      }
    >
      <>
        <Metatags title={artist.fullName} description={artist.about} image={artist.picture} />
        {addFeedback && token && <Feedback uid={artist.uid} token={token} />}

        <WelcomePopup />
        <ArtistProfileSection artist={artist} ready={isArtistLoaded} brand={EBrand.Mac}>
          <BasketIcon />
        </ArtistProfileSection>

        <Ui.ContentWrapper>
          <Ui.Tabs
            tabs={getTabsNames()}
            tabSelectedCallback={(idx) => {
              if (idx === 0) analyticsTabSelected('looks');
              else analyticsTabSelected('favorites');
            }}
          >
            <LooksList
              onShopNowClick={handleShopNowClick}
              looks={looks || []}
              fetchArtistLook={fetchArtistLook}
              // @ts-ignore
              relatedContent={isDataReady ? relatedContent : []}
              addProductToCart={addProductToCart}
            />
            <Favorites />
          </Ui.Tabs>
        </Ui.ContentWrapper>
      </>
    </StatusWrapper>
  );
};
export default ArtistScreen;
