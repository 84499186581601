import styled from 'styled-components';
import { AddToCartButton as Btn } from '../AddToCartButton/AddToCartButton';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 90px;
  ${media.sm`
  display: none;
`}
`;

export const AddToCartButton = styled(Btn)`
  margin: 0;
`;

export const ProductPreview = styled.div<{ visible: boolean }>`
  position: absolute;
  bottom: 90px;
  left: 0;
  min-height: 90px;
  width: 100%;
  background: white;
  transform: ${({ visible }) => `translateY(${visible ? '0' : '100%'});`};

  transition-duration: 1s;
  transition-timing-function: cubic-bezier;
  z-index: 1;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;

  // to do : delete and use common button component
  button.add-to-bag {
    background: #120e02;
    color: #fff;
    border: 1px solid #120e02;
    font-weight: 400;
    font-size: 12px;
    font-family: AnoBold;
    text-transform: uppercase;
    text-align: center;
    border-radius: 45px;
    padding: 16px 20px;
    cursor: pointer;
  }
`;

export const VariantPickerWrapper = styled.div`
  > div {
    align-items: flex-start;
  }

  p {
    margin: 7px 0 0 20px;
  }
`;

export const ProductName = styled.p`
  font-size: 16px;
  margin-bottom: 14px;
  padding-right: 23px;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  font-weight: 700;
  text-transform: uppercase;
`;

export const BottomSectionWrapper = styled.div`
  display: flex;
  align-items: strech;
  justify-content: space-between;
  gap: 15px;
  select {
    margin-bottom: 0;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  position: relative;
  background: #fff;
  flex-wrap: nowrap;
  width: 100%;
  gap: 5px;
  cursor: grab;
  padding: 5px;
  z-index: 2;
`;

export const Image = styled.img<{ active: boolean }>`
  width: 80px;
  height: 80px;
  object-fit: contain;
  background: ${({ theme }) => theme.productCard.image.background};
  ${({ active }) =>
    active &&
    `
    border: 1px solid black;
  `}
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  padding: 10px;
  top: 0px;
  background: transparent;
  border: none;
`;
