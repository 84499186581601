import styled from 'styled-components';
import { media } from '../../utils/rwd';
import { Button as Btn } from '../Button/Button';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${media.lg`
    flex-direction: row;
  `}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  ${media.lg`
 width: 50%;
`}
`;

export const ContentCol = styled(Col)`
  flex: 1;
  padding: 12px;
  ${media.lg`
    padding: 20px 20px 20px 0;
    height: 100%;
    overflow-y: scroll;
  `}
`;

export const CarouselCol = styled(Col)`
  align-items: center;
  justify-content: flex-start;
  flex: 1;
`;

export const VariantPicker = styled.div`
  width: 80%;
  margin: 12px auto;
`;

export const ColorName = styled.h4.attrs(({ className = '' }) => ({
  className,
}))<{ color: string }>`
  font-size: ${({ theme }) => theme.productCard.colorName.fontSize};
  font-weight: 500;
  line-height: ${({ theme }) => theme.productCard.colorName.lineHeight};
  color: ${({ color }) => color};
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  margin: 4px 0 0 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Name = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  text-transform: uppercase;
  padding-right: 0;

  ${media.md`
    padding-right: 35px;
  `}
`;

export const Price = styled.p`
  ${media.md`
    font-size: 20px
  `}

  ${media.xl`
    font-size: 24px;
  `}
`;

export const Description = styled.p``;

export const Ingredients = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .column {
    width: calc(50% - 10px);
  }

  li {
    padding-bottom: 8px;
  }
`;

export const Disclaimer = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.italic};
  font-style: italic;
`;

export const ButtonRow = styled(Row)`
  margin-top: 12px;
  text-align: center;
  padding-bottom: 36px;
`;

export const Button = styled(Btn)`
  margin: 0 auto;
`;
