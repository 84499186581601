import styled from 'styled-components';
import { media } from '../../utils/rwd';
import { ReactComponent as CloseIcon } from '../../assets/svg/cross.svg';

export const Overlay = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${({ isOpen }) => (isOpen ? '101' : '-1')};
  top: 0;
  left: 0;
  bottom: 0;
  transition: all 600ms linear;
  overflow-x: hidden;
  overflow-y: auto;

  backdrop-filter: ${({ isOpen }) => (isOpen ? 'blur(6px)' : 'blur(0px)')};
`;

export const Modal = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  bottom: ${({ isOpen }) => (isOpen ? '0' : '-50%')};
  transition: all 300ms ease-in-out;
  z-index: 9999;
  background: white;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;

  ${media.sm`
    width: 500px;
    left: 50%;
    margin-left: -250px;
  `}
`;

export const Header = styled.div`
  padding: 20px;
  border-bottom: 1px solid lightgrey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h6 {
    margin-bottom: 0;
  }
`;

export const List = styled.ul`
  list-style: none;
  padding: 0 20px;
`;

export const Item = styled.li`
  padding: 8px 0;

  button {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  p {
    font-size: 14px;
    margin: 0;
  }
`;

export const CopyButtonIcon = styled.div<{ copied: boolean }>`
  border-radius: 50%;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${({ copied }) => (copied ? 'green' : 'transparent')};
  transition: all 600ms linear;

  svg {
    fill: ${({ copied }) => (copied ? 'white' : 'black')};
  }
`;

export const CopyButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  position: relative;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;

export const Tooltip = styled.span`
  font-size: 12px;
  color: green;
  position: absolute;
  z-index: 9999;
  bottom: 6px;
  right: -50%;
`;
