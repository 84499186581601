import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Count = styled.span`
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 15px;
  margin-left: 5px;
  margin-bottom: 3px;
`;
