import { css } from 'styled-components';

export const hideScrollBars = css`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const transparentButton = css`
  background: transparent;
  border: none;
`;

export const linkWithoutStyling = css`
  color: unset;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: unset;
  }
`;
