import React, { FC } from 'react';
import { MdClose } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { BasketHeader } from './basket.styles';

export const Header: FC<{ onClose: () => void; basketQuantity: number }> = ({
  onClose,
  basketQuantity,
}) => {
  const { t } = useTranslation(['app']);

  return (
    <BasketHeader alignEnd={!basketQuantity}>
      {!!basketQuantity && <h5>{`${t('basket.yourBag')} (${basketQuantity})`}</h5>}
      <MdClose style={{ cursor: 'pointer' }} size={30} onClick={onClose} />
    </BasketHeader>
  );
};
