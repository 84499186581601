import styled from 'styled-components';
import Ctr from '../Container/Container';

export const Wrapper = styled(Ctr)`
  display: flex;
  gap: 8px;
  z-index: 2;
  padding: 5px 0;
  a,
  p {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};
    margin: 0;
  }
`;
