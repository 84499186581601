import React, { FC, useContext } from 'react';
import { Accordion as Acc, Button, Card, AccordionContext as AccContext } from 'react-bootstrap';
import { useAccordionToggle as useAccToggle } from 'react-bootstrap/AccordionToggle';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import * as UI from './Accordion.styles';

type TProps = {
  items: Array<{
    header: string;
    body: string | JSX.Element;
  }>;
};

const Toggle = ({ children, eventKey }: { children: React.ReactNode; eventKey: string }) => {
  // @ts-ignore
  const activeEventKey = useContext(AccContext);

  const isCurrentEventKey = activeEventKey === eventKey;

  const decoratedOnClick = useAccToggle(eventKey, () => {});

  return (
    <Button variant="link" onClick={decoratedOnClick}>
      <>
        {isCurrentEventKey ? (
          <MdKeyboardArrowUp size="24px" />
        ) : (
          <MdKeyboardArrowDown size="24px" />
        )}
        {children}
      </>
    </Button>
  );
};

export const Accordion: FC<TProps> = ({ items = [] }) => (
  <UI.Accordion defaultActiveKey="0">
    {items.map((item, index) => (
      <Card key={item.header}>
        <Card.Header>
          <Toggle eventKey={`${index}`}>{item.header}</Toggle>
        </Card.Header>
        <Acc.Collapse eventKey={`${index}`}>
          <Card.Body>{item.body}</Card.Body>
        </Acc.Collapse>
      </Card>
    ))}
  </UI.Accordion>
);
