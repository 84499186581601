import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBundle, IFetchableItem, TLook } from '@elc/common';
import { TArtistState } from '../../types/MacArtist';

// import { TArtistBundleState } from '../slices/artistBundles.slice';

export const fetchBundle = createAsyncThunk(
  'bundle/fetchBundleById',
  async (
    {
      shortId,
      token,
    }: {
      shortId: string;
      token?: string | null;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const { artist } = getState() as {
        artist: IFetchableItem<TArtistState>;
      };

      // @ts-ignore
      const [lookFromStore] = artist.item.looks.filter((l) => l?.shortId === shortId);

      if (lookFromStore) {
        return lookFromStore;
      }

      const { data: response } = await getBundle(shortId, token);

      if (response) {
        return response.store;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
  {
    condition: ({ shortId }, { getState }) => {
      const { bundle } = getState() as { bundle: IFetchableItem<TLook> };
      if (bundle.item.short_id === shortId) {
        return false;
      }
    },
  },
);
