import styled from 'styled-components';
import Ctr from '../Container/Container';

export const Wrapper = styled(Ctr)`
  .cover-photo-column {
    padding: 0;
  }

  .container {
    padding: 0 !important;
    min-height: 80vh;
  }
`;

export const LookListItemWrapper = styled.div`
  .shop-now-bar {
    display: flex;
  }

  .look-products-preview {
    display: flex;
  }

  .looks-list-item__description {
    display: flex;
  }

  .looks-list-item__content-wrapper {
    padding: 0 20px 40px 20px;
  }

  .looks-list-item__views-bars {
    display: flex;
  }
`;
