import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { media } from '../../utils/rwd';

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #000000;
  width: 100%;
  min-height: 300px;
  padding: 44px;
`;

export const Logo = styled.img<{ size: number }>`
  max-height: ${({ size }) => size}px;
`;

export const Disclaimer = styled.div`
  color: white;
  font-size: 12px;
  line-height: 15px;
  margin-top: 22px;
  text-align: center;
`;

export const LinksRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 28px;
  margin-top: 22px;
  justify-content: center;
  ${media.sm.down`
    flex-direction: column;
    align-items: center;
    gap: 16px;
  `}
`;

export const Divider = styled.div`
  background: #ffffff;
  width: 1px;
  height: 14px;
  display: block;
  ${media.sm.down`
    display: none;
  `}
`;

const LinkStyle = `
  text-decoration: none;
  color: #ffffff;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0;
  text-transform: uppercase;
  &:hover {
    color: #ffffff;
    text-decoration: underline;
  }

`;

export const PageLink = styled(Link)`
  ${LinkStyle}
`;

export const ExternalLink = styled.a`
  ${LinkStyle}
`;

export const LanguagePicker = styled(Dropdown)`
  margin-top: 35px;

  .show > .btn-primary.dropdown-toggle {
    background: inheirt;
    border: none;
    box-shadow: none;
  }

  .dropdown-toggle {
    background: inherit;
    border: none;
  }
`;
