import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.colors.white};
`;

export const ImageContainer = styled.div`
  flex: 1 0 ${({ theme }) => theme.singleBundle.image.width.small};

  ${media.xl`
    flex: 1 0 ${({ theme }) => theme.singleBundle.image.width.medium};
  `}

  ${media.xxl`
    flex: 1 0 ${({ theme }) => theme.singleBundle.image.width.big};
  `}

  ${media.lg.down`
    width: 100%;
    flex: 1 0 0;
  `}
`;

export const Image = styled.div`
  object-fit: cover;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  border-radius: ${({ theme }) => theme.borderRadius};

  img,
  svg {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  ${media.lg.down`
    border-radius: 0;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-right: 12px;

  ${media.lg.down`
      flex-direction: column;
      padding: 0 ;
  `}
`;

export const ProductsListWrapper = styled.div`
  margin-left: 19px;
  width: 100%;
  overflow: hidden;
  ${media.lg.down`
    margin-left: 0;
    background: #f0f3f6;
    padding: 12px 0;
  `}
`;

export const ClickableHeader = styled(Link)`
  cursor: pointer;
  text-decoration: none;

  &:hover {
    h1 {
      text-decoration: underline;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 15px 12px 15px;
`;

export const Title = styled.h1.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme?.singleBundle?.title?.color || '#ffffff'};
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 4px 0;
  ${media.md.down`
    font-size: 14px;
  `}
`;

export const Description = styled.div<{ theme: DefaultTheme }>`
  color: ${({ theme }) => theme?.singleBundle?.description?.color || '#ffffff'};
  opacity: ${({ theme }) => theme?.singleBundle.description?.opacity || '1.0'};
  line-height: 1.5;
  font-size: 14px;
  flex: 1;
  ${media.md.down`
    font-size: 13px
  `}
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.05;
  background-color: ${({ theme }) => theme.colors.black};
  margin-bottom: 15px;
  margin-top: 8px;
`;
