import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  breakpoints,
  LearningCenterPost as LearningCenterPostComponent,
  TPost,
  useScrollToTop,
} from '@elc/common';
import styled from 'styled-components';
import { useLangagueAwareLearingCenter } from '../hooks/useLangaugeAwareLearingCenter';

const Wrapper = styled.div`
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  height: 100%;
  min-height: calc(100vh - 380px);
  background: #f1f3f6;
  display: flex;
  flex-direction: column;
`;

type LearningCenterPostRouteParams = { postUid: string };

const LearningCenterPost = (): JSX.Element | null => {
  const { postUid } = useParams<LearningCenterPostRouteParams>();
  useScrollToTop();

  const learingCenter = useLangagueAwareLearingCenter();
  const [post, setPost] = useState<TPost | null>(null);

  useEffect(() => {
    if (learingCenter) {
      const [selectedPost] = learingCenter.posts.filter((p) => p.uid === postUid);
      if (selectedPost) {
        setPost(selectedPost);
      }
    }
  }, [learingCenter]);

  if (!post) return null;

  return (
    <Wrapper>
      <LearningCenterPostComponent post={post} />
    </Wrapper>
  );
};

export default LearningCenterPost;
