import { createSelector } from '@reduxjs/toolkit';
import { MainState } from '../types';
import { FEATURED_ARTISTS_REDUCER_KEY } from './featuredArtists.constants';

const selectSelf = (state: MainState) => state[FEATURED_ARTISTS_REDUCER_KEY];

export const featuredArtistsSelector = createSelector(selectSelf, (state) => state.items);
export const isfeaturedArtistsLoadingSelector = createSelector(
  selectSelf,
  (state) => state.loading,
);
