import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TModalState = {
  modalId: string;
};

const initialState: TModalState = {
  modalId: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<string>) => {
      state.modalId = action.payload;
    },
    closeModal: (state) => {
      state.modalId = '';
    },
  },
});

export const modalActions = {
  ...modalSlice.actions,
};

export const modalReducer = modalSlice.reducer;
