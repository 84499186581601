import React from 'react';
import { Skeleton, SkeletonRectangle } from '../../Skeleton/Skeleton';
import { HorizontalSocialsSkeleton } from '../../Socials/HorizontalSocials/skeleton/HorizontalSocials.skeleton';
import * as Ui from '../ShortArtistProfileSection.styles';

export const ShortArtistProfileSectionSkeleton = ({
  withDescription = false,
}: {
  withDescription?: boolean;
}) => {
  const currentLocation = window.location.pathname + window.location.search;
  return (
    <Ui.Wrapper>
      <Ui.Row>
        <Ui.LinkSection isLink={false} to={currentLocation}>
          <Skeleton width={72} height={100} viewBox="0 0 72 100">
            <circle r="36" cx="36" cy="36" />
            <SkeletonRectangle width="72" height="15" y="78" />
          </Skeleton>
        </Ui.LinkSection>
        <Ui.Col>
          <Ui.LinkSection isLink={false} to={currentLocation}>
            <Ui.BundlesNumber>
              <Skeleton width={40} height={25} viewBox="0 0 40 25">
                <SkeletonRectangle width="30" height="25" x="5" />
              </Skeleton>
            </Ui.BundlesNumber>
            <Ui.BundlesCaption isInsideLink={false}>
              <Skeleton width={40} height={20} viewBox="0 0 40 20">
                <SkeletonRectangle width="40" height="15" y="5" />
              </Skeleton>
            </Ui.BundlesCaption>
          </Ui.LinkSection>
        </Ui.Col>
        <HorizontalSocialsSkeleton />
      </Ui.Row>
      <Ui.Description>
        {withDescription && (
          <Skeleton style={{ width: '40%' }} height={25}>
            <SkeletonRectangle style={{ width: '100%' }} height="10" />
            <SkeletonRectangle style={{ width: '100%' }} height="10" y="15" />
          </Skeleton>
        )}
      </Ui.Description>
    </Ui.Wrapper>
  );
};
