import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import * as Ui from './Footer.styles';

export const Footer = ({
  logo,
  links,
  size = 32,
  languages,
  showDisclaimer,
}: {
  logo: string;
  links: Array<{ labelPath: string; href: string; external: boolean }>;
  size?: number;
  languages?: Array<{
    name: string;
    code: string;
  }>;
  showDisclaimer: boolean;
}) => {
  const [language, setLanguage] = useState(
    languages?.find((lgn) => lgn.code === i18next.language)?.name,
  );
  const { t } = useTranslation(['app', 'components']);

  return (
    <Ui.Footer>
      <Ui.Logo src={logo} size={size} />
      {showDisclaimer && <Ui.Disclaimer>{t('components:disclaimer')}</Ui.Disclaimer>}
      <Ui.LinksRow>
        {links.map((link, index) => (
          <>
            {link.external ? (
              <Ui.ExternalLink key={link.labelPath} href={link.href}>
                {t(link.labelPath)}
              </Ui.ExternalLink>
            ) : (
              <Ui.PageLink key={link.labelPath} to={link.href}>
                {t(link.labelPath)}
              </Ui.PageLink>
            )}
            {index !== links.length - 1 && <Ui.Divider />}
          </>
        ))}
      </Ui.LinksRow>
      {languages && (
        <Ui.LanguagePicker>
          <Ui.LanguagePicker.Toggle
            style={{ background: 'inherit', border: 'none', boxShadow: 'none' }}
          >
            {language}
          </Ui.LanguagePicker.Toggle>
          <Ui.LanguagePicker.Menu>
            {languages.map((lng) => (
              <Ui.LanguagePicker.Item
                key={lng.code}
                onClick={() => {
                  setLanguage(lng.name);
                  i18next.changeLanguage(lng.code);
                }}
              >
                {lng.name}
              </Ui.LanguagePicker.Item>
            ))}
          </Ui.LanguagePicker.Menu>
        </Ui.LanguagePicker>
      )}
    </Ui.Footer>
  );
};
