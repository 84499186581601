import { numberOfLooks } from '@elc/common';
import { createSelector } from '@reduxjs/toolkit';

import { compose, get, isEqual, getOr, find } from 'lodash/fp';
import { GlobalState } from '..';

const selectSelf = (state: GlobalState) => state.artist;

export const artistSelector = createSelector(selectSelf, (state) => state.item);

export const artistLooksNumberSelector = createSelector(
  selectSelf,
  compose(numberOfLooks, get('item')),
);

export const artistLooksSelector = createSelector(artistSelector, ({ looks }) => looks);

export const artistFavoritesSelector = createSelector(
  artistSelector,
  ({ favorites }) => favorites || [],
);

export const artistFavoritesLenghtSelector = createSelector(
  artistSelector,
  ({ favorites, stores }) =>
    favorites?.length || stores.filter((store) => store.is_single_product).length,
);

export const areFavoritesLoadingSelector = createSelector(
  artistSelector,
  ({ favorites }) => favorites === null,
);

export const isArtistLoadedSelector = createSelector(
  selectSelf,
  compose(isEqual('fullfield'), get('loading')),
);

export const relatedContentSelector = createSelector(selectSelf, (state) =>
  // @ts-ignore
  state.item.related_brand_content?.filter((c) => !c.is_deleted),
);

export const promoCodeSelector = createSelector(
  selectSelf,
  compose(getOr('', 'offer_code'), find('offer_code'), get('item.related_brand_content')),
);
