import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { media } from '../../utils/rwd';

export const StyledPopup = styled(Popup)`
  &-content {
    width: 100%;
    height: 100vh;
    overflow: auto !important;
    border-radius: none;
    padding: 0 !important;
    ${media.md`
      max-width: 1000px;
      height: unset;
      max-height: 80vh;
      border-radius: ${({ theme }) => theme.borderRadius};
    `}
    ${media.xxl`
      width: 60%;
    `}
  }
  &-content::-webkit-scrollbar {
    display: none !important;
  }
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Close = styled(MdClose).attrs({
  size: 30,
})`
  margin: 10px;
  position: absolute;
  top: 0;
  right: 12px;
  cursor: pointer;
  z-index: 1;
`;
