import { uniqBy, uniqWith } from 'lodash';
import * as NoImage from '../assets/images/no_image.png';
import {
  EVariant,
  TColorWithSkuUid,
  TProduct,
  TSelectedSku,
  TSizeWithSkuUid,
  TSku,
} from '../typings';
import { EBrand } from '../typings/enums/Brands';

export const createSelectedSku = (
  selectedVariant: TSku,
  product: TProduct,
  variant: EVariant,
): TSelectedSku => ({
  id: selectedVariant.id,
  uid: selectedVariant.uid,
  // upc_code: selectedVariant.upc_code,
  sku: selectedVariant.sku,
  product_code: selectedVariant.product_code,
  retail_price: selectedVariant.retail_price,
  name: product.name,
  description: product.description,
  photo_url: selectedVariant.images.length ? selectedVariant.images[0] : NoImage,
  currency: product.currency,
  size: selectedVariant.size,
  color: selectedVariant.color,
  variant,
  base_id: product.base_id,
  inventory: selectedVariant.inventory,
  images: selectedVariant.images,
  iln_listing: selectedVariant.iln_listing,
  productId: product._id,
  parent_product: product.parent_product,
});

/**
 * Calculates if more skus of product has color or size. There is no information in product which gives us a hint which
 * variant should be used and skus can have both size and color. The assumption is made that all skus have at least one of
 * color or size set. If all skus have both color and size color is prefered.
 * @param activeSkus of products
 * @returns value of enum EVariant indicating if product has color variants or size variants
 */

export const calculateVariantsType = (activeSkus: TSku[], brand: EBrand): EVariant => {
  const uniqueColorsCount = uniqBy(
    activeSkus.filter((p) => p?.color?.name),
    (sku) => sku.color?.name,
  ).length;

  const uniqueSizesCount = uniqBy(
    activeSkus.filter((p) => p?.size?.name),
    (sku) => sku.size?.name,
  ).length;

  if (uniqueSizesCount === uniqueColorsCount) {
    return brand === EBrand.Aveda ? EVariant.SIZE : EVariant.COLOR;
  }

  return uniqueSizesCount > uniqueColorsCount ? EVariant.SIZE : EVariant.COLOR;
};

/**
 * Calculates unique sizes of given skus. The assumption is made that activeSkus have primary sku as first element.
 * The size of primary sku is moved to the front of the list.
 * @param activeSkus
 * @returns list of unique sizes with size of primary sku at front
 */
export const calculateSizes = (activeSkus: TSku[]): TSizeWithSkuUid[] => {
  const uniqueSizes = uniqWith(
    activeSkus.map((sku) => ({
      ...sku.size,
      skuUid: sku.uid,
      inventory: sku.inventory,
      retail_price: sku.retail_price,
    })),
    (a, b) => a.name === b.name,
  );
  return uniqueSizes;
};
/**
 * Calculates unique colors of given skus. The assumption is made that activeSkus have primary sku as first element.
 * The color of primary sku is moved to the front of the list.
 * @param activeSkus
 * @returns list of unique colors with size of primary sku at front
 */
export const calculateColors = (activeSkus: TSku[]): Array<TColorWithSkuUid> => {
  const uniqueColors = uniqWith<TColorWithSkuUid>(
    activeSkus.map((sku) => ({
      ...sku?.color,
      skuUid: sku?.uid,
      inventory: sku.inventory,
    })),
    (a, b) => a.name === b.name,
  );

  return uniqueColors;
};

export const splitIngredients = (ingredients: string | null) =>
  ingredients
    ?.replace('Ingredients: ', '')
    .split(',')
    .map((i) => i.trim()) || [];

export const getInitialSelectedSku = (product: TProduct): TSelectedSku => {
  const variantType = calculateVariantsType(product.sub_skus, EBrand.Mac);

  return createSelectedSku(product.primary_sku, product, variantType);
};
