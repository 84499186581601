import styled from 'styled-components';

import { Accordion as Acc } from 'react-bootstrap';
import { media } from '../../utils/rwd';

export const Accordion = styled(Acc)`
  .btn-link {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    text-decoration: none;
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 1.28em;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    font-weight: bold;
    padding: 20px;
  }

  .card-header {
    background: white;
    padding: 0;
    border-bottom: none;

    ${media.lg.down`
      button {
        padding: 20px 0;
      }
    `}
  }

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .card-body {
    padding: 0;
    ${media.lg`
      padding: 0px 20px 20px 20px;
  `}
  }

  .accordion {
    overflow: hidden;
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;
