import { helmetJsonLdProp } from 'react-schemaorg';
import { TLook, TProduct, TSku } from '../../../typings';

export const getBundleSchema = (look: TLook) => {
  const { products } = look;

  const productsListMeta = products
    .filter((p) => p.primary_sku)
    .map((product: TProduct) => ({
      '@type': 'Product',
      name: product.name,
      brand: { '@type': 'Thing', name: product.name },
      image: product.primary_sku.images,
      description: product.description,
      productID: product.base_id,
      url: `https://${window.location.host}/look/${look.short_id}/?modifier=auto_add`,
      offers: product.sub_skus.map((sku: TSku) => ({
        '@type': 'Offer',
        url: `https://${window.location.host}/look/${look.short_id}`,
        priceCurrency: product.currency,
        price: sku.retail_price,
        inventoryLevel: {
          value: 'unlimited',
        },
        availability: 'https://schema.org/InStock',
        sku: sku.sku,
        image: sku.images,
      })),
    }));

  return helmetJsonLdProp({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    url: window.location.origin,
    numberOfItems: products.length,
    itemListElement: productsListMeta,
  });
};
