import React from 'react';

import * as Ui from './Button.styles';

type ButtonProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  buttonProps?: {
    padding?: string;
    fontWeight?: string;
    background?: string;
    color?: string;
    fontSize?: string;
    uppercase?: boolean;
  };
};

export const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  disabled = false,
  buttonProps,
  ...rest
}) => (
  <Ui.Button
    onClick={onClick}
    disabled={disabled}
    padding={buttonProps?.padding}
    fontWeight={buttonProps?.fontWeight}
    background={buttonProps?.background}
    color={buttonProps?.color}
    fontSize={buttonProps?.fontSize}
    uppercase={buttonProps?.uppercase}
    {...rest}
  >
    {text}
  </Ui.Button>
);
