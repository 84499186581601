import React, { useRef, useState } from 'react';

import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';

import NoImage from '../../../../assets/images/no_image.png';
import * as Ui from './MacProductCard.styles';
import { StarRating } from '../../../StarRating/StarRating';
import { formatPrice } from '../../../../utils/formatPrice';

import { VariantPicker } from '../../VariantPicker';
import {
  EVariant,
  TColorWithSkuUid,
  TProduct,
  TSelectedSku,
  TSizeWithSkuUid,
} from '../../../../typings';
import { getSkuInventoryBadgeText } from '../../../../utils/getSkuBadgeText';
import { useEffectInitialized } from '../../../../hooks/useEffectInitialized';
import { E2EWrapper } from '../../../E2EWrapper/E2EWrapper';

export type ProductCardProps = {
  product: TProduct;
  selectedSku: TSelectedSku;
  type: EVariant;
  variants: TSizeWithSkuUid[] | TColorWithSkuUid[];
  handleVariantSelection: (variant: TSizeWithSkuUid | TColorWithSkuUid) => void;
  onAddToCart: () => void;
  renderPopup: (trigger: JSX.Element) => JSX.Element;
  isActive: boolean;
  withDescription?: boolean;
  visibleColors?: number;
  onImageClick?: (product: TProduct) => void;
};

export const MacProductCard = ({
  product,
  selectedSku,
  type,
  variants,
  handleVariantSelection,
  onAddToCart,
  renderPopup,
  isActive,
  withDescription = false,
  onImageClick,
  ...rest
}: ProductCardProps) => {
  const { t } = useTranslation(['components']);

  const imageRef = useRef<HTMLSpanElement | null>(null);

  const [loadingImage, setLoadingImage] = useState(false);

  useEffectInitialized(() => {
    const image = (imageRef?.current as unknown as HTMLElement)?.children;

    if (image.length) {
      setLoadingImage(true);

      const item = image[0] as unknown as HTMLImageElement;

      item.onload = () => {
        setLoadingImage(false);
      };
      item.onerror = () => {
        item.src = NoImage;
      };

      item.src = selectedSku.images[0] || NoImage;
    }
  }, [selectedSku.id]);

  const renderImage = () => {
    const imageProps = {
      alt: 'Product image',
      isLoading: loadingImage,
      className: `product-image-${product._id}`,
      height: 375,
      width: 375,
      src: NoImage,
      'data-src': selectedSku.images.length
        ? selectedSku.images[0]
        : (NoImage as unknown as string),
    };

    if (onImageClick) {
      // @ts-ignore
      return <Ui.Image {...imageProps} onClick={() => onImageClick(product)} />;
    }
    // @ts-ignore
    return renderPopup(<Ui.Image {...imageProps} />);
  };

  return (
    <Ui.Wrapper {...rest}>
      <Ui.StarRatingWrapper>
        <StarRating count={product?.review_number || 0} rating={product?.review_rating || 0} />
      </Ui.StarRatingWrapper>
      <span ref={imageRef}>{renderImage()}</span>
      <Ui.ProductDataWrapper>
        <Ui.Name dangerouslySetInnerHTML={{ __html: sanitize(product.name) }} />
        <Ui.Price>{formatPrice(selectedSku.retail_price, product.currency)}</Ui.Price>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            justifyContent: withDescription ? 'space-between' : 'flex-end',
          }}
        >
          {withDescription && renderPopup(<Ui.Description>{product.description}</Ui.Description>)}
          <Ui.VariantPickerWrapper type={type}>
            <VariantPicker
              type={type}
              variants={variants}
              selectedSku={selectedSku}
              handleVariantSelection={handleVariantSelection}
              {...rest}
            />
          </Ui.VariantPickerWrapper>
        </div>
        <E2EWrapper id="addToBagButton">
          <Ui.Button
            text={isActive ? t('addToBag') : getSkuInventoryBadgeText(selectedSku, t)}
            disabled={!isActive}
            onClick={onAddToCart}
          />
        </E2EWrapper>
      </Ui.ProductDataWrapper>
    </Ui.Wrapper>
  );
};
