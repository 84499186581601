import styled from 'styled-components';
import { Dropdown as Drop } from '../Dropdown/Dropdown';

export const Select = styled.div<{ showArrow: boolean }>`
  height: 30px;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 1px solid #ccc;
  width: auto;
  padding: ${({ showArrow }) => (showArrow ? '0 30px 0 5px' : '0 10px 0 0  ')};
  line-height: 30px;
  overflow: hidden;
  position: relative;
  display: inline-block;
`;

export const Value = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.3px;
  white-space: nowrap;
  overflow: hidden;
  width: auto;
  text-overflow: ellipsis;
  padding-left: 10px;
  font-size: 12px;
  display: inline-block;
`;

export const Arrow = styled.span<{ active: boolean }>`
  display: inline-block;
  position: absolute;
  right: 12px;
  transition: transform 0.2s;
  transform: ${({ active }) => (active ? 'rotate(90deg)' : 'rotate(-90deg)')};
`;

export const Item = styled.div`
  display: flex;
  padding: 5px 6px;
  align-items: center;
  background-color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #4c91e3;
  }
`;

export const ItemValue = styled.span`
  text-transform: uppercase;
  letter-spacing: 0.3px;
  padding-left: 10px;
  font-size: 12px;
  display: inline-block;
`;

export const Dropdown = styled(Drop)`
  .dropdown-list {
    border: 1px solid #ccc !important;
    border-radius: ${({ theme }) => theme.borderRadius};
    z-index: 10;
    div {
      max-height: 150px; // 5 items
    }
  }
`;
