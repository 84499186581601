import React from 'react';
import { EVariant, TColorWithSkuUid, TSizeWithSkuUid, TSelectedSku } from '../../typings';
import { ColorPicker } from '../ColorPicker/ColorPicker';
import { SizePicker } from '../SizePicker/SizePicker';

type VariantPickerProps = {
  type: EVariant;
  variants: TSizeWithSkuUid[] | TColorWithSkuUid[];
  selectedSku: TSelectedSku;
  handleVariantSelection: (variant: TSizeWithSkuUid | TColorWithSkuUid) => void;
  onVariantClick?: (selectedSku: TSelectedSku) => void;
  visibleColors?: number;
  positionTop?: boolean;
};

export const VariantPicker = React.memo(
  ({
    type,
    variants,
    selectedSku,
    handleVariantSelection,
    positionTop = false,
    ...rest
  }: VariantPickerProps) =>
    type === EVariant.SIZE ? (
      <SizePicker
        onClick={handleVariantSelection}
        sizes={variants as TSizeWithSkuUid[]}
        selectedSku={selectedSku}
        positionTop={positionTop}
        {...rest}
      />
    ) : (
      <ColorPicker
        colors={variants as TColorWithSkuUid[]}
        onClick={handleVariantSelection}
        defaultActive={selectedSku.uid}
        {...rest}
      />
    ),
);

VariantPicker.displayName = 'VariantPicker';
