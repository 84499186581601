import styled, { css } from 'styled-components/macro';
import { TTabsProps } from './Tabs';

export const TabNav = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  gap: 20%;
`;

const itemAsTab = (active, flexColumn) => css`
  outline: 0 !important;
  background: transparent;
  padding: 0;
  border: none;
  padding: 10px;

  h2 {
    font-family: ${({ theme }) => theme.fontFamily?.bold};
    font-weight: 400;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.3;
    line-height: 1.2;
    white-space: nowrap;
    text-transform: uppercase;
    color: ${({ theme }) => (active ? theme.colors.black : '#A8A8A8')};
    box-sizing: border-box;
    margin: 0;
    transition-duration: 600ms;
    transition-timing-function: ease-in-out;
    position: relative;

    ${active &&
    flexColumn &&
    `
        ::after {
          content: '';
          border-bottom: 3px solid #000;
          width: 60px;
          display: block;
          position: absolute;
          left: calc(50% - 30px);
          bottom: -10px;
        }
      `}
  }
`;

const itemAsButton = (active) => css`
  padding: 20px 50px;
  background: ${active ? 'black' : 'white'};
  border: 2px solid black;
  cursor: pointer;
  h2 {
    color: ${active ? 'white' : 'black'};
    font-family: ${({ theme }) => theme.fontFamily.normal};
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.3;
    line-height: 1.2;
    margin: 0;
  }
`;

const renderTabItem = ({ active, flexColumn, displayAs }) =>
  displayAs === 'tabs' ? itemAsTab(active, flexColumn) : itemAsButton(active);

export const TabNavItem = styled.button<{
  active: boolean;
  flexColumn: boolean;
  displayAs: TTabsProps['displayAs'];
}>`
  ${(props) => renderTabItem(props)}
`;

export const TabItemsWrapper = styled.div<{
  flexColumn: boolean;
  displayAs: TTabsProps['displayAs'];
}>`
  border-bottom: 1px solid #edeef0;
  display: flex;
  flex-direction: ${({ flexColumn }) => (flexColumn ? 'column' : 'row')};
  justify-content: center;
  position: relative;

  ${({ displayAs }) =>
    displayAs === 'buttons' &&
    `
        gap: 25px;
        border-bottom: none;
      `}
`;

export const TabBody = styled.div`
  padding: 24px 0;
`;

export const ActiveElementUnderline = styled.div<{ left: number }>`
  background-color: #000;
  height: 3px;
  width: 42px;
  position: absolute;
  bottom: -2px;
  left: ${({ left }) => `${left}px`};

  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
`;
