import React from 'react';
import { ProductCard, TFavorite, TSelectedSku } from '../..';

import * as Ui from './FavoritesList.styles';

export const FavoritesList = ({
  favorites = [],
  addToCart,
  onProductClick,
  loading,
}: {
  favorites: Array<TFavorite>;
  addToCart: (lookShortId: string) => (variant: TSelectedSku) => void;
  onProductClick: (product: TFavorite) => void;
  loading: boolean;
}) => {
  if (loading) {
    // to do => translation
    return <p className="text-muted mt-5 text-center ">Loading</p>;
  }

  if (favorites.length === 0) {
    // to do => translation
    return <p className="text-muted mt-5 text-center ">No Favorites</p>;
  }

  return (
    <Ui.FavoriteListWrapper>
      {favorites.map((fav) => {
        // @ts-ignore
        if (fav?.lookId) {
          return (
            // @ts-ignore

            <Ui.ProductCardWrapper key={fav.lookId}>
              <ProductCard
                // @ts-ignore

                onImageClick={() => onProductClick(fav)}
                // @ts-ignore
                onAddToCart={addToCart(fav.lookId)}
                // @ts-ignore
                product={fav}
              />
            </Ui.ProductCardWrapper>
          );
        }
        return <p key="lala">skeleton</p>;
      })}
    </Ui.FavoriteListWrapper>
  );
};

export default FavoritesList;
