import React from 'react';

import * as Ui from './Container.styles';

const Container = ({ children, ...rest }: { children: JSX.Element | JSX.Element[] }) => (
  <Ui.Container {...rest}>{children}</Ui.Container>
);

Container.Row = function Row({ children, ...rest }: { children: JSX.Element | JSX.Element[] }) {
  return <Ui.Row {...rest}>{children}</Ui.Row>;
};

export default Container;
