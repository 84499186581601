import React, { useEffect, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { useTranslation } from 'react-i18next';
import { EVariant, formatPrice, TCartItem } from '../..';
import * as Ui from './AddedToBasketPreview.styles';

type AddedToBasketPreviewProps = {
  items: TCartItem[];
  clearLastAddedItems: () => void;
  openBasket: () => void;
};

export const AddedToBasketPreview = ({
  items,
  clearLastAddedItems,
  openBasket,
}: AddedToBasketPreviewProps) => {
  const { t } = useTranslation(['components']);
  const [counterId, setCounterId] = useState(1);
  const [isPlaying, setIsPlaying] = useState(true);
  const [dismissed, setDismissed] = useState(false);

  const [popupPosition, setPopupPosition] = useState({ top: 0, right: 0 });

  useEffect(() => {
    if (items.length) {
      // @ts-ignore
      const [basketIcon] = [...document.querySelectorAll('#basket-icon')].filter(
        (node) => node.offsetWidth,
      );

      const position = basketIcon?.getBoundingClientRect();

      if (position) {
        setPopupPosition({
          top: position.top + position.height + 17,
          right: window.innerWidth - (position.left || 0) - 35,
        });
      }
    }
  }, [items]);

  useEffect(() => {
    if (items.length) {
      setCounterId(counterId + 1);
    }
    if (!items.length) {
      setDismissed(false);
      setCounterId(1);
    }
  }, [items]);

  return (
    <Ui.Wrapper
      visible={!!items.length}
      popupPostionTop={popupPosition.top}
      arrowPosition={popupPosition.right}
      onFocus={() => setIsPlaying(false)}
      onBlur={() => {
        setIsPlaying(true);
      }}
      onMouseOver={() => setIsPlaying(false)}
      onMouseOut={() => {
        setIsPlaying(true);
      }}
    >
      <Ui.Content>
        <div className="d-flex justify-content-between align-items-center">
          {/* // to do: change basket to component name */}
          <h5 className="m-0">{t('basket.addedToBag')}</h5>
          <Ui.SpinnerWrapper>
            {!dismissed ? (
              <CountdownCircleTimer
                key={counterId}
                isPlaying={isPlaying}
                duration={7}
                colors={['#004777', '#F7B801', '#A30000', '#A30000']}
                colorsTime={[7, 5, 2, 0]}
                size={40}
                strokeWidth={5}
                onComplete={() => {
                  clearLastAddedItems();
                }}
              >
                {() => (
                  <Ui.CloseButton
                    onClick={() => {
                      setDismissed(true);
                    }}
                  />
                )}
              </CountdownCircleTimer>
            ) : (
              <Ui.CloseButton
                onClick={() => {
                  setIsPlaying(true);
                  clearLastAddedItems();
                }}
              />
            )}
          </Ui.SpinnerWrapper>
        </div>
        {!!items.length &&
          items.map((item: TCartItem) => (
            <Ui.BasketItemWrapper key={item.sku}>
              <Ui.BasketItemImage>
                <img
                  className="basket-item-image"
                  alt="Product thumbnail"
                  src={`${item.photo_url}`}
                  width={80}
                  height={80}
                />
              </Ui.BasketItemImage>
              <Ui.BasketItemContent>
                {/* eslint-disable-next-line react/no-danger */}
                <p className="name" dangerouslySetInnerHTML={{ __html: item.name }} />

                {item.variant === EVariant.SIZE ? (
                  <Ui.Size>{item.size.name}</Ui.Size>
                ) : (
                  <div className="d-flex align-items-end py-2">
                    <div
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        backgroundColor: item.color.hex_value,
                      }}
                    />
                    <Ui.ColorName color={item.color.hex_value}>{item.color.name}</Ui.ColorName>
                  </div>
                )}
                <p className="price">{formatPrice(item.retail_price, item.currency)}</p>
              </Ui.BasketItemContent>
            </Ui.BasketItemWrapper>
          ))}
        <Ui.Link
          onClick={() => {
            clearLastAddedItems();
            openBasket();
          }}
        >
          Show entire basket
        </Ui.Link>
      </Ui.Content>
    </Ui.Wrapper>
  );
};
