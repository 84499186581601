import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

const UP = 'UP';
const DOWN = 'DOWN';

export const useScrollController = (heightDifference: number) => {
  const [scrolled, setScrolled] = useState(false);
  const lastOffset = useRef(0);

  const onScroll = debounce(() => {
    const offset = window.scrollY;

    const direction = offset > lastOffset.current ? DOWN : UP;

    if (direction === UP) {
      if (offset === 0) {
        setScrolled(lastOffset.current < heightDifference);
      } else {
        setScrolled(true);
      }
    } else {
      setScrolled(true);
    }
    lastOffset.current = offset;
  }, 20);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrolled;
};
