// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const bringElementToFront = (arrayToSort: Array<any>, property: string): Array<any> => {
  const sorted = [...arrayToSort];
  for (let i = 0; i < arrayToSort.length; i++) {
    if (sorted[i][property]) {
      const imortant_note = sorted.splice(i, 1);
      sorted.unshift(imortant_note[0]); // push to front
    }
  }

  return sorted;
};
