import React from 'react';
import { TRelatedContent } from '../../..';
import { YoutubeContent } from './YoutubeContent';
import { VideoContent } from './VideoContent';
import { ImageContent } from './ImageContent';

export const RelatedContent = ({ item, position }: { item: TRelatedContent; position: number }) => {
  if (item.resource_type === 'video') return <VideoContent item={item} />;
  if (item.resource_type === 'youtube') return <YoutubeContent item={item} />;
  // banners shouldn't be added as related content to artist's pages
  return <ImageContent item={item} position={position} />;
};
