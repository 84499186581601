import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TLearningCenter } from '@elc/common';
import { useGlobalState, useThunkDispatch } from '../store';
import { fetchLearningCenter } from '../store/actions/learningCenter.actions';

export const useLangagueAwareLearingCenter = (): TLearningCenter | null => {
  const dispatch = useThunkDispatch();

  const learningCenters = useGlobalState((state) => state.learningCenter.item);
  const { i18n } = useTranslation();
  const [selectedCenter, setSelectedCenter] = useState<TLearningCenter | null>(null);

  useEffect(() => {
    // if there are no learning centres already loaded, fetch them on initial load
    if (learningCenters.learning_centers.length === 0) {
      dispatch(fetchLearningCenter());
    }
  }, []);

  useEffect(() => {
    const currentLng = i18n.language;
    const defaultLng = learningCenters.default;

    const currentLngLearningCenters = learningCenters.learning_centers.filter(
      (center) => center.language === currentLng,
    );

    // if there is learing center with language set by browser or user
    if (currentLngLearningCenters[0]) {
      setSelectedCenter(currentLngLearningCenters[0]);
    } else {
      // else try to select learning center with language set as default to brand
      const defaultLngLearningCenters = learningCenters.learning_centers.filter(
        (center) => center.language === defaultLng,
      );
      if (defaultLngLearningCenters[0]) {
        setSelectedCenter(defaultLngLearningCenters[0]);
      } else {
        // if for some reason there is no learning center with default langaue try to set first from list
        // eslint-disable-next-line no-lonely-if
        if (learningCenters.learning_centers[0]) {
          setSelectedCenter(learningCenters.learning_centers[0]);
        }
      }
    }
  }, [learningCenters.default, i18n.language]);

  return selectedCenter;
};
