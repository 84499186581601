import React from 'react';
import { Skeleton } from '../../../Skeleton/Skeleton';
import * as Ui from '../HorizontalSocials.styles';

export const HorizontalSocialsSkeleton = () => (
  <Ui.Socials>
    {Array.from({ length: 4 }).map((_, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton key={idx} width={24} height={24} viewBox="0 0 24 24">
        <circle r="12" cx="12" cy="12" />
      </Skeleton>
    ))}
  </Ui.Socials>
);
