import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLearningCenter } from '@elc/common';

export const fetchLearningCenter = createAsyncThunk(
  'learningCenter/fetchLearningCenter',
  async () => {
    try {
      const { data: response } = await getLearningCenter();

      if (response) {
        return response;
      }
      throw Error();
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
