import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFetchableItem, TTerms } from '@elc/common';
import * as termsActions from '../actions/terms.actions';

const initialState: IFetchableItem<TTerms> = {
  item: {
    terms_of_use: '',
    influencer_privacy_policy: '',
  },
  loading: 'pending',
  status: 200,
};

const termsSlice = createSlice({
  name: 'learningCenter',
  initialState,
  reducers: {
    setTerms: (state, action: PayloadAction<TTerms>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(termsActions.fetchTerms.fulfilled, (state, { payload }) => {
      state.loading = 'fullfield';
      state.item = payload;
    });
  },
});

export const termsAction = {
  ...termsSlice.actions,
};

export default termsSlice;
