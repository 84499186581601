import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { media } from '../../utils/rwd';

export const FeedbackWrapper = styled.a`
  position: fixed;
  color: black;
  padding: 1rem;
  font-size: 24px;
  cursor: pointer;
  top: 50%;
  right: -100px;
  background: #efe7dd;
  transform: rotate(270deg);
  z-index: 9999;
  text-decoration: none;
`;

export const StyledPopup = styled(Popup)`
  &-content {
    background: #f1f1f1;
    position: fixed !important;
    right: 90px;
    top: 30% !important;
    left: unset !important;
    width: 498px;

    ${media.sm.down`
    width: 100%;
    right: 0px;
`}
  }
`;

export const RightCorner = styled.div`
  background: #f1f1f1;
  position: fixed !important;
  top: 45%;
  right: 80px;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
  z-index: -1;
`;

export const PopupHeader = styled.div`
  padding: 24px;
  padding-vertical: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: black;
  color: white;
  h4 {
    font-size: 24px;
    color: white;
    margin: 0;
  }
  svg {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  overflow: scroll;
  max-height: 50vh;
`;

export const Text = styled.p`
  margin-bottom: 0px;
`;

export const TextArea = styled.textarea`
  padding: 10px;
`;

export const SubmitButton = styled.button`
  padding: 12px;
  padding-inline: 35px;
  color: white;
  width: 50%;
  border: none;
  span {
    margin-right: 16px;
    margin-bottom: 5px;
  }
`;

export const ApproveButton = styled(SubmitButton)`
  background: #1fba5f;
`;

export const RejectButton = styled(SubmitButton)`
  background: #e43a5a;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
`;

export const Disclaimer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5px;
  p {
    font-size: 12px;
    color: #707070;
    margin: 0;
  }
`;
