import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Ui from './ShortArtistProfileSection.styles';
import { HorizontalSocials } from '../Socials';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';

import { numberOfLooks } from '../../utils/artist';

import { TArtist, ShortArtistProfileSectionSkeleton } from '../..';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

export type ShortArtistProfileSectionProps = {
  artist: TArtist;
  noImage: string;
  artistPageUrl?: string;
  showDescription?: boolean;
  showLocations?: boolean;
};

export const ShortArtistProfileSection = ({
  artist,
  noImage,
  artistPageUrl,
  showDescription = true,
  showLocations = true,
  ...rest
}: ShortArtistProfileSectionProps) => {
  if (!artist?.id) {
    return <ShortArtistProfileSectionSkeleton />;
  }

  const { t } = useTranslation(['components']);
  const currentLocation = window.location.pathname + window.location.search;
  const photoUrl = artist.picture || noImage;
  const numberOfBundles = numberOfLooks(artist);

  return (
    <Ui.Wrapper {...rest}>
      <Ui.Row>
        <E2EWrapper id="backToArtistButton">
          <Ui.LinkSection isLink={!!artistPageUrl} to={artistPageUrl || currentLocation}>
            <Ui.Image src={photoUrl} alt="artist_photo" />
            {/*  @ts-ignore */}
            <Ui.Name>{artist.fullName}</Ui.Name>
          </Ui.LinkSection>
        </E2EWrapper>
        <Ui.Col>
          <E2EWrapper id="backToLooksButton">
            <Ui.LinkSection isLink={!!artistPageUrl} to={artistPageUrl || currentLocation}>
              <Ui.BundlesNumber>{numberOfBundles}</Ui.BundlesNumber>
              <Ui.BundlesCaption isInsideLink={!!artistPageUrl}>
                {numberOfBundles === 1 ? t('look') : t('looks')}
              </Ui.BundlesCaption>
            </Ui.LinkSection>
          </E2EWrapper>
        </Ui.Col>
        {/*  @ts-ignore */}

        <HorizontalSocials socials={artist.socials} className="shortArtistProfileSection_socials" />
      </Ui.Row>
      <Ui.Description>
        {!!artist.about && showDescription && <HtmlTruncatedText html={artist.about} />}
      </Ui.Description>
      {artist.locations && showLocations && (
        <Ui.Salons>
          {artist.locations.map((salon) => (
            <E2EWrapper id="favoriteOpenSalonButton" key={salon.vanity_url}>
              <Ui.SalonLink to={`/salon/${salon.vanity_url}`}>{salon.name}</Ui.SalonLink>
            </E2EWrapper>
          ))}
        </Ui.Salons>
      )}
    </Ui.Wrapper>
  );
};
