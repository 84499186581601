import styled from 'styled-components';
import { Header } from '@elc/common';

export const Wrapper = styled.div`
  background: #fff;
`;

export const PageBackground = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
`;

export const Page = styled.div<{ noPadding: boolean }>`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ noPadding }) => (noPadding ? '0px' : '50px')};
`;

export const StyledHeader = styled(Header)`
  .header_brandLogo {
    height: 45%;
  }
`;
