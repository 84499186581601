import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import * as Ui from './Terms.styles';
import { TTerms } from '../../typings/types/Terms';

type TermsProps = {
  terms: TTerms;
  activeTabName: string;
  onChange: (name: string) => void;
};

export const Terms = ({ terms, activeTabName, onChange }: TermsProps) => {
  const { t } = useTranslation(['components']);

  const tabsNames = ['terms_of_use', 'influencer_privacy_policy'];

  return (
    <Ui.Wrapper>
      <Tabs
        id="terms_tab"
        activeKey={activeTabName.replaceAll('-', '_')}
        className="mb-3"
        onSelect={(k) => k && onChange(k.replaceAll('_', '-'))}
      >
        {tabsNames.map((name) => (
          <Tab eventKey={name} title={t(name)} key={name}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: sanitize(terms[name]),
              }}
            />
          </Tab>
        ))}
      </Tabs>
    </Ui.Wrapper>
  );
};
