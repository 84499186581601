import styled from 'styled-components';
import CarouselComponent from '@brainhubeu/react-carousel';
import { media } from '../../utils/rwd';

const CarouselWrapper = styled.div`
  overflow: hidden;

  .swiper-container {
    position: relative;
    padding-bottom: 20px;
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
      opacity: 0;
    }
    .swiper-pagination-disabled > &,
    &.swiper-pagination-disabled {
      display: none !important;
    }
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
  }
  /* Bullets */
  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
    .swiper-pagination-bullet {
      transform: scale(0.33);
      position: relative;
    }
    .swiper-pagination-bullet-active {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-main {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-prev {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet-active-next {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.33);
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background: white;
    opacity: 0.5;
    cursor: pointer;
    @at-root button#{&} {
      border: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      appearance: none;
    }
    .swiper-pagination-clickable & {
      cursor: pointer;
    }

    &:only-child {
      display: none !important;
    }
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-horizontal.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }

  .swiper-pagination-lock {
    display: none;
  }
  ///////

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 50px;
    height: 50px;
    opacity: 0.5;
    background-color: white;
    border-radius: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    &.swiper-button-disabled {
      display: none;
    }
    &.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    &:after {
      font-family: swiper-icons;
      font-size: var(--swiper-navigation-size);
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    &:after {
      content: 'prev';
    }
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    &:after {
      content: 'next';
    }
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
`;

const Carousel = styled(CarouselComponent)`
  position: relative;
  height: 100%;

  .BrainhubCarouselItem {
    position: relative;

    ${media.sm.down`
      width: 100%;
    `}

    img {
      max-height: 400px;
      object-fit: contain;

      ${media.sm.down`
        width: 100%
    `}
    }
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0;
`;

const Dot = styled.div<{ active: boolean }>`
  outline: 0;
  padding: 10px;
  border: none;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none;
  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: #000;
  }
`;

const ThumbnailsWrapper = styled.div`
  position: absolute;
  ${media.sm.down`
  top: 5px;
  left: 5px;
`}
  top: 25px;
  left: 25px;
  max-height: 400px;
  ${media.sm.down`
  max-height: 300px;

`}
  overflow: hidden;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  overflow: auto;
  overflow-x: hidden;
`;

const Thumbnail = styled.img<{ active: boolean }>`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  object-fit: contain;
`;

export {
  CarouselWrapper,
  Carousel,
  DotsWrapper,
  Dot,
  ThumbnailsWrapper,
  Thumbnail,
  ThumbnailsContainer,
};
