import styled, { DefaultTheme } from 'styled-components';

export const AddToCartButton = styled.button<{
  theme?: DefaultTheme;
  disabled: boolean;
  padding?: string;
  fontWeight?: string;
  background?: string;
  color?: string;
  fontSize?: string;
  uppercase?: boolean;
}>`
  position: relative;
  padding: 21px 20px;
  white-space: nowrap;
  margin: 16px auto 0 auto;
  width: 100%;

  background: ${({ theme, background }) => background || theme.button.background};
  border: none;
  border-radius: ${({ theme }) => theme.button?.borderRadius};
  text-align: center;
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity: 0.5; cursor: not-allowed;`}
  outline: none;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  max-width: 170px;

  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.9);
  }

  &.clicked:hover {
    opacity: 1;
  }

  .fa-cart-shopping {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: -20%;
    font-size: 2em;
    transform: translate(-50%, -50%);
  }
  .fa-box {
    position: absolute;
    z-index: 3;
    top: -20%;
    left: 52%;
    font-size: 1.2em;
    transform: translate(-50%, -50%);
  }
  path {
    fill: ${({ theme, color }) => color || theme.button.color};
  }
  span {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    font-size: 1.2em;
    color: ${({ theme, color }) => color || theme.button.color};
    transform: translate(-50%, -50%);
    font-weight: ${({ theme, fontWeight }) => fontWeight || theme.button.fontWeight};
    line-height: ${({ theme }) => theme.button.lineHeight};
    font-size: ${({ theme, fontSize }) => fontSize || theme.button.fontSize};
    font-family: ${({ theme }) => theme.button.fontFamily};
    text-transform: ${({ theme, uppercase }) =>
      uppercase ? 'uppercase' : theme.button.textTransform || 'none'};
  }
  span.add-to-cart {
    opacity: 1;
  }

  &.clicked .fa-cart-shopping {
    animation: cart 1.5s ease-in-out forwards;
  }
  &.clicked .fa-box {
    animation: box 1.5s ease-in-out forwards;
  }
  &.clicked span.add-to-cart {
    animation: txt1 1.5s ease-in-out forwards;
  }

  @keyframes cart {
    0% {
      left: -20%;
    }
    40%,
    60% {
      left: 50%;
    }
    100% {
      left: 110%;
    }
  }
  @keyframes box {
    0%,
    40% {
      top: -20%;
    }
    60% {
      top: 40%;
      left: 52%;
    }
    100% {
      top: 40%;
      left: 112%;
    }
  }
  @keyframes txt1 {
    0% {
      opacity: 1;
    }
    20%,
    100% {
      opacity: 0;
    }
  }
`;
