import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Page404: React.FC = () => (
  <Wrapper>
    <h1>404 </h1>
    <h3>PAGE NOT FOUND</h3>
  </Wrapper>
);

export default Page404;
