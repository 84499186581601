import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClipboardCheck } from 'react-icons/bs';

import {
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookMessengerIcon,
} from 'react-share';
import { analyticsShareButtonClick, TLookShort, TArtistLook } from '../..';

import * as Ui from './ShareModal.styles';

type ShareModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  look: TLookShort | TArtistLook | undefined;
};

export const ShareModal: FC<ShareModalProps> = ({ isOpen, onClose, look }) => {
  const [copied, setCopied] = useState(false);
  // @ts-ignore
  const lookUrl = `${window.location.origin}/look/${look?.shortId || look?.short_id}`;
  const { t } = useTranslation(['components']);
  const handleCopyToClipboard = () => {
    setCopied(true);
    navigator.clipboard.writeText(lookUrl);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    if (isOpen) {
      analyticsShareButtonClick('open');
    }
  }, [isOpen]);
  return (
    <Ui.Overlay isOpen={isOpen} onClick={onClose}>
      <Ui.Modal
        isOpen={isOpen}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Ui.Header>
          <h6> {t('components:shareModal.modalTitle')}</h6>
          <Ui.CloseButton
            onClick={() => {
              analyticsShareButtonClick('close');
              if (onClose) {
                onClose();
              }
            }}
          />
        </Ui.Header>
        <Ui.List>
          <Ui.Item>
            <Ui.CopyButton onClick={handleCopyToClipboard}>
              {copied && <Ui.Tooltip>Copied</Ui.Tooltip>}
              <Ui.CopyButtonIcon copied={copied}>
                <BsClipboardCheck />
              </Ui.CopyButtonIcon>
              <p>{t('components:shareModal.copyLink')}</p>
            </Ui.CopyButton>
          </Ui.Item>

          <Ui.Item>
            <FacebookShareButton url={lookUrl} quote="Checkout this look" hashtag="cokolwiek">
              <FacebookIcon size={32} round />
              <p>{t('components:shareModal.facebookPost')}</p>
            </FacebookShareButton>
          </Ui.Item>
          <Ui.Item>
            <TwitterShareButton
              url={lookUrl}
              title="Checkout this look"
              hashtags={['test_hashtag']}
              via="test_via"
            >
              <TwitterIcon size={32} round />
              <p>{t('components:shareModal.postTweet')}</p>
            </TwitterShareButton>
          </Ui.Item>
          <Ui.Item>
            <PinterestShareButton
              url={lookUrl}
              media={look?.pictures[0] || ''}
              description="Checkout this look"
            >
              <PinterestIcon size={32} round />
              <p>{t('components:shareModal.pinterest')}</p>
            </PinterestShareButton>
          </Ui.Item>
          <Ui.Item>
            <FacebookMessengerShareButton url={lookUrl} appId="mac.cosmetic.7902">
              <FacebookMessengerIcon size={32} round />
              <p>{t('components:shareModal.sendMessage')}</p>
            </FacebookMessengerShareButton>
          </Ui.Item>
          <Ui.Item>
            <WhatsappShareButton url={lookUrl} title="Checkout this look">
              <WhatsappIcon size={32} round />
              <p>{t('components:shareModal.sendMessage')}</p>
            </WhatsappShareButton>
          </Ui.Item>

          <Ui.Item>
            <EmailShareButton url={lookUrl} subject="Checkout this look" body="">
              <EmailIcon size={32} round />
              <p>{t('components:shareModal.sendEmail')}</p>
            </EmailShareButton>
          </Ui.Item>
        </Ui.List>
      </Ui.Modal>
    </Ui.Overlay>
  );
};
