import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSalonGroupAction } from './salonGroup.actions';
import { SALON_GROUP_REDUCER_KEY } from './salonGroup.constants';
import { TSalonGroup } from '../../typings';

export type TFeaturedArtistsState2 = {
  items: TSalonGroup | null;
  loading: 'pending' | 'fullfield' | 'rejected';
  status?: number;
};

const initialState: TFeaturedArtistsState2 = {
  items: null,
  loading: 'pending',
  status: undefined,
};

const salonGroupSlice = createSlice({
  name: SALON_GROUP_REDUCER_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchSalonGroupAction.fulfilled,
      (state, { payload }: PayloadAction<TSalonGroup>) => {
        state.items = payload;
        state.items.locations = state.items.locations.map((location) => ({
          ...location,
          // TODO: usunac jak api dostarczy
          cover_photo:
            'https://res.cloudinary.com/hausmartstaging/image/upload/c_limit,h_540,w_960/v1/mac/locations/7rmKW82.jpg',
        }));
        state.loading = 'fullfield';
        state.status = 200;
      },
    );
    builder.addCase(fetchSalonGroupAction.rejected, (state, { payload }) => {
      state.loading = 'rejected';
      state.status = payload as number;
    });
  },
});

export const salonGroupActions = {
  ...salonGroupSlice.actions,
};

export const salonGroupReducer = salonGroupSlice.reducer;
