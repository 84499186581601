import { DefaultTheme } from 'styled-components';
import { EBrand } from '../typings/enums/Brands';

export const avedaTheme: DefaultTheme = {
  brand: EBrand.Aveda,
  colors: {
    black: '#120E02',
    white: '#fff',
    grey: '#d9d9d9',
  },
  fontFamily: {
    bold: 'MetaOTBold',
    normal: 'MetaOT',
  },
  borderRadius: '0px',
  transitionTime: '0.5s',
  header: {
    topBar: {
      background: '#ffffff',
      size: {
        big: '80px',
        small: '80px',
      },
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '18px',
    },
    logos: {
      small: '60px',
      big: '100px',
    },
  },
  productCard: {
    wrapper: {
      fontSize: '16px',
    },
    colorName: {
      fontSize: '12px',
      lineHeight: '14px',
      fontFamily: 'MetaOTBold',
    },
    name: {
      fontSize: '13px',
      lineHeight: '16px',
      textTransform: 'lowercase',
      fontFamily: 'MetaOT',
    },
    description: {
      fontSize: '12px',
      lineHeight: '15px',
    },
    price: {
      fontSize: '18px',
      lineHeight: '23px',
    },
    image: {
      maxHeight: '230px',
      background: 'white',
    },
  },
  singleBundle: {
    description: {
      color: '#120E02',
      opacity: 1,
      lineHeight: {
        small: '15px',
        big: '18px',
      },
      fontSize: {
        small: '12px',
        big: '16px',
      },
    },
    contentFooter: {
      color: '#120E02',
      letterSpacing: '0.01em',
    },
    title: {
      color: '#120E02',
    },
    image: {
      width: {
        big: '926px',
        medium: '601px',
        small: '508px',
      },
    },
  },
  button: {
    padding: '13px 20px',
    fontWeight: '400',
    lineHeight: '14px',
    fontSize: '13px',
    background: '#120e02',
    color: '#FFF',
    borderRadius: '0px',
    fontFamily: 'MetaOT',
    textTransform: 'uppercase',
  },
  artistProfileSection: {
    title: {
      textTransform: 'none',
      fontSize: '22px',
    },
  },
  artistLookItem: {
    title: {
      textTransform: 'capitalize',
      fontSize: '18px',
      fontWeight: '400',
      fontFamily: 'MetaOT',
    },
    description: {
      color: '#000000',
    },
  },
  allProductBundle: {
    title: {
      textTransform: 'none',
    },
  },
};
