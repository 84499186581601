import { useLocation, matchRoutes } from 'react-router-dom';

const routes = [
  { path: 'look/:shortId', name: 'Look' },
  { path: 'a/:vanity_url', name: 'Artist' },
  { path: 'a/:vanityUrl/p/:shortId', name: 'Product' },
];

export const useCurrentPath = () => {
  const location = useLocation();
  const macthedRoutes = matchRoutes(routes, location);

  return macthedRoutes;
};
