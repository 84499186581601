import styled from 'styled-components';
import { ReactComponent as CloseIcon } from '../../../../assets/svg/cross.svg';

export const Overlay = styled.div<{ isOpen: boolean }>`
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  top: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Modal = styled.div<{ isOpen: boolean }>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  right: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  transition: all 300ms ease-in-out;
  background: white;
`;

export const Header = styled.div`
  padding: 60px 20px 30px 20px;
  display: flex;
  justify-content: flex-end;
`;

export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  padding: 15px 20px;
  font-size: 16px;
  line-height: 140%;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  color: unset;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: unset;
  }
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
`;
