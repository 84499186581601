import React from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { formatPrice } from '../../utils/formatPrice';
import { Carousel } from '../Carousel/Carousel';
import * as Ui from './ProductDetails.styles';
import { ProductDetailsAccordion } from './ProductDetailsAccordion';
import { VariantPicker } from '../ProductCard/VariantPicker';
import {
  EVariant,
  TProduct,
  TSelectedSku,
  SAddToCart,
  TColorWithSkuUid,
  TSizeWithSkuUid,
} from '../../typings';
import { getSkuInventoryBadgeText } from '../../utils/getSkuBadgeText';

type ProductDetailsType = {
  product: TProduct;
  type: EVariant;
  selectedSku: TSelectedSku;
  handleVariantSelection: (variant: TSizeWithSkuUid | TColorWithSkuUid) => void;
  variants: TSizeWithSkuUid[] | TColorWithSkuUid[];
  onAddToCart: SAddToCart;
  close: () => void;
  isActive: boolean;
};

export const ProductDetails = ({
  product,
  type,
  selectedSku,
  handleVariantSelection,
  variants,
  close,
  onAddToCart,
  isActive,
}: ProductDetailsType) => {
  const { t } = useTranslation(['components']);

  return (
    <Ui.Wrapper>
      <Ui.CarouselCol>
        <Carousel images={selectedSku.images} />
      </Ui.CarouselCol>
      <Ui.ContentCol>
        <Ui.Name
          dangerouslySetInnerHTML={{
            __html: sanitize(product.name),
          }}
        />

        <Ui.Price>{formatPrice(selectedSku.retail_price, product.currency)}</Ui.Price>
        <Ui.Description
          dangerouslySetInnerHTML={{
            __html: sanitize(product.description),
          }}
        />
        <Ui.VariantPicker>
          <VariantPicker
            type={type}
            variants={variants}
            selectedSku={selectedSku}
            handleVariantSelection={handleVariantSelection}
          />
        </Ui.VariantPicker>
        <ProductDetailsAccordion details={product.details} ingredients={selectedSku.iln_listing} />
        <Ui.ButtonRow>
          <Ui.Button
            disabled={!isActive}
            onClick={() => {
              close();
              onAddToCart(selectedSku);
            }}
            text={isActive ? t('addToBag') : getSkuInventoryBadgeText(selectedSku, t)}
          />
        </Ui.ButtonRow>
      </Ui.ContentCol>
    </Ui.Wrapper>
  );
};
