import React, { FC } from 'react';

import arrowLeft from '../../assets/svg/chevron-left.svg';
import NoImage from '../../assets/images/profile_placeholder.png';
import { Skeleton, SkeletonRectangle } from '../Skeleton/Skeleton';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';
import * as Ui from './LookNavigationHeader.styles';

export type TLookNavigationHeaderProps = {
  imageUrl: string;
  linkText: string;
  linkHref: string;
  ready?: boolean;
  showImage?: boolean;
};

export const LookNavigationHeader: FC<TLookNavigationHeaderProps> = ({
  imageUrl,
  linkText,
  linkHref,
  ready = false,
  showImage = true,
  children,
  ...rest
}) => {
  const renderLinkText = () => (
    <Ui.Text>
      {ready ? (
        linkText
      ) : (
        <Skeleton style={{ width: '200px', height: '18px' }}>
          <SkeletonRectangle width="100%" height="100%" />
        </Skeleton>
      )}
    </Ui.Text>
  );

  return (
    <Ui.Wrapper {...rest}>
      <Ui.Link to={linkHref}>
        <Ui.ArrowLeft src={arrowLeft} />
      </Ui.Link>
      <E2EWrapper id="backButton">
        <Ui.Link to={linkHref}>
          <div className="d-flex align-items-center">
            {showImage && <Ui.Image src={ready && imageUrl ? imageUrl : NoImage} />}
            {renderLinkText()}
          </div>
        </Ui.Link>
      </E2EWrapper>
      <Ui.BasketWrapper>{children}</Ui.BasketWrapper>
    </Ui.Wrapper>
  );
};
