/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useCallback, Children } from 'react';

import { FaArrowRight as ArrowRight, FaArrowLeft as ArrowLeft } from 'react-icons/fa';
import { Arrow, Wrapper } from './HorizontalList.styles';
import { useVisibility } from './useVisibility';

type HorizontalListProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: any; // TODO better typing that works on color picker
  withArrows: boolean;
  autoScroll: boolean;
  defaultActive?: number;
  customArrows?: {
    left: JSX.Element;
    right: JSX.Element;
  };
  scrollBehavior?: 'smooth' | 'auto';
  rest?: {
    className: string;
  };
};

export const HorizontalList = ({
  children,
  withArrows,
  autoScroll,
  defaultActive = 0,
  customArrows,
  scrollBehavior = 'smooth',
  ...rest
}: HorizontalListProps) => {
  const childrenWrapper = useRef<HTMLDivElement>(null);
  const previouslyActiveElementIndex = useRef(defaultActive);

  const firstChild = useRef<HTMLElement>(null);
  const lastChild = useRef<HTMLElement>(null);

  const childrenCount = React.useMemo(() => Children.toArray(children).length, [children]);

  const [activeElementIndex, setActiveElementIndex] = React.useState(defaultActive);

  useEffect(() => {
    setActiveElementIndex(defaultActive);
  }, [defaultActive]);

  const [leftArrowVisible, setLeftArrowVisibility] = React.useState(false);
  const [rightArrowVisible, setRightArrowVisibility] = React.useState(false);
  const [showArrows, setShowArrows] = React.useState(withArrows);

  const scrollIntoView = useCallback(
    (activeIndex) => {
      const parent = childrenWrapper.current;
      if (parent) {
        const element = parent.children[activeIndex] as HTMLElement;
        const left = element?.offsetLeft;
        const singleElementWidth = element?.offsetWidth;

        const parentLeft = parent?.scrollLeft;
        const parentWidth = parent?.offsetWidth;

        if (singleElementWidth * childrenCount < parentWidth) {
          setShowArrows(false);
        }

        const offsetLeft = left; // 27 is a padding size

        if (element) {
          const indexOfElement = Number(element.attributes.getNamedItem('data-index')?.value);

          const positionInPercent = ((offsetLeft - parentLeft) / parentWidth) * 100;

          if (autoScroll) {
            if (positionInPercent > 70) {
              parent?.scrollTo({
                left: parentLeft + parentWidth * 0.25,
                behavior: scrollBehavior,
              });
            } else if (
              positionInPercent < 20 &&
              indexOfElement <= previouslyActiveElementIndex.current
            ) {
              parent?.scrollTo({
                left: parentLeft - parentWidth * 0.25,
                behavior: scrollBehavior,
              });
            }
          }

          previouslyActiveElementIndex.current = indexOfElement;
        }
      }
    },

    [childrenWrapper.current],
  );

  const passedChildren = React.Children.map(children, (child, index) => {
    const newProps: {
      onClick: () => void;
      className: string;
      ref?: React.RefObject<HTMLElement>;
    } = {
      onClick: () => {
        setActiveElementIndex(index);
        if (child.props.onClick instanceof Function) child.props.onClick();
      },
      className: child.props.className.concat(index === activeElementIndex ? ' active' : ''),
    };

    if (index === 0) {
      newProps.ref = firstChild;
    }

    if (index === React.Children.count(children) - 1) {
      newProps.ref = lastChild;
    }

    return React.cloneElement(child, {
      ...child.props,
      ...newProps,
    });
  });

  useVisibility(firstChild, childrenWrapper, setLeftArrowVisibility);
  useVisibility(lastChild, childrenWrapper, setRightArrowVisibility);

  useEffect(() => {
    scrollIntoView(activeElementIndex);
  }, [activeElementIndex]);

  return (
    <Wrapper>
      {showArrows && leftArrowVisible && (
        <Arrow
          direction="left"
          onClick={() =>
            childrenWrapper.current?.scrollTo({
              left: childrenWrapper.current.scrollLeft - childrenWrapper.current.offsetWidth * 0.25,
              behavior: scrollBehavior,
            })
          }
        >
          {customArrows?.left || <ArrowLeft size="12px" />}
        </Arrow>
      )}

      {/* TODO replace ...rest spreading with something better typed */}
      <div className="childrenWrapper" {...rest} ref={childrenWrapper}>
        {passedChildren}
      </div>

      {showArrows && rightArrowVisible && (
        <Arrow
          direction="right"
          onClick={() =>
            childrenWrapper.current?.scrollTo({
              left:
                childrenWrapper.current.scrollLeft +
                (childrenWrapper.current?.offsetWidth || 0) * 0.25,
              behavior: scrollBehavior,
            })
          }
        >
          {customArrows?.right || <ArrowRight size="12px" />}
        </Arrow>
      )}
    </Wrapper>
  );
};
