import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  line-height: 1.5;
  font-family: ${({ theme }) => theme?.shorArtistProfileSecrtion?.fontFamily || theme.fontFamily};
  color: ${({ theme }) => theme.colors.black};
  padding: 0 0 24px 0;
  margin-top: -2px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 8px;
  align-items: center;
  gap: 20px;
  ${media.md.down`
    padding: 0 8px 8px 8px;
  `}
`;

export const LinkSection = styled(Link)<{ isLink: boolean }>`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  cursor: ${({ isLink }) => (isLink ? 'pointer' : 'auto')};
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Salons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const SalonLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;
  display: block;
  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const LinkWithScroll = styled(HashLink)`
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const Image = styled.img`
  border-radius: 50%;
  border: solid 2px #000000;
  width: 72px;
  height: 72px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const Name = styled.h4`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  margin-top: 4px;
`;

export const Description = styled.h4`
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  padding: 0 16px;

  ${media.md.down`
    padding: 0 8px;
  `}
`;

export const BundlesNumber = styled.div`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
`;

export const BundlesCaption = styled.div<{ isInsideLink: boolean }>`
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: ${({ isInsideLink }) => (isInsideLink ? 'underline' : 'none')};
`;
