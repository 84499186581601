import React, { FC, useState, useEffect } from 'react';
import Scroll from 'react-indiana-drag-scroll';
import { sanitize } from 'dompurify';
import * as Ui from './LookProductsPreview.styles';
import { TProduct, TSelectedSku, useImageLazyLoading } from '../..';
import { useProductSelection } from '../../hooks/product/useProductSelection';
import closeIcon from '../../assets/svg/cross.svg';
import { VariantPicker } from '../ProductCard/VariantPicker';

type TLookProductsPreviewProps = {
  products: TProduct[];
  onAddProductToCart: (variant: TSelectedSku) => void;
};

const VariantPickerWrapper = React.memo(
  ({
    product,
    onVariantClick,
  }: {
    product: TProduct;
    onVariantClick: (selectedSku: TSelectedSku) => void;
  }) => {
    const { selectedSku, handleVariantSelection, variants, type } = useProductSelection(
      product,
      onVariantClick,
    );

    useEffect(() => {
      if (selectedSku) {
        onVariantClick(selectedSku);
      }
    }, [selectedSku]);

    return (
      <Ui.VariantPickerWrapper>
        <VariantPicker
          positionTop
          type={type}
          variants={variants}
          selectedSku={selectedSku}
          handleVariantSelection={handleVariantSelection}
          visibleColors={4}
        />
      </Ui.VariantPickerWrapper>
    );
  },
);

VariantPickerWrapper.displayName = 'VariantPickerWrapper';

export const LookProductsPreview: FC<TLookProductsPreviewProps> = ({
  products,
  onAddProductToCart,
}) => {
  const images = products.map((product) => ({
    img: product.primary_sku.images[0],
    id: product._id,
  }));

  const [product, setProduct] = useState<TProduct | undefined>(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedSku, setSelectedSku] = useState<TSelectedSku>();
  useImageLazyLoading(`product-image-preview`, !!products.length);

  const onPreviewClose = () => {
    setVisible(false);
    setTimeout(() => {
      setProduct(undefined);
    }, 1000);
  };

  const onImageClick = (id) => {
    if (product?._id === id) {
      onPreviewClose();
    } else {
      setProduct(products.find((prod) => prod._id === id) as TProduct);
      setVisible(true);
    }
  };

  const onVariantClick = (variant: TSelectedSku) => {
    setSelectedSku(variant);
  };

  return (
    <Ui.Wrapper className="look-products-preview">
      <Ui.ProductPreview visible={visible}>
        <Ui.CloseButton onClick={onPreviewClose}>
          <img src={closeIcon} alt="Close button" />
        </Ui.CloseButton>
        <div>
          <Ui.ProductName dangerouslySetInnerHTML={{ __html: sanitize(product?.name || '') }} />
        </div>
        <Ui.BottomSectionWrapper>
          {!!product && <VariantPickerWrapper onVariantClick={onVariantClick} product={product} />}

          <Ui.AddToCartButton
            onClick={() => {
              if (selectedSku) {
                onAddProductToCart(selectedSku);
              }
            }}
            // to do => add translation
            text="Add to bag"
          />
        </Ui.BottomSectionWrapper>
      </Ui.ProductPreview>
      <Scroll
        hideScrollbars={false}
        style={{ zIndex: 2, background: '#fff', position: 'relative', width: '100%' }}
      >
        <Ui.ScrollContainer>
          {images.map((item) => (
            <Ui.Image
              className="product-image-preview"
              active={item.id === product?._id}
              key={item.id}
              onClick={() => onImageClick(item.id)}
              width={80}
              height={80}
              loading="lazy"
              data-src={item.img}
            />
          ))}
        </Ui.ScrollContainer>
      </Scroll>
    </Ui.Wrapper>
  );
};
