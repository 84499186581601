import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFetchableItem, TLearningCenters } from '@elc/common';
import * as learningCenterActions from '../actions/learningCenter.actions';

const initialState: IFetchableItem<TLearningCenters> = {
  item: {
    default: '',
    learning_centers: [],
  },
  loading: 'pending',
  status: 200,
};

const learningCenterSlice = createSlice({
  name: 'learningCenter',
  initialState,
  reducers: {
    setLearningCenter: (state, action: PayloadAction<TLearningCenters>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(learningCenterActions.fetchLearningCenter.fulfilled, (state, { payload }) => {
      state.loading = 'fullfield';
      state.item = payload;
    });
  },
});

export const learningCenterAction = {
  ...learningCenterSlice.actions,
};

export default learningCenterSlice;
