import styled from 'styled-components';
import { media } from '../../utils/rwd';
import Ctr from '../Container/Container';

export const Container = styled(Ctr)`
  ${media.lg.down`
    padding:0;
  `}
`;

export const Banner = styled.img`
  width: 100%;
  cursor: pointer;
`;
