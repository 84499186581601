import React from 'react';
import styled from 'styled-components';
import { ImageWrapper } from '../LooksList.styles';
import { TRelatedContent } from '../../../typings';
import { analyticsPromoClick } from '../../../utils/googleAnalitics';

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const ImageContent = ({ item, position }: { item: TRelatedContent; position: number }) => {
  const onClick = () => {
    analyticsPromoClick(item, position);
    // eslint-disable-next-line no-alert
    if (item.link && window.confirm(`This link will take you to ${item.link}`)) {
      window.open(item.link);
    }
  };

  return (
    <ImageWrapper onClick={onClick} disabled={!item.link}>
      <Image src={item.url} />
    </ImageWrapper>
  );
};
