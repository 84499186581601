import React from 'react';

import * as Ui from './Header.styles';

type HeaderProps = {
  salonLogoUrl?: string;
  onSalonLogoClick?: () => void;
  brandLogoUrl: string;
  onBrandLogoClick?: () => void;
  className?: string;
  children?: React.ReactNode;
  resizingLogo?: boolean;
  scrolled?: boolean;
};

export const Header = ({
  salonLogoUrl,
  onSalonLogoClick = () => {},
  brandLogoUrl,
  onBrandLogoClick = () => {},
  className = '',
  children,
  resizingLogo = false,
  scrolled = false,
}: HeaderProps) => (
  <Ui.TopBar className={`${className} header_topBar`} larger={resizingLogo && !scrolled}>
    <Ui.RowElement>
      {salonLogoUrl && (
        <Ui.SalonLogo
          src={salonLogoUrl}
          onClick={() => onSalonLogoClick()}
          className="header_salonLogo"
          larger={resizingLogo && !scrolled}
        />
      )}
    </Ui.RowElement>
    <Ui.BrandLogoContainer>
      <Ui.BrandLogo
        src={brandLogoUrl}
        onClick={() => onBrandLogoClick()}
        className="header_brandLogo"
        larger={resizingLogo && !scrolled}
      />
    </Ui.BrandLogoContainer>

    <Ui.RowElement>
      <Ui.IconsContainer>{children}</Ui.IconsContainer>
    </Ui.RowElement>
  </Ui.TopBar>
);
