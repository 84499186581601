import { useState, useEffect, useMemo } from 'react';
import { compose, find, map, filter, result, findIndex, reduce } from 'lodash/fp';

import { EInventory, TLook, TProduct, TSelectedSku, SAddToCart } from '../../../typings';
import { getInitialSelectedSku } from '../../../utils/products';
import { formatPrice } from '../../../utils/formatPrice';

type TSelectedItem = TSelectedSku & { selected: boolean };

export const useProductSelection = (
  look: TLook,
  onAddToCart: SAddToCart,
): {
  totalPrice: string;
  numberOfSelectedProducts: number;
  onCheckboxClick: (product: TProduct) => void;
  onVariantClick: (variant: TSelectedSku) => void;
  isProductSelected: (product: TProduct) => boolean;
  handleAddMultipleProductsToCart: () => void;
} => {
  const [selectedProductsVariants, setSelectedProductsVariants] = useState<TSelectedItem[]>([]);

  const isShoppable = (item: TSelectedItem) => item.inventory.toUpperCase() === EInventory.ACTIVE;

  const onVariantClick = (variant: TSelectedSku) => {
    const selectedProductVariantIndex = findIndex(
      ['productId', variant.productId],
      selectedProductsVariants,
    );
    const selectedProductsVariantsCopy = [...selectedProductsVariants];
    const selectedVariant = selectedProductsVariantsCopy[selectedProductVariantIndex];

    selectedProductsVariantsCopy.splice(selectedProductVariantIndex, 1, {
      ...variant,
      selected: selectedVariant?.selected
        ? variant.inventory.toUpperCase() === EInventory.ACTIVE
        : false,
    });

    setSelectedProductsVariants(selectedProductsVariantsCopy);
  };

  const onCheckboxClick = (product: TProduct) => {
    const selectedProductVariantIndex = findIndex(
      ['productId', product._id],
      selectedProductsVariants,
    );
    const selectedProductsVariantsCopy = [...selectedProductsVariants];
    const selectedVariant = selectedProductsVariantsCopy[selectedProductVariantIndex];

    selectedProductsVariantsCopy.splice(selectedProductVariantIndex, 1, {
      ...selectedVariant,
      selected: selectedVariant?.selected
        ? false
        : selectedVariant.inventory.toUpperCase() === EInventory.ACTIVE,
    });

    setSelectedProductsVariants(selectedProductsVariantsCopy);
  };

  const handleAddMultipleProductsToCart = () => {
    onAddToCart(selectedProductsVariants.filter((p) => p.selected && isShoppable(p)));

    setSelectedProductsVariants(
      selectedProductsVariants.map((item) => ({ ...item, selected: false })),
    );
  };

  const numberOfSelectedProducts = useMemo(
    () => selectedProductsVariants.filter((v) => v.selected).length,
    [selectedProductsVariants],
  );

  const totalPrice = useMemo(
    () =>
      compose(
        (price: number) => formatPrice(price, look?.currency),
        reduce((acc, { retail_price }) => acc + Number(retail_price), 0),
        filter((i: TSelectedItem) => i.selected),
      )(selectedProductsVariants),
    [selectedProductsVariants, look],
  );

  const isProductSelected = (product: TProduct): boolean =>
    compose(result('selected'), find(['productId', product._id]))(selectedProductsVariants);

  useEffect(() => {
    if (look.products.length) {
      const initiallySelectedVariants = compose(
        map((v: TSelectedSku) => ({
          ...v,
          selected: v.inventory.toUpperCase() === EInventory.ACTIVE,
        })),
        map(getInitialSelectedSku),
      )(look.products);
      setSelectedProductsVariants(initiallySelectedVariants);
    }
  }, [look.products]);

  return {
    totalPrice,
    numberOfSelectedProducts,
    onCheckboxClick,
    onVariantClick,
    isProductSelected,
    handleAddMultipleProductsToCart,
  };
};
