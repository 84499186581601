import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoImage from '../../assets/images/profile_placeholder.png';
import { fetchFeaturedArtistAction } from '../../store/featuredArtists/featuredArtists.actions';
import {
  featuredArtistsSelector,
  isfeaturedArtistsLoadingSelector,
} from '../../store/featuredArtists/featuredArtists.selectors';
import { Container } from '../Container/Container.styles';

import { EBrand } from '../../typings';
import * as Ui from './FeaturedArtistsPage.styles';

interface IFeaturedArtistsPageProps {
  brand?: EBrand;
}

export const FeaturedArtistsPage: React.FC<IFeaturedArtistsPageProps> = ({ brand }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featuredArtists = useSelector(featuredArtistsSelector);
  const isfeaturedArtistsLoading = useSelector(isfeaturedArtistsLoadingSelector);

  useEffect(() => {
    dispatch(fetchFeaturedArtistAction());
  }, []);

  useEffect(() => {
    if (!isfeaturedArtistsLoading && !featuredArtists.length) {
      navigate('/about', {
        state: {
          from: '',
        },
      });
    }
  }, [isfeaturedArtistsLoading]);

  if (brand === EBrand.Mac) {
    return (
      <>
        <Ui.MacHero>
          <Container>
            <h1>
              M<span>&#183;</span>A<span>&#183;</span>C Now
            </h1>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua.
            </p>
          </Container>
        </Ui.MacHero>
        <Ui.MacList>
          {featuredArtists.map((artist) => (
            <Ui.MacItem key={artist.uid}>
              <Ui.MacImageWrapper>
                <Ui.Image
                  loading="lazy"
                  width={300}
                  height={300}
                  src={artist.connected_user?.picture || NoImage}
                />
              </Ui.MacImageWrapper>

              <Ui.MacContentWrapper>
                <Ui.MacName>{`${artist.name} ${artist.surename}`}</Ui.MacName>
                <Ui.MacDescription>{artist.connected_user.about}</Ui.MacDescription>

                <Ui.MacLink to={`/a/${artist.vanity_url}`}>View Artist</Ui.MacLink>
              </Ui.MacContentWrapper>
            </Ui.MacItem>
          ))}
        </Ui.MacList>
      </>
    );
  }

  return (
    <Ui.Wrapper>
      <Ui.List>
        {featuredArtists.map((artist) => (
          <Ui.Item
            key={artist.uid}
            onClick={() => {
              navigate(`/a/${artist.vanity_url}`, {
                state: {
                  from: '',
                },
              });
            }}
          >
            <Ui.ImageWrapper>
              <Ui.Image
                loading="lazy"
                width={300}
                height={300}
                src={artist.connected_user?.picture || NoImage}
              />
            </Ui.ImageWrapper>

            <Ui.ContentWrapper>
              <Ui.Name>{`${artist.name} ${artist.surename}`}</Ui.Name>
              <Ui.Vanity>{`@${artist.vanity_url}`}</Ui.Vanity>
            </Ui.ContentWrapper>
          </Ui.Item>
        ))}
      </Ui.List>
    </Ui.Wrapper>
  );
};
