import React, { FC, useEffect, useState } from 'react';
import {
  ProductPage,
  TProduct,
  StatusWrapper,
  CenteredCard,
  formatPrice,
  useAddProductToCart,
  useQueryParams,
} from '@elc/common';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Metatags } from '../components/Metatags/Metatags';
import { fetchBundle } from '../store/actions/bundle.actions';
import { fetchArtist, fetchPrivateArtistProfile } from '../store/actions/artist.actions';
import { useGlobalState, useThunkDispatch } from '../store';
import NoImage from '../assets/img/mac.png';
import { increaseEntriesCounter } from '../utils/increaseEntriesCounter';

type ProductRouteParams = { vanityUrl: string; shortId: string };

const ProductScreen: FC = () => {
  const { vanityUrl = '', shortId = '' } = useParams<ProductRouteParams>();
  const { owner_token: ownerPageToken } = useQueryParams();

  const dispatch = useThunkDispatch();
  const { status, item: artist } = useGlobalState((state) => state.artist);
  const { item: look } = useGlobalState((state) => state.bundle);
  const { t } = useTranslation(['app', 'components', 'metatags']);

  const [product, setProduct] = useState<TProduct | null>(null);

  useEffect(() => {
    if (ownerPageToken) {
      dispatch(fetchPrivateArtistProfile({ vanity_url: vanityUrl, token: ownerPageToken }));
    } else {
      dispatch(fetchArtist(vanityUrl));
    }
    dispatch(fetchBundle({ shortId }));
    increaseEntriesCounter(look._id, 'SINGLE_PRODUCT_PAGE');
  }, [shortId, vanityUrl]);

  useEffect(() => {
    if (look.products.length > 0) {
      setProduct(look.products[0]);
    }
  }, [look]);

  const addProductToCart = useAddProductToCart();

  if (!product) {
    return null;
  }

  return (
    <StatusWrapper
      status={status}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.artistNotFound')} />
      }
    >
      <>
        <Metatags
          title={product.name}
          description={`${t('metatags:buyNowFor')}: ${formatPrice(
            product.primary_sku.retail_price,
            product.currency,
          )} 
          ${product.description}`}
          image={product.primary_sku.images[0]}
        />
        <ProductPage
          product={product}
          onAddToCart={addProductToCart(shortId)}
          artist={artist}
          noImage={NoImage}
        />
      </>
    </StatusWrapper>
  );
};

export { ProductScreen };
