import { isEmpty } from 'lodash';
import { validSocials } from '../constants/socials/validSocials';

const TTL = 1000 * 60 * 60 * 24 * 7; // 7 days

const REFERRER_KEY = 'elc_referrer';
type TReferrer = typeof validSocials[number];

class ReferrerService {
  referrer: string;

  constructor() {
    this.referrer = document.referrer;
  }

  getReferrer = () => {
    const referrerObject = JSON.parse(localStorage.getItem(REFERRER_KEY) || '{}');
    if (isEmpty(referrerObject)) {
      return null;
    }
    const currentTime = new Date().getTime();
    if (currentTime > referrerObject.expiresAt) {
      return null;
    }
    return referrerObject.referrer;
  };

  // checks if referrer is from one of whitelisted domains
  categorizeReferrer = () => {
    const categorizedReferrer = validSocials.find(
      (referrerType: TReferrer) => !!this.referrer.match(referrerType),
    );
    return categorizedReferrer;
  };

  // sets referrer to localStorage but only if it comes from one of whitelisted domains
  setReferrer = () => {
    if (this.categorizeReferrer()) {
      const expiresAt = new Date().getTime() + TTL;
      localStorage.setItem(REFERRER_KEY, JSON.stringify({ referrer: this.referrer, expiresAt }));
    }
  };
}

export const referrerInstance = new ReferrerService();
