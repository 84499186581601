import styled from 'styled-components';
import { media } from '../../utils/rwd';
import Ctr from '../Container/Container';

export const Wrapper = styled.div`
  background: black;
  padding-top: 30px;
  padding-bottom: 30px;

  ${media.lg`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

export const Container = styled(Ctr)`
  padding-right: 0;
  ${media.lg`
    padding-right: 20;
  `}
`;
export const SectionTitle = styled.h1`
  font-size: 40px;
  margin-bottom: 20px;
  color: #fff;
`;

export const CarouselWrapper = styled.div`
  overflow: hidden;

  .swiper-container {
    position: relative;
    padding-bottom: 20px;
  }

  .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    &.swiper-pagination-hidden {
      opacity: 0;
    }
    .swiper-pagination-disabled > &,
    &.swiper-pagination-disabled {
      display: none !important;
    }
  }
  /* Common Styles */
  .swiper-pagination-fraction,
  .swiper-pagination-custom,
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0;
    top: var(--swiper-pagination-top, auto);
    left: 0;
    width: 100%;
  }
  /* Bullets */
  .swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0;
    .swiper-pagination-bullet {
      transform: scale(0.33);
      position: relative;
    }
    .swiper-pagination-bullet-active {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-main {
      transform: scale(1);
    }
    .swiper-pagination-bullet-active-prev {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-prev-prev {
      transform: scale(0.33);
    }
    .swiper-pagination-bullet-active-next {
      transform: scale(0.66);
    }
    .swiper-pagination-bullet-active-next-next {
      transform: scale(0.33);
    }
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background: white;
    opacity: 0.5;
    cursor: pointer;
    @at-root button#{&} {
      border: none;
      margin: 0;
      padding: 0;
      box-shadow: none;
      appearance: none;
    }
    .swiper-pagination-clickable & {
      cursor: pointer;
    }

    &:only-child {
      display: none !important;
    }
  }
  .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-horizontal.swiper-pagination-bullets {
    .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
    }
    &.swiper-pagination-bullets-dynamic {
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;
      .swiper-pagination-bullet {
        transition: 200ms transform, 200ms left;
      }
    }
  }

  .swiper-pagination-lock {
    display: none;
  }
  ///////

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 50px;
    height: 50px;
    opacity: 0.5;
    background-color: white;
    border-radius: 50%;
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    &.swiper-button-disabled {
      display: none;
    }
    &.swiper-button-hidden {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }

    &:after {
      font-family: swiper-icons;
      font-size: var(--swiper-navigation-size);
      text-transform: none !important;
      letter-spacing: 0;
      font-variant: initial;
      line-height: 1;
    }
  }
  .swiper-button-prev,
  .swiper-rtl .swiper-button-next {
    &:after {
      content: 'prev';
    }
    left: var(--swiper-navigation-sides-offset, 10px);
    right: auto;
  }
  .swiper-button-next,
  .swiper-rtl .swiper-button-prev {
    &:after {
      content: 'next';
    }
    right: var(--swiper-navigation-sides-offset, 10px);
    left: auto;
  }
`;

export const DotsWrapper = styled.div`
  margin-top: 30px;

  ${media.lg`
    margin-top: 40px;
  `}

  .BrainhubCarousel__dot:before {
    width: 6px;
    height: 6px;
    background: white;

    ${media.lg`
      width: 15px;
      height: 15px;
    `}
  }
`;
