import styled, { DefaultTheme } from 'styled-components';
import { ReactComponent as CloseIcon } from '../../assets/svg/cross.svg';
import { hideScrollBars } from '../../utils/styled-components/mixins';

export const Wrapper = styled.div<{
  popupPostionTop: number;
  arrowPosition: number;
  visible: boolean;
}>`
  width: 270px;
  background: white;
  position: fixed;
  transform: translateY(-100%);
  right: 0;
  top: 0;
  z-index: 106;
  border: 1px solid grey;
  background: #f1f1f1;
  transition: 0.6s ease-in-out;
  ${({ visible }) =>
    visible &&
    `
    transform: translateY(0);
    top: 0;
    `}
`;

export const Content = styled.div`
  max-height: calc(100vh - 300px);
  ${hideScrollBars}
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 8px;
`;

export const CloseButton = styled(CloseIcon)`
  cursor: pointer;
  width: 18px;
  height: 18px;
`;

export const SpinnerWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  alingn-self: flex-end;
`;

export const BasketItemWrapper = styled.div`
  display: flex;
  position: relative;
  background: white;
  padding: 10px 20px 10px 10px;
`;

export const BasketItemContent = styled.div`
  color: #000000;
  font-size: 12px;
  flex: 1;
  padding-left: 6px;
  .name {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 14px;
    margin: 0;
    max-width: 95%;
  }

  .description {
    line-height: 15px;

    margin: 6px 0;
  }

  .price {
    text-transform: uppercase;
    font-weight: bold;
    line-height: 17px;
    margin: 0;
    font-size: 14px;
  }
`;

export const BasketItemImage = styled.div`
  width: 80px;
  height: 80px;

  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

// How to handle long names of colors that doesn't fit one line?
export const ColorName = styled.h4.attrs(({ className = '' }) => ({
  className,
}))<{ color: string; theme: DefaultTheme }>`
  font-size: ${({ theme }) => theme.productCard.colorName.fontSize};
  font-weight: 500;
  line-height: ${({ theme }) => theme.productCard.colorName.lineHeight};
  color: ${({ color }) => color};
  margin: 0;
  margin-left: 4px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0;
`;

export const Size = styled.p`
  padding: 5px 10px;
  background: black;
  color: white;
`;

export const Link = styled.a`
  text-align: center;
  color: unset;
  font-size: 14px;
  cursor: pointer;
`;
