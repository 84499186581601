import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Ui from './HtmlTruncatedText.styles';

export const HtmlShowMore = ({
  initialReadMore = false,
  showMore,
  orginalHtml,
  truncatedHtml,
  setDisplayedHtml,
  pageUrl,
  contentRemoved,
}: {
  initialReadMore: boolean;
  showMore: boolean;
  orginalHtml: string;
  truncatedHtml: string;
  setDisplayedHtml: (html: string) => void;
  pageUrl?: string;
  contentRemoved: boolean;
}) => {
  const { t } = useTranslation(['components']);
  const [readMore, setReadMore] = useState<boolean>(false);

  useEffect(() => {
    setReadMore(initialReadMore);
  }, [initialReadMore]);

  const handleShowMore = () => {
    if (readMore) {
      setReadMore(false);
      setDisplayedHtml(orginalHtml);
    } else {
      setReadMore(true);
      setDisplayedHtml(truncatedHtml);
    }
  };

  if (!showMore || !contentRemoved) {
    return null;
  }

  if (pageUrl) {
    return <Ui.StyledShowMore to={pageUrl}>{t('readMoreText')}</Ui.StyledShowMore>;
  }

  return (
    <Ui.StyledDiv onClick={handleShowMore}>
      {readMore ? t('readMoreText') : t('hideText')}
    </Ui.StyledDiv>
  );
};
