import React, { ReactNode } from 'react';

type ComponentLoaderProps = {
  skeleton: ReactNode;
  isReady: boolean;
  children: ReactNode;
};

export const ComponentLoader = ({ skeleton, isReady, children }: ComponentLoaderProps) => (
  // Without this rule disabled and without fragment TS would complain that LoadingComponent can't be used as JSXElement
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{isReady ? children : skeleton}</>
);
