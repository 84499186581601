import React, { useCallback, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { EBrand, TSizeWithSkuUid } from '../../typings';
import { TSelectedSku } from '../../typings/types/SelectedSku';
import { formatPrice } from '../../utils/formatPrice';
import { getSizeName } from '../../utils/getSizeName';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';
import * as Ui from './SizePicker.styles';

type SizePickerProps = {
  onClick: (size: TSizeWithSkuUid) => void;
  sizes: Array<TSizeWithSkuUid>;
  selectedSku: TSelectedSku;
  positionTop: boolean;
};

const getSearchInputItemName = (size: TSizeWithSkuUid, selectedSku: TSelectedSku): string => {
  const { brand } = useContext(ThemeContext);

  const sizeName = getSizeName(size?.name);

  if (brand === EBrand.Mac) {
    return sizeName;
  }

  return `${sizeName} ${formatPrice(size?.retail_price, selectedSku?.currency)}`;
};

const SizeSearchInput = ({
  sizeName,
  active = false,
  showArrow,
}: {
  sizeName: string;
  active?: boolean;
  showArrow: boolean;
}) => (
  <Ui.Select showArrow={showArrow}>
    <Ui.Value>{sizeName}</Ui.Value>

    {showArrow && (
      <Ui.Arrow active={active}>
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.64784 1.46745L2.11528 8L8.64784 14.5326L8.13877 15.0416L1.09718 8.00003L8.13877 0.95843L8.64784 1.46745Z"
            fill="black"
            stroke="black"
            strokeWidth="0.5"
          />
        </svg>
      </Ui.Arrow>
    )}
  </Ui.Select>
);

const SizeSearchDropdownItem = ({
  sizeName,
  onClick,
}: {
  sizeName: string;
  onClick: () => void;
}) => (
  <Ui.Item onClick={onClick}>
    <Ui.ItemValue>{sizeName}</Ui.ItemValue>
  </Ui.Item>
);

export const SizePicker = ({ onClick, sizes, selectedSku, positionTop }: SizePickerProps) => {
  const selectedDefault = useCallback(
    (option) => option.skuUid === selectedSku?.uid,
    [selectedSku],
  );

  return (
    <E2EWrapper id="sizeDropdownButton">
      <Ui.Dropdown
        positionTop={positionTop}
        options={sizes}
        itemsNumber={sizes.length}
        selectedDefault={selectedDefault}
        renderTrigger={(selectedSize, open) => (
          <SizeSearchInput
            showArrow={sizes.length > 1}
            active={open}
            sizeName={getSearchInputItemName(selectedSize, selectedSku)}
          />
        )}
        renderOption={(option) => (
          <SizeSearchDropdownItem
            sizeName={getSearchInputItemName(option, selectedSku)}
            onClick={() => onClick(option)}
          />
        )}
      />
    </E2EWrapper>
  );
};
