import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media } from '../../utils/rwd';
import { EBrand } from '../../typings';

export const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  position: relative;

  text-align: center;
  padding: 0px 20px;
  max-width: 620px;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    theme.brand === EBrand.Mac ? `margin-bottom: -46px;  top: -46px;` : `  padding-top: 20px;`}

  ${media.lg`
    top: 0px;
    padding: 32px 0;
    margin-bottom: 0;
  `}
`;

export const BasketWrapper = styled.span`
  position: absolute;
  bottom: 20px;
  right: 10px;
  z-index: 3;
`;

export const CounterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 50px;
`;

export const LogoWrapper = styled.div`
  position: relative;
  background: #000;
  padding: 20px 0 65px 0;
  text-align: center;
  width: 100%;

  ${media.lg`
    display: none;
  `}
`;

export const ProfileImageWrapper = styled.div`
  width: 92px;
  height: 92px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const Image = styled.img`
  border-radius: 50%;
  width: 86px;
  height: 86px;
  object-fit: contain;
`;

export const Name = styled.div`
  font-size: ${({ theme }) => theme?.artistProfileSection?.title?.fontSize};
  line-height: 24px;
  font-weight: bold;
  text-transform: ${({ theme }) => theme?.artistProfileSection?.title?.textTransform || 'none'};
  margin-top: 18px;
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Salons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const SalonLink = styled(Link)`
  font-size: 14px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SalonName = styled.div`
  font-size: 14px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;
`;
