/* eslint-disable */

import React, { FC } from 'react';
import { TSizeWithSkuUid } from '../..';

import * as Ui from './SizeSearch.styles';

type TSizeSearchProps = {
  sizes: Array<TSizeWithSkuUid>;
  selectedDefault: (option) => boolean;
  onClick: (size: TSizeWithSkuUid) => void;
};

const SizeSearchInput = ({
  size,
  active = false,
  showArrow,
}: {
  size: TSizeWithSkuUid;
  active?: boolean;
  showArrow: boolean;
}) => (
  <Ui.Select showArrow={showArrow}>
    <Ui.Value>{size?.name}</Ui.Value>
    {showArrow && (
      <Ui.Arrow active={active}>
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.64784 1.46745L2.11528 8L8.64784 14.5326L8.13877 15.0416L1.09718 8.00003L8.13877 0.95843L8.64784 1.46745Z"
            fill="black"
            stroke="black"
            strokeWidth="0.5"
          />
        </svg>
      </Ui.Arrow>
    )}
  </Ui.Select>
);

const SizeSearchDropdownItem = ({
  size,
  onClick,
}: {
  size: TSizeWithSkuUid;
  onClick: () => void;
}) => (
  <Ui.Item onClick={onClick}>
    <Ui.ItemValue>{size.name}</Ui.ItemValue>
  </Ui.Item>
);

const SizeSearch: FC<TSizeSearchProps> = ({ sizes, onClick, selectedDefault }) => {
  return (
    <Ui.Dropdown
      options={sizes}
      selectedDefault={selectedDefault}
      renderTrigger={(selectedSize, open) => {
        return <SizeSearchInput showArrow={sizes.length > 1} active={open} size={selectedSize} />;
      }}
      renderOption={(option) => (
        <SizeSearchDropdownItem size={option} onClick={() => onClick(option)} />
      )}
    />
  );
};

export { SizeSearch };
