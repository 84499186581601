import { EInventory, EVariant, IEntityBasket } from '../../..';
import { TCartItem } from '../../../typings/types/Sku';

export const twoItemsBasketMock: IEntityBasket<TCartItem> = {
  items: [
    {
      id: 3987,
      uid: 'vbPkaOwY5MexzRVG5RMzKLqB',
      sku: '26385',
      product_code: 'MH5401',
      retail_price: '40.00',
      name: 'Complete Comfort Creme',
      description:
        'Eine Feuchtigkeitscreme, welche die Haut rehydriert und für empfindliche Haut geeignet ist.',
      photo_url: 'https://sdcdn.io/mac/de/mac_sku_MH5401_1x1_0.png?width=1080&height=1080',
      currency: 'EUR',
      size: {
        uid: 'LOr0jwQPVjr97NX6Vd4knlYa',
        id: 285,
        name: '50 ml',
        description: '',
      },
      color: {
        uid: 'XE0dKW253LydWGEbPRrNDyex',
        id: 4565,
        name: '',
        hex_value: '',
      },
      variant: EVariant.SIZE,
      inventory: EInventory.ACTIVE,
      images: ['https://sdcdn.io/mac/de/mac_sku_MH5401_1x1_0.png?width=1080&height=1080'],
      iln_listing:
        "Ingredients: Water\\Aqua\\Eau, Dimethicone, Butylene Glycol, Cetyl Esters, Caprylic/Capric/Myristic/Stearic Triglyceride, Ethylhexyl Palmitate, Myristyl Myristate, Aleurites Moluccanus Seed Oil, Isononyl Isononanoate, Tocopheryl Acetate, Sodium Hyaluronate, Lecithin, Squalane, Hypnea Musciformis (Algae) Extract, Gelidiella Acerosa Extract, Algae Extract, Astrocaryum Murumuru Seed Butter, Vitis Vinifera (Grape) Seed Extract, Cola Acuminata (Kola) Seed Extract, Hordeum Vulgare Extract\\Extrait D'Orge, Polygonum Cuspidatum Root Extract, Humulus Lupulus (Hops) Extract, Macrocystis Pyrifera (Kelp) Extract, Triticum Vulgare (Wheat) Germ Extract, Citrus Reticulata (Tangerine) Peel Extract, Punica Granatum (Pomegranate) Fruit Juice, Asparagopsis Armata Extract, Yeast Extract\\Faex\\Extrait De Levure, Ascophyllum Nodosum Extract, Hydrolyzed Wheat Protein, Acetyl Glucosamine, Myristyl Laurate, Ethylhexyl Isononanoate, Peg-10 Dimethicone, Sorbitol, Propylene Glycol Laurate, Phytosphingosine, Caprylyl Glycol, Hexylene Glycol, Glycine, Sucrose, Cyclodextrin, Carbomer, Sodium Stearoyl Lactylate, Polysorbate 20, Sorbitan Laurate, Tocopherol, Rosmarinus Officinalis (Rosemary) Leaf Extract, Polydecene, Linolenic Acid, Linoleic Acid, Polyglyceryl-10 Pentastearate, Nordihydroguaiaretic Acid, Glyceryl Stearate, Peg-100 Stearate, Behenyl Alcohol, Propylene Glycol Stearate, Sodium Hydroxide, Myristyl Alcohol, Fragrance (Parfum), Ethylbisiminomethylguaiacol Manganese Chloride, Disodium Edta, Phenoxyethanol, Iron Oxides (Ci 77491), Iron Oxides (Ci 77492) <ILN49713>",
      productId: 'g7a3mn4wOl0YwEWkO5J1eyDK',
      bundleId: 'R424O',
      quantity: 1,
      parent_product: '123123',
    },
    {
      id: 4448,
      uid: 'kBOyWqEbzkWlbJ1qzP8eQ29K',
      sku: '58355',
      product_code: 'MW3A05',
      retail_price: '47.50',
      name: 'Studio Face and Body Foundation 120 ml',
      description:
        'Eine federleichte Foundation mit natürlichem Satin-Finish und leichter Deckkraft.',
      photo_url: 'https://sdcdn.io/mac/de/mac_sku_MW3A05_1x1_0.png?width=1080&height=1080',
      currency: 'EUR',
      size: {
        uid: 'ykbjeMgXO7NEwgQ6d4EW23B9',
        id: 328,
        name: '120 ml',
        description: '',
      },
      color: {
        uid: 'dXjOR5mo9X2rzRx58pw3bKPe',
        id: 2679,
        name: 'C3',
        hex_value: '#cab49a',
      },
      variant: EVariant.COLOR,
      inventory: EInventory.ACTIVE,
      images: [
        'https://sdcdn.io/mac/de/mac_sku_MW3A05_1x1_0.png?width=1080&height=1080',
        'https://sdcdn.io/mac/de/mac_sku_MW3A05_1x1_1.png?width=1080&height=1080',
        'https://sdcdn.io/mac/de/mac_sku_MW3A05_1x1_2.png?width=1080&height=1080',
        'https://sdcdn.io/mac/de/mac_sku_MW3A05_1x1_3.png?width=1080&height=1080',
      ],
      iln_listing:
        'Ingredients: Water\\Aqua\\Eau, Dimethicone, Hydrogenated Polyisobutene, Butylene Glycol, Pvp/Hexadecene Copolymer, Stearic Acid, Phenyl Trimethicone, Polyglyceryl-3 Diisostearate, Ethylhexyl Hydroxystearate, Vp/Eicosene Copolymer, Talc, Tocopherol, Tocopheryl Acetate, Citrus Aurantium Dulcis (Orange), Fusanus Spicatus Wood Oil, Xanthan Gum, Triethanolamine, Polysorbate 20, Magnesium Aluminum Silicate, Trimethylsiloxysilicate, Cetyl Alcohol, Disodium Edta, Phenoxyethanol, Chlorphenesin,  [+/- Mica, Titanium Dioxide (Ci 77891), Iron Oxides (Ci 77491, Ci 77492, Ci 77499), Chromium Oxide Greens (Ci 77288)] <ILN40892>',
      productId: 'MvakVKOejEDqmdAy7ylbP2gr',
      bundleId: 'R424O',
      quantity: 1,
      parent_product: '12321341',
    },
  ],
  lastAddedItems: [],
  lookFromLastItemWasAdded: 'ABC12',
  artistId: '0000',
};
