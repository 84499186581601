import React from 'react';
import { ImageSkeleton, Skeleton, SkeletonRectangle } from '../../Skeleton/Skeleton';
import * as Ui from '../LearningCenter.styles';

const background = '#dfe0e2';
const foreground = '#eaecec';

export const LearningCenterSkeleton = () => (
  <Ui.Wrapper>
    <Ui.PrimarySection>
      <Skeleton
        style={{ minWidth: '200px', maxWidth: '300px', width: '100%' }}
        height={60}
        foregroundColor={foreground}
        backgroundColor={background}
      >
        <SkeletonRectangle width="300" height="60" />
      </Skeleton>
    </Ui.PrimarySection>
    <Ui.PrimarySubtitle>
      <Skeleton
        style={{ minWidth: '200px', maxWidth: '500px', width: '100%' }}
        height={24}
        backgroundColor={background}
        foregroundColor={foreground}
      >
        <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
      </Skeleton>
    </Ui.PrimarySubtitle>
    <Ui.PostsGrid>
      {Array.from({ length: 3 }).map((_, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Ui.PostCard key={idx}>
          <Ui.PostImage>
            <ImageSkeleton />
          </Ui.PostImage>
          <Ui.PostColumn>
            <Ui.PostTitle>
              <Skeleton
                style={{ minWidth: '100px', maxWidth: '200px', width: '100%', height: '2rem' }}
                // backgroundColor={background}
                // foregroundColor={foreground}
              >
                <SkeletonRectangle style={{ width: '100%', height: '100%' }} />
              </Skeleton>
            </Ui.PostTitle>
            <Ui.PostDescription>
              <Skeleton style={{ width: '100%' }} height={34}>
                <SkeletonRectangle style={{ width: '100%' }} height="14" />
                <SkeletonRectangle y="19" style={{ width: '100%' }} height="14" />
              </Skeleton>
            </Ui.PostDescription>
          </Ui.PostColumn>
        </Ui.PostCard>
      ))}
    </Ui.PostsGrid>
  </Ui.Wrapper>
);
