import styled from 'styled-components/macro';

export const Dropdown = styled.div`
  position: relative;
  cursor: pointer;
  .dropdown-list {
    position: absolute;
    width: 100%;
    overflow: hidden;

    div {
      overflow-y: scroll;
    }
  }
`;
