import styled from 'styled-components';

// TODO maybe pass gap size as argument
export const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  gap: 30px;

  a {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .facebook {
    background-color: #1877f2;
  }

  .instagram {
    background-color: #f00073;
  }

  .pinterest {
    background-color: #e60023;
  }

  .tiktok {
    background-color: #000000;
  }
`;
