import React, { ReactNode } from 'react';

export const E2EWrapper = ({ id, children, ...rest }: { id: string; children: ReactNode }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { 'data-testId': id, ...rest });
    }
    return child;
  });

  if (childrenWithProps && children) {
    // eslint-disable-next-line
    return <>{childrenWithProps}</>;
  }

  return null;
};
