import React, { FC } from 'react';
import { Footer, useScrollController } from '@elc/common';
import { Outlet } from 'react-router-dom';

import MacLogo from '../../assets/logo.svg';
import MacLogoWhite from '../../assets/mac-white-logo.png';
import { PageBackground, StyledHeader, Wrapper } from './Layout.styles';
import footerLinks from '../../constants/footerLinks';
import languages from '../../constants/languages';

const Layout: FC = () => {
  const scrolled = useScrollController(50);

  return (
    <Wrapper>
      <StyledHeader brandLogoUrl={MacLogo} resizingLogo scrolled={scrolled} />

      <PageBackground>
        <Outlet />
      </PageBackground>
      <Footer
        size={100}
        logo={MacLogoWhite}
        links={footerLinks}
        languages={languages}
        showDisclaimer={false}
      />
    </Wrapper>
  );
};

export default Layout;
