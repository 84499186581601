import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchFeaturedArtistAction } from './featuredArtists.actions';
import { FEATURED_ARTISTS_REDUCER_KEY } from './featuredArtists.constants';
import { TFeaturedArtist } from '../../typings';

export type TFeaturedArtistsState = {
  items: TFeaturedArtist[];
  loading: boolean;
};

const initialState: TFeaturedArtistsState = {
  items: [],
  loading: true,
};

const featuredAritstsSlice = createSlice({
  name: FEATURED_ARTISTS_REDUCER_KEY,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchFeaturedArtistAction.fulfilled,
      (state, { payload }: PayloadAction<TFeaturedArtist[]>) => {
        state.items = payload.filter((artist) => artist.connected_user);
        state.loading = false;
      },
    );
    builder.addCase(fetchFeaturedArtistAction.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const featuredAritstsActions = {
  ...featuredAritstsSlice.actions,
};

export const featuredAritstsReducer = featuredAritstsSlice.reducer;
