import React from 'react';
import StarRatings from 'react-star-ratings';

import * as Ui from './StarRating.styles';

type StarRatingProps = {
  rating: number;
  count: number;
  starsNumber?: number;
};

export const StarRating = ({ rating, count, starsNumber = 5, ...rest }: StarRatingProps) => (
  <Ui.Wrapper {...rest}>
    {count ? (
      <>
        <StarRatings
          numberOfStars={starsNumber}
          rating={rating}
          starDimension="15px"
          starSpacing="0"
          starRatedColor="#000000"
          starEmptyColor="rgba(0,0,0,0.5)"
        />
        <Ui.Count>({count})</Ui.Count>
      </>
    ) : (
      <div style={{ height: '26px' }} />
    )}
  </Ui.Wrapper>
);
