import styled from 'styled-components';
import { Header } from '@elc/common';

export const Wrapper = styled.div`
  background: #f1f3f6;
`;

export const PageBackground = styled.div`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  background: #f1f3f6;
`;

export const StyledHeader = styled(Header)`
  .header_brandLogo {
    height: 70%;
  }
  top: 0;
`;
