import React, { FC, useState, useCallback, useRef } from 'react';
import Carousel, { slidesToShowPlugin, Dots, arrowsPlugin } from '@brainhubeu/react-carousel';

import { ProductCard, SAddToCart, TLook } from '../..';
import { useImageLazyLoading } from '../../hooks/useImageLazyLoad';
import { ReactComponent as CheckMark } from '../../assets/svg/check-mark.svg';
import { useProductSelection } from './hooks/useProductSelection';
import { SProductDetailsClick } from '../../typings';
import { LeftCarouselArrow, RightCarouselArrow } from '../CarouselArrows/CarouselArrows';
import * as Ui from './LookProductsList.styles';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

const ProductCardOverlay: FC<{
  isChecked: boolean;
  onClick: () => void;
}> = ({ isChecked, onClick, children }) => (
  <Ui.ProductCardWrapper>
    <Ui.ClickingArea onClick={onClick}>
      <Ui.Checkbox isChecked={isChecked}>{isChecked && <CheckMark />}</Ui.Checkbox>
    </Ui.ClickingArea>

    {children}
  </Ui.ProductCardWrapper>
);

export const LookProductsList = ({
  look,
  onAddToCart,
  onProductDetailsClick,
  onModalClose,
}: {
  look: TLook;
  onAddToCart: SAddToCart;
  onProductDetailsClick?: SProductDetailsClick;
  onModalClose?: () => void;
}) => {
  useImageLazyLoading('product-image', !!look?.products?.length);

  const {
    totalPrice,
    numberOfSelectedProducts,
    onCheckboxClick,
    onVariantClick,
    isProductSelected,
    handleAddMultipleProductsToCart,
  } = useProductSelection(look, onAddToCart);

  const [activeSlide, setActiveSlide] = useState<number>(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const carouselDotsVisible = look?.pictures.length > 1;

  const onChange = useCallback((value) => {
    setActiveSlide(value);
  }, []);

  const renderLookCoverPhoto = () => {
    if (!look.pictures.length) return null;

    if (look.pictures.length === 1) {
      return (
        <Ui.CoverPhotoWrapper>
          {!!look.pictures.length && <Ui.CoverPhoto src={look.pictures[0]} />}
        </Ui.CoverPhotoWrapper>
      );
    }

    return (
      <Ui.CarouselWrapper>
        <Carousel
          value={activeSlide}
          onChange={onChange}
          plugins={[
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 1,
              },
            },
            {
              resolve: arrowsPlugin,
              options: {
                arrowLeft: (
                  <Ui.CarouselArrowWrapper arrow="left">
                    <LeftCarouselArrow />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowLeftDisabled: (
                  <Ui.CarouselArrowWrapper arrow="left">
                    <LeftCarouselArrow disabled />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowRight: (
                  <Ui.CarouselArrowWrapper arrow="right">
                    <RightCarouselArrow />
                  </Ui.CarouselArrowWrapper>
                ),
                arrowRightDisabled: (
                  <Ui.CarouselArrowWrapper arrow="right">
                    <RightCarouselArrow disabled />
                  </Ui.CarouselArrowWrapper>
                ),
                addArrowClickHandler: true,
              },
            },
          ]}
        >
          {!!look.pictures.length &&
            look.pictures.map((picture) => (
              <Ui.Slide role="button" key={picture}>
                <Ui.CoverPhotoWrapper>
                  <Ui.Image src={picture} />
                </Ui.CoverPhotoWrapper>
              </Ui.Slide>
            ))}
        </Carousel>
        <Ui.DotsWrapper>
          <Dots value={activeSlide} onChange={setActiveSlide} number={look?.pictures.length} />
        </Ui.DotsWrapper>
      </Ui.CarouselWrapper>
    );
  };

  return (
    <Ui.ContentContainer
      className="container"
      offsetTop={wrapperRef.current?.offsetTop}
      ref={wrapperRef}
    >
      <Ui.CoverPhotoColumn className="cover-photo-column d-flex align-items-center">
        {renderLookCoverPhoto()}
      </Ui.CoverPhotoColumn>

      <Ui.ContentColumn noPaddingTop={carouselDotsVisible}>
        {!!numberOfSelectedProducts && (
          <Ui.FixedBarWrapper>
            <Ui.FixedBar>
              <p>{`Total price: ${numberOfSelectedProducts ? totalPrice : '0'}`}</p>

              <E2EWrapper id="addAllItemsButton">
                <Ui.FixedBarButton
                  disabled={!numberOfSelectedProducts}
                  onClick={handleAddMultipleProductsToCart}
                  // to do => add translation
                  text={`Add ${numberOfSelectedProducts} item${
                    numberOfSelectedProducts > 1 ? 's' : ''
                  } to bag`}
                />
              </E2EWrapper>
            </Ui.FixedBar>
          </Ui.FixedBarWrapper>
        )}

        <Ui.ContentWrapper>
          <Ui.ContentHeading>{look.name}</Ui.ContentHeading>
          <Ui.LookDescription>
            <HtmlTruncatedText sentencesCount={1} html={look.message} />
          </Ui.LookDescription>
        </Ui.ContentWrapper>

        <Ui.ProductListWrapper>
          {look.products.map((product, idx) => (
            <ProductCardOverlay
              isChecked={isProductSelected(product)}
              onClick={() => {
                onCheckboxClick(product);
              }}
              key={product.name}
            >
              <ProductCard
                onVariantClick={(selectedSku) => {
                  onVariantClick(selectedSku);
                }}
                onProductDetailsClick={onProductDetailsClick?.(look.name)?.(idx)}
                onModalClose={onModalClose}
                onAddToCart={onAddToCart}
                product={product}
                visibleColors={4}
              />
            </ProductCardOverlay>
          ))}
        </Ui.ProductListWrapper>
      </Ui.ContentColumn>
    </Ui.ContentContainer>
  );
};

export default LookProductsList;
