import React, { FC } from 'react';
import { EBrand } from '../../typings/enums/Brands';

import ArrowRigth from '../../assets/svg/arrow-right-topbar.svg';
import MACLogo from '../../assets/svg/mac-now-logo.svg';
import AvedaLogo from '../../assets/svg/aveda-logo.svg';

import * as Ui from './Topbar.styles';

type TopbarProps = {
  text?: string;
  onClick?: () => void;
  links?: Array<{ href?: string; onClick?: () => void; text: string | JSX.Element }>;
  children?: React.ReactNode;
  brand: EBrand;
  linkVisible: boolean;
  logo?: string;
};

export const Topbar: FC<TopbarProps> = ({
  text = '',
  onClick,
  links,
  children: basket,
  brand,
  linkVisible,
  logo,
}) => (
  <Ui.Wrapper>
    <Ui.Topbar visible={linkVisible}>
      <Ui.LinksWrapper>
        {logo && <Ui.Logo src={logo} />}
        {text.length && (
          <Ui.Link visible={linkVisible} className="topbar_link" onClick={onClick}>
            <div>
              {text}
              <img src={ArrowRigth} alt="right arrow" style={{ marginLeft: '8px' }} />
            </div>
          </Ui.Link>
        )}
        {links &&
          links.map((link) => (
            <Ui.Link visible key={link.href} href={link.href} onClick={link.onClick}>
              {link.text}
            </Ui.Link>
          ))}
      </Ui.LinksWrapper>

      <Ui.BrandLogo src={brand === EBrand.Mac ? MACLogo : AvedaLogo} alt="Brand Logo" />
      <Ui.BasketWrapper>{basket}</Ui.BasketWrapper>
    </Ui.Topbar>
  </Ui.Wrapper>
);
