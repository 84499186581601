import styled from 'styled-components';
import { Container } from '../Container/Container.styles';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div`
  background: #000;
  position: sticky;
  top: 0;
  z-index: 11;
`;

export const Topbar = styled(Container)<{ visible: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  padding: 10px 20px;

  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  gap: 12px;
  font-size: 14px;
  background: #000;
  width: 100%;
  justify-content: space-between;

  ${media.lg`
    display:flex;
    height: 50px;
    align-items:center;
  `}
`;

export const LinksWrapper = styled.div`
  display: flex;
  gap: 20px;
  height: 100%;
  align-items: center;
  a {
    font-size: 14px;
    line-height: 1;
    color: white;
    text-decoration: none;
    width: auto;
    svg {
      fill: white;
    }
  }
`;

export const BasketWrapper = styled.div`
  /* display: none;
  ${media.lg` 
    display: block;
  `} */
`;

export const BrandLogo = styled.img`
  display: none;
  position: absolute;
  max-width: 90px;
  left: calc(50% - 45px);
  ${media.lg` 
    display: block;
  `}
`;

export const Logo = styled.img`
  // max-width: 80px;
  max-height: 22px;
`;

export const Link = styled.a<{ visible: boolean }>`
  font-size: ${({ theme }) => theme.header.topBar.fontSize};
  font-weight: ${({ theme }) => theme.header.topBar.fontWeight};
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};

  ${media.lg` 
    width: auto;
  `}
  div {
    display: flex;
    justify-content: space-between;
    color: #fff;

    ${media.lg` 
      justify-content: flex-start;
    `}
  }

  &:hover {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
  }
`;
