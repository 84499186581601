import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import { basketQuantitySelector, startCartAction, basketAction } from '../store';
import { TSelectedSku } from '../typings';

export const useAddProductToCart = () => {
  const dispatch = useDispatch();
  const basketQuantity = useSelector(basketQuantitySelector);
  const isLookPage = useMatch('/look/:shortId') !== null;
  const isProductPage = useMatch('a/:vanityUrl/p/:shortId') !== null;
  const isLookFromArtist = useMatch('/a/:artistName/look/:shortId') !== null;

  const artistId = useSelector(
    // @ts-expect-error
    (state) => state?.artist?.item.publishing_id || state?.artist?.item.artist_id || '',
  );

  return useCallback(
    (lookShortId: string) => (variants: TSelectedSku | TSelectedSku[]) => {
      if (basketQuantity === 0) {
        dispatch(startCartAction({ lookShortId, variants }));
        dispatch(
          basketAction.setArtistId(isLookPage || isProductPage || isLookFromArtist ? artistId : ''),
        );
      }

      if (Array.isArray(variants)) {
        variants.forEach((variant) => {
          dispatch(
            basketAction.addToBasket({
              ...variant,
              bundleId: lookShortId,
              quantity: 1,
            }),
          );
        });
      } else {
        dispatch(
          basketAction.addToBasket({
            ...variants,
            bundleId: lookShortId,
            quantity: 1,
          }),
        );
      }
    },
    [basketQuantity, isLookFromArtist, isProductPage, isLookPage, artistId, dispatch],
  );
};
