import React, { FC, useState, useContext, useLayoutEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';

import { ReactComponent as CompactViewIcon } from '../../assets/svg/compact-view.svg';
import { ReactComponent as TargetViewIcon } from '../../assets/svg/large-view.svg';
import ArrowRigth from '../../assets/svg/arrow-right-topbar.svg';
import NoImage from '../../assets/images/no_image.png';
import * as Ui from './LooksList.styles';
import {
  EBrand,
  LookProductsPreview,
  ShareModal,
  TArtistLook,
  TLookShort,
  TLook,
  TRelatedContent,
  analyticsViewTypeSelected,
  // useImageLazyLoading,
} from '../..';
import { TSelectedSku } from '../../typings/types/Sku';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';
import { RelatedContent } from './RelatedContent/RelatedContent';

type TLookListProps = {
  looks: Array<TLookShort | TArtistLook>;
  relatedContent: TRelatedContent[];
  addProductToCart: (lookShortId: string) => (variant: TSelectedSku) => void;
  onShopNowClick: (look: TLookShort | TArtistLook | TLook) => void;
  fetchArtistLook: ({
    lookShortId,
    entityType,
  }: {
    lookShortId: string;
    entityType: 'looks' | 'favorites';
  }) => void;
};

type TLookListItemProps = {
  look: TLookShort | TArtistLook | TLook;
  compactView: boolean;
  fetchArtistLook?: ({
    lookShortId,
    entityType,
  }: {
    lookShortId: string;
    entityType: 'looks' | 'favorites';
  }) => void;
  addProductToCart: (lookShortId: string) => (variant: TSelectedSku) => void;
  onShopNowClick: (look: TLookShort | TArtistLook | TLook) => void;
};

export const LookListItem: FC<TLookListItemProps> = ({
  look,
  onShopNowClick,
  compactView,
  addProductToCart,
  fetchArtistLook,
}) => {
  const dispatch = useDispatch();
  // useImageLazyLoading(
  //   `look-image-${ (look as TArtistLook)?.short_id}`,
  //   true,
  // );

  const [lookToShare, setLookToShare] = useState<TLookShort | TArtistLook>();

  const lookImg = look?.pictures ? look?.pictures[0] : NoImage;
  const fetchLook = (element) => {
    const lookShortId = element.getAttribute('data-lookid');

    if (fetchArtistLook) {
      dispatch(fetchArtistLook({ lookShortId, entityType: 'looks' }));
    }
  };

  const lookObserver = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return;
        }
        fetchLook(entry.target);
        observer.unobserve(entry.target);
      });
    },
    {
      threshold: 0.1,
    },
  );

  useLayoutEffect(() => {
    if (fetchArtistLook) {
      const wrapper = document.querySelectorAll(`.look-${(look as TArtistLook)?.short_id}`);

      wrapper.forEach((item) => {
        lookObserver.observe(item);
      });

      return () => {
        const wrapperUnmount = document.querySelectorAll(
          `.look-${(look as TArtistLook)?.short_id}`,
        );

        wrapperUnmount.forEach((item) => {
          lookObserver.unobserve(item);
        });
      };
    }
  }, [fetchArtistLook]);

  return (
    <>
      <Ui.LookListItem
        key={(look as TArtistLook)?.short_id}
        compactView={compactView}
        className={`look-${(look as TArtistLook)?.short_id}`}
        data-lookId={(look as TArtistLook)?.short_id}
      >
        <Ui.ImageWrapper onClick={() => onShopNowClick(look)}>
          <Ui.Image
            className={`look-image-${(look as TArtistLook)?.short_id}`}
            width={300}
            height={300}
            src={lookImg}
          />
        </Ui.ImageWrapper>
        {!compactView && !!(look as TLookShort)?.products?.length && (
          <LookProductsPreview
            products={(look as TLookShort)?.products || []}
            onAddProductToCart={addProductToCart((look as TArtistLook)?.short_id)}
          />
        )}
        {!compactView && (
          <Ui.ShopNowBar className="shop-now-bar" onClick={() => onShopNowClick(look)}>
            <span>Shop now </span>
            <img src={ArrowRigth} alt="right arrow" style={{ marginLeft: '8px' }} />
          </Ui.ShopNowBar>
        )}
        {!compactView && (
          <Ui.ViewsBar>
            <Ui.ShareButton onClick={() => setLookToShare(look as TLookShort)}>
              Share
            </Ui.ShareButton>
          </Ui.ViewsBar>
        )}

        <Ui.ContentWrapper compactView={compactView}>
          <Ui.Name>{look.name}</Ui.Name>

          <Ui.Description>
            <HtmlTruncatedText sentencesCount={1} html={(look as TLookShort)?.message} />
          </Ui.Description>
        </Ui.ContentWrapper>
      </Ui.LookListItem>
      <ShareModal
        isOpen={!!lookToShare}
        look={lookToShare}
        onClose={() => {
          setLookToShare(undefined);
        }}
      />
    </>
  );
};

export const LooksList: FC<TLookListProps> = ({
  looks,
  relatedContent,
  onShopNowClick,
  addProductToCart,
  fetchArtistLook,
}) => {
  const { brand } = useContext(ThemeContext);

  const [compactView, setCompactView] = useState(brand !== EBrand.Aveda);

  return (
    <Ui.LookListWrapper>
      <Ui.FilterWrapper>
        <Ui.ChangeViewButton
          active={!compactView}
          onClick={() => {
            analyticsViewTypeSelected('list');
            setCompactView(false);
          }}
        >
          <TargetViewIcon />
        </Ui.ChangeViewButton>
        <Ui.ChangeViewButton
          active={compactView}
          onClick={() => {
            analyticsViewTypeSelected('grid');
            setCompactView(true);
          }}
        >
          <CompactViewIcon />
        </Ui.ChangeViewButton>
      </Ui.FilterWrapper>

      <Ui.LookList>
        {looks.map((look) => (
          <LookListItem
            // @ts-ignore
            key={look?.short_id}
            look={look}
            fetchArtistLook={fetchArtistLook}
            onShopNowClick={onShopNowClick}
            addProductToCart={addProductToCart}
            compactView={compactView}
          />
        ))}
        {relatedContent.map((content, idx) => (
          <Ui.LookListItem key={content.id} compactView={compactView}>
            <RelatedContent item={content} position={looks.length + idx + 1} />
          </Ui.LookListItem>
        ))}
      </Ui.LookList>
    </Ui.LookListWrapper>
  );
};
