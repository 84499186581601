import { useDispatch, useSelector } from 'react-redux';
import { takeWhile } from 'lodash';
import {
  basketAction,
  createBasketProductListSelector,
  basketSelector,
  TUseCheckoutFlowType,
  getEnvVariable,
} from '@elc/common';
import { artistSelector, promoCodeSelector } from '../store/selectors/artist.selectors';

const AWIN_URL = 'https://www.awin1.com/cread.php';

const URL =
  getEnvVariable('BUILD_TYPE') === 'production'
    ? 'https://www.maccosmetics.de/shared/add_to_bag.tmpl'
    : 'https://e.maccosmetics.emea2.de.stage.emea.elcdev.net/shared/add_to_bag.tmpl';

const getFallbackUrl = (products: Record<string, number>[]) => {
  const removeProtocol = (url: string): string =>
    url.startsWith('http://') ? url.replace('http://', '') : url.replace('https://', '');

  const removeQueryParams = (url: string): string =>
    takeWhile(url, (char) => char !== '?').join('');

  const prepareUrl = (url: string): string => removeProtocol(removeQueryParams(url));
  const welcomeParam = products.length ? '?welcome=true' : '';
  return `${prepareUrl(window.location.href)}${welcomeParam}`;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const removeEmpty = (obj: Record<string, any>): Record<string, any> =>
  Object.fromEntries(Object.entries(obj).filter((entry) => entry[1] !== ''));

const objectToQueryParams = (obj: Record<string, string>, withEncoding = false): string =>
  Object.entries(obj)
    .map(([key, value]) => {
      const shouldEncode = withEncoding && !['PRODUCT_CODE', 'products'].includes(key);
      return `${key}=${shouldEncode ? encodeURIComponent(value) : value}`;
    })
    .join('&');

const useCheckoutFlow = (): TUseCheckoutFlowType => {
  const dispatch = useDispatch();
  const products = useSelector(createBasketProductListSelector('product_code'));
  const artist = useSelector(artistSelector);
  const basket = useSelector(basketSelector);
  const promoCode = useSelector(promoCodeSelector);

  return (args = {}) => {
    let redirectUrl = '';
    const { path = URL, source = 'checkout' } = args;
    const endpoint = path.startsWith('/') ? `${URL}${path}` : path;
    const label = [artist.name, artist.second_name, artist.last_name]
      .filter((elem) => elem)
      .join(' ');
    const fallbackUrl = getFallbackUrl(products);
    const useAwin = artist.publishing_id && artist.publishing_id !== '000000';

    const queryParams: any = {
      label,
      redirect: fallbackUrl,
      socialshoppe: '1',
      promoCode,
    };

    if (products.length) {
      queryParams.PRODUCT_CODE = products
        .map((product) => `${Object.keys(product)[0]}:${Object.values(product)[0]}`)
        .join(',');
    }
    redirectUrl = `${endpoint}?${objectToQueryParams(removeEmpty(queryParams)).toString()}`;

    if (useAwin) {
      const avinQueryParams = {
        awinmid: '25656',
        awinaffid: artist.publishing_id,
        ued: redirectUrl,
      };

      redirectUrl = `${AWIN_URL}?${objectToQueryParams(
        removeEmpty(avinQueryParams),
        true,
      ).toString()}`;
    } else {
      redirectUrl = `${endpoint}?${objectToQueryParams(removeEmpty(queryParams), true).toString()}`;
    }

    dispatch(basketAction.checkout({ basket, source }));

    // eslint-disable-next-line
    console.log('🚀 ~ file: useCheckoutFlow.ts ~ line 87 ~ return ~ redirectUrl', redirectUrl);

    window.open(redirectUrl, '_blank');
  };
};

export default useCheckoutFlow;
