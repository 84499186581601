import styled from 'styled-components';

export const GRID_GAP = 22;
export const MOBILE_PADDING = 20;

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${MOBILE_PADDING}px;
`;

export const Row = styled.div`
  display: flex;
  gap: ${GRID_GAP}px;
`;
