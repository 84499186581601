import { DefaultTheme } from 'styled-components';
import { EBrand } from '../typings/enums/Brands';

export const macTheme: DefaultTheme = {
  brand: EBrand.Mac,
  colors: {
    black: '#120E02',
    white: '#fff',
    grey: '#d9d9d9',
  },
  borderRadius: '10px',
  fontFamily: {
    bold: 'AnoBold',
  },
  transitionTime: '0.5s',
  header: {
    topBar: {
      background: '#ffffff',
      size: {
        big: '130px',
        small: '80px',
      },
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '18px',
    },
    logos: {
      small: '56px',
      big: '110px',
    },
  },
  productCard: {
    wrapper: {
      fontSize: '16px',
    },
    colorName: {
      fontSize: '12px',
      lineHeight: '1.25',
      fontFamily: 'AnoBold',
    },
    name: {
      fontSize: '16px',
      lineHeight: '24px',
      textTransform: 'uppdercase',
      fontFamily: 'AnoBold',
    },
    description: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    price: {
      fontSize: '12px',
      lineHeight: '24px',
    },
    image: {
      background: ' #f9f9f9;',
      maxHeight: '140px',
    },
  },
  singleBundle: {
    description: {
      color: '#000000',
      opacity: 1,
      lineHeight: {
        small: '15px',
        big: '18px',
      },
      fontSize: {
        small: '12px',
        big: '16px',
      },
    },
    contentFooter: {
      color: '#000000',
      letterSpacing: '0.01em',
    },
    title: {
      color: '#000000',
    },
    image: {
      width: {
        big: '768px',
        medium: '647px',
        small: '350px',
      },
    },
  },
  button: {
    padding: '16px 20px',
    fontWeight: '400',
    lineHeight: '14px',
    fontSize: '12px',
    background: '#000',
    color: '#FFF',
    borderRadius: '45px',
    fontFamily: 'AnoBold',
    textTransform: 'uppercase',
  },

  artistProfileSection: {
    title: {
      textTransform: 'uppercase',
      fontSize: '20px',
    },
  },
  artistLookItem: {
    title: {
      textTransform: 'uppercase',
      fontSize: '14px',
      fontWeight: '700',
      fontFamily: 'AnoBold',
    },
    description: {
      color: '#000000',
    },
  },
  allProductBundle: {
    title: {
      textTransform: 'uppercase',
    },
  },

  learningCenter: {
    button: {
      textTransform: 'uppercase',
    },
    post: {
      title: {
        textTransform: 'uppercase',
      },
    },
  },
};
