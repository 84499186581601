import styled from 'styled-components';
import { media } from '../../utils/rwd';

const GAP_VALUE_SM = 12;
const GAP_VALUE_MD = 20;

export const FavoriteListWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${GAP_VALUE_SM}px;
  padding: 10px 0;

  ${media.sm`
    padding: 32px ${GAP_VALUE_SM}px;
  `}
`;

export const ProductCardWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  ${media.sm`
    width: calc(50% - ${GAP_VALUE_SM / 2}px);
  `}

  ${media.md`
    width: calc(33.3% - ${(2 * GAP_VALUE_MD) / 3}px);
  `}

  > div {
    min-width: unset;
  }
`;
