import React, { useState, useCallback, useRef, useEffect } from 'react';
import {
  Carousel as StyledCarousel,
  Dot,
  DotsWrapper,
  ThumbnailsWrapper,
  Thumbnail,
  ThumbnailsContainer,
  CarouselWrapper,
} from './Carousel.styles';
import * as NoImage from '../../assets/images/no_image.png';
import '@brainhubeu/react-carousel/lib/style.css';

type TCarouselProps = {
  images: string[];
  withThumbnails?: boolean;
};

const Carousel = ({ images = [], withThumbnails = true }: TCarouselProps) => {
  const [activeSlide, setActiveSlide] = useState<number>(0);

  const onChange = useCallback((value) => {
    setActiveSlide(value);
  }, []);
  const wrapper = useRef<HTMLDivElement>(null);

  const scrollIntoView = useCallback(
    (activeSlideIndex: number, behavior = 'smooth') => {
      const parent = wrapper.current;

      const element = wrapper?.current?.children[0].children[activeSlideIndex] as HTMLElement;

      const height = element?.offsetHeight;
      const top = element?.offsetTop;
      const parentTop = parent?.scrollTop || 0;
      const parentHeight = parent?.offsetHeight || 0;

      parent?.scrollTo({
        top,
        behavior,
      });

      if (top + height - parentTop > parentHeight) {
        parent?.scrollTo({
          top: top + height,
          behavior,
        });
      } else {
        parent?.scrollTo({
          top: top - height,
          behavior,
        });
      }
    },

    [wrapper.current],
  );

  useEffect(() => {
    scrollIntoView(activeSlide);
  }, [activeSlide]);

  const slides = images.length
    ? images.map((img) => <img alt="carousel-item" key="img" src={img} />)
    : [<img alt="carousel-item" key="img" src={NoImage as unknown as string} />];

  return (
    <CarouselWrapper>
      <StyledCarousel
        slides={slides}
        value={activeSlide}
        plugins={['fastSwipe']}
        onChange={onChange}
      />
      <DotsWrapper>
        {images.map((img, index) => (
          <Dot key={img} active={index === activeSlide} onClick={() => onChange(index)} />
        ))}
      </DotsWrapper>

      {withThumbnails && (
        //  eslint-disable-next-line
        <ThumbnailsWrapper ref={wrapper}>
          <ThumbnailsContainer>
            {images.map((src, index) => (
              <Thumbnail
                src={src}
                key={src}
                active={index === activeSlide}
                onClick={() => {
                  onChange(index);
                  // onBeforeFocus(e);
                }}
              />
            ))}
          </ThumbnailsContainer>
        </ThumbnailsWrapper>
      )}
    </CarouselWrapper>
  );
};

export { Carousel };
