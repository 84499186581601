import React, { useEffect } from 'react';

export const useVisibility = (
  ref: React.RefObject<HTMLElement>,
  rootRef: React.RefObject<HTMLElement>,
  setVisibility: (a: boolean) => void,
) => {
  const options = {
    root: rootRef.current,
    rootMargin: '0px',
    threshold: [0.9],
  };

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setVisibility(false);
      } else {
        setVisibility(true);
      }
    });
  };

  const observer = new IntersectionObserver(callback, options);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      observer.observe(element);
    }
    return () => {
      if (element) observer.unobserve(element);
    };
  }, [ref]);
};
