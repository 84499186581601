import { isEmpty } from 'lodash';
import { TEntry } from '../typings';
import { referrerInstance } from './ReferrerService';
import { api } from '../api';

export class EntriesService {
  readFromLocalStorege = (key: 'lastLocation' | 'lastArtist') => {
    const entry = JSON.parse(localStorage.getItem(key) || '{}');
    return isEmpty(entry) ? {} : { ...entry, time: new Date(entry?.time) };
  };

  getLastEntries = () => {
    const SALON = this.readFromLocalStorege('lastLocation');
    const PROFILE = this.readFromLocalStorege('lastArtist');
    return { SALON, PROFILE };
  };

  setLastEntry = (type: TEntry, uid: string) => {
    if (type === 'SALON') {
      localStorage.setItem('lastLocation', JSON.stringify({ uid, time: new Date() }));
    }
    if (type === 'PROFILE') {
      localStorage.setItem('lastArtist', JSON.stringify({ uid, time: new Date() }));
    }
  };

  increaseEntriesCounter = async (uid: string, type: TEntry) => {
    const referrer = referrerInstance.getReferrer();
    api.post('/v3/entry', {
      entry_type: type,
      entry_id: uid,
      ...(referrer ? { source: referrer } : {}),
    });
  };

  newEntry = async (uid: string, type: TEntry, source?: TEntry) => {
    const lastEntries = this.getLastEntries();

    if (type === 'LOOK') {
      this.increaseEntriesCounter(uid, type);
    }

    if (type === 'PROFILE') {
      if (
        lastEntries.PROFILE?.uid !== uid ||
        lastEntries.PROFILE?.time.getDate() !== new Date().getDate() || // if this is first visit on the new day
        source === 'SALON'
      ) {
        this.increaseEntriesCounter(uid, type);
      }
    }

    if (type === 'SALON') {
      if (
        lastEntries.SALON?.uid !== uid ||
        lastEntries.SALON?.time.getDate() !== new Date().getDate()
      ) {
        this.increaseEntriesCounter(uid, type);
      }
    }

    if (type === 'SINGLE_PRODUCT_PAGE') {
      this.increaseEntriesCounter(uid, type);
    }

    this.setLastEntry(type, uid);
  };
}
