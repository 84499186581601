import styled from 'styled-components/macro';
import { theme } from 'styled-tools';

const TabWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
`;

const TabItem = styled.button<{ active: boolean }>`
  margin: 0 12px 0 0;
  padding: 0;
  outline: 0;
  font-size: 14px;
  line-height: 1.25;
  white-space: nowrap;
  outline: 0 !important;
  position: relative;
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
`;

const TabItemWrapper = styled.div``;

const Color = styled.div<{ active: boolean; color: string }>`
  background-color: ${({ color }) => color};

  box-sizing: border-box;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  font-size: 0;
  margin-bottom: 5px;
  ${({ active }) =>
    active &&
    `
    &:after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: white;
      border-radius: 8px;
      left: 7px;
      top: 7px;
    }
  `};
`;

const ColorLabel = styled.div<{ color: string }>`
  text-transform: uppercase;
  display: flex;
  font-size: 12px;
  p {
    color: ${({ color }) => color};
  }

  > * {
    &:first-child {
      color: ${({ color }) => color};
      &:after {
        content: ' ';
        padding-right: 5px;
      }
    }
  }
`;

const Arrow = styled.button`
  background: ${theme('backgroundColor', '#ffffff')};
  border: none;
  position: absolute;
  z-index: 4;
  top: 0;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const RightArrow = styled(Arrow)`
  right: 0;
  &:after {
    content: '>';
  }
`;

const LeftArrow = styled(Arrow)`
  left: 0;
  &:after {
    content: '<';
  }
`;

export { TabItem, TabItemWrapper, Color, ColorLabel, RightArrow, LeftArrow, TabWrapper };
