import { flatMap } from 'lodash';
import { TCartItem, TLook, TProduct, TRelatedContent, TSelectedSku } from '../typings';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

const clearEcommerce = (): void => {
  window.dataLayer.push({ ecommerce: null });
};

export const analiticsVideo = (event, sourceType: string, id: string, start = true): void => {
  const isYoutube = sourceType === 'youtube';
  const videoDuration = isYoutube ? event.target.getDuration() : event.target.duration;
  const currentTime = isYoutube ? event.target.getCurrentTime() : event.target.currentTime;
  const analiticData = {
    event: 'eventload',
    eventCategory: 'artistPage',
    eventAction: start ? 'video_play' : 'video_pause',
    eventLabel: id,
    videoId: id,
    videoLength: videoDuration,
    videoMilestone: (currentTime / videoDuration) * 100,
    videoPlayhead: currentTime,
  };
  window.dataLayer.push(analiticData);
};

export const currencyCode = process.env.CURRENCY || 'USD';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const productImpressionEvent = (impressions: any) => ({
  event: 'eventload',
  eventCategory: 'ecommerce',
  eventAction: 'artistPage',
  eventLabel: 'productImpression',
  ecommerce: {
    currencyCode,
    impressions,
  },
});

const productParentId = (product: TProduct | TSelectedSku | TCartItem) => `PROD${product.base_id}`;

const singleBundleImpressions = (bundle: TLook) =>
  bundle.products.map((product: TProduct, i: number) => ({
    id: productParentId(product),
    list: `artistPage - ${bundle.name}`,
    position: i + 1,
  }));

export const analyticsProductImpression = (bundle: TLook): void => {
  const impressions = singleBundleImpressions(bundle);
  if (impressions.length === 0) {
    return;
  }
  clearEcommerce();
  window.dataLayer.push(productImpressionEvent(impressions));
};

export const analyticsExternalLinkClick = (linkName: string) => {
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'artistPage',
    eventAction: 'externaLinkClick',
    eventLabel: linkName,
  });
};

const getProductInfo = (variant: TSelectedSku) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const product: any = {
    id: productParentId(variant),
    dimension52: `SKU${variant.sku}`,
    dimension84: variant.product_code,
    // dimension85: variant.upc_code,
  };
  if (variant.size?.name) {
    product.dimension1 = variant.size?.name;
  }

  if (variant.color?.name) {
    product.dimension50 = variant.color?.name;
  }
  return product;
};

export const analyticsChangeVariant = (variant: TSelectedSku): void => {
  clearEcommerce();

  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'ecommerce',
    eventAction: 'spp',
    eventLabel: 'shade size switch',
    ecommerce: {
      detail: {
        products: [getProductInfo(variant)],
      },
    },
  });
};

export const analyticsProductDetails = (variant: TSelectedSku): void => {
  clearEcommerce();

  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'ecommerce',
    eventAction: 'artistPage',
    eventLabel: 'productDetail',
    ecommerce: {
      detail: {
        products: [getProductInfo(variant)],
      },
    },
  });
};

export const analyticsProductClick =
  (bundleName: string) =>
  (position: number) =>
  (variant: TSelectedSku): void => {
    clearEcommerce();
    window.dataLayer.push({
      event: 'eventload',
      eventCategory: 'ecommerce',
      eventAction: 'artistPage',
      eventLabel: 'productClick',
      ecommerce: {
        currencyCode,
        click: {
          actionField: { list: `artistPage - ${bundleName}` },
          products: [{ id: productParentId(variant), position: position + 1 }],
        },
      },
    });
  };

export const analyticsSetPageTypeCategory = (): void => {
  window.dataLayer.push({
    pageType: 'category',
  });
};

export const analyticsSetPageDataArtist = (pageName: string): void => {
  window.dataLayer.push({
    pageTemplate: 'artist',
    pageType: 'category',
    pageName,
  });
};

export const analyticsSetPageDataLook = (pageName: string): void => {
  window.dataLayer.push({
    pageTemplate: 'look',
    pageType: 'category',
    pageName,
  });
};

export const analyticsSetPageDataSalon = (pageName: string): void => {
  window.dataLayer.push({
    pageTemplate: 'salon',
    pageType: 'category',
    pageName,
  });
};

export const analyticsProductImpressionMulti = (bundles: TLook[]): void => {
  const impressions = flatMap(bundles, singleBundleImpressions);
  if (impressions.length === 0) {
    return;
  }
  clearEcommerce();
  window.dataLayer.push(productImpressionEvent(impressions));
};

export const analyticsAddToBag = (variant: TCartItem): void => {
  clearEcommerce();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const product: any = {
    id: productParentId(variant),
    dimension52: `SKU${variant.sku}`,
    dimension55: 'no', // autoReplenishExist
    dimension56: 0, // autoReplenishmentTimeScale
    dimension84: variant.product_code, // "{{product code}}",
    // dimension85: variant.upc_code,
    price: variant.retail_price,
    variant: variant.color?.name || variant.size?.name, // "{{productShade} }",
    quantity: 1,
  };
  if (variant.size?.name) {
    product.dimension1 = variant.size?.name;
  }

  if (variant.color?.name) {
    product.dimension50 = variant.color?.name;
  }
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'ecommerce',
    eventAction: 'addToBag',
    eventLabel: 'single',
    ecommerce: {
      currencyCode,
      add: {
        products: [product],
      },
    },
  });
};

export const analyticsTabSelected = (tabName) => {
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'artist page view types',
    eventAction: 'tabs',
    eventLabel: tabName,
  });
};

export const analyticsViewTypeSelected = (viewType: 'grid' | 'list') => {
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'artist page view types',
    eventAction: 'grid or list',
    eventLabel: viewType,
  });
};

export const analyticsLocationArtistSelected = (link: string) => {
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'salonPage',
    eventAction: 'selectArtist',
    eventLabel: link,
  });
};

export const analyticsShareButtonClick = (eventLabel: 'open' | 'close') => {
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'share button',
    eventAction: 'overlay',
    eventLabel,
  });
};

const getPromoDetails = (promo: TRelatedContent, position: number) => ({
  id: `module-${position}/${promo.resource_type}-${promo.name}`,
  name: promo.name,
  creative: promo.resource_type,
  position: 'module',
});

export const analyticsPromoView = (promos: [TRelatedContent, number][]) => {
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce
  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'ecommerce',
    eventAction: 'promotion',
    eventLabel: 'impression',
    eventNonInteraction: 'true',
    ecommerce: {
      promoView: {
        promotions: promos
          .filter(([promo]) => promo.resource_type === 'image' || promo.resource_type === 'banner')
          .map(([promo, position]) => getPromoDetails(promo, position)),
      },
    },
  });
};

export const analyticsPromoClick = (promo: TRelatedContent, position: number) => {
  if (promo.resource_type === 'video' || promo.resource_type === 'youtube') return;

  window.dataLayer.push({
    event: 'eventload',
    eventCategory: 'ecommerce',
    eventAction: 'promotionClick',
    eventLabel: 'click',
    ecommerce: {
      promoClick: {
        promotions: [getPromoDetails(promo, position)],
      },
    },
  });
};
