const languages = [
  {
    name: 'English',
    code: 'en',
  },
  {
    name: 'Deutsch',
    code: 'de',
  },
];

export default languages;
