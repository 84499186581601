import { createAsyncThunk } from '@reduxjs/toolkit';
import { saveCart } from '../../api';
import { TCartItem, TCheckoutSource } from '../../typings';

export const checkout = createAsyncThunk(
  'basket/checkout',
  async ({ basket, source }: { basket: Array<TCartItem>; source: TCheckoutSource }) => {
    try {
      const mapBasketItems = (item: TCartItem) => ({
        baseCurrency: item.currency,
        quantity: item.quantity,
        price: item.retail_price,
        bundle_short_id: item.bundleId.toUpperCase(),
        product_code: item.product_code,
      });

      await saveCart(basket.map(mapBasketItems), source);
      return null;
    } catch (error) {
      return Promise.reject(error);
    }
  },
);
