import { createSelector } from '@reduxjs/toolkit';
import { MainState } from '../types';
import { SALON_GROUP_REDUCER_KEY } from './salonGroup.constants';

const selectSelf = (state: MainState) => state[SALON_GROUP_REDUCER_KEY];

export const salonGroupSelector = createSelector(selectSelf, (state) => state.items);
export const isSalonGroupLoadingSelector = createSelector(
  selectSelf,
  (state) => state.loading === 'pending',
);
export const salonGroupRequestStateSelector = createSelector(selectSelf, (state) => state.status);
