import { TDataLayer } from '@elc/common';

const { pathname } = window.location;

const [, artist] = pathname.match(/\/a\/([\w\d]+)/) || [];
const [, look] = pathname.match(/\/look\/([\w\d]+)/) || [];
const categoryPage = artist || look;

let pageTemplate = 'home';
const pageType = categoryPage ? 'category' : 'home';
const pageName = categoryPage || 'home';

if (artist) {
  pageTemplate = 'artist';
}

if (look) {
  pageTemplate = 'look';
}

const dataLayer: TDataLayer = [
  {
    country: process.env.COUNTRY || 'DE',
    language: process.env.LANGUAGE || 'de',
    currencyCode: process.env.CURRENCY || 'EUR',
    brand: 'MAC',
    brandId: `MAC ${process.env.REGION || 'DE'}`,
    brandLocale: process.env.REGION || 'DE',
    environment: process.env.NODE_ENV || 'development',
    pageTemplate,
    pageType,
    pageName,
  },
];

export { dataLayer };
