import styled from 'styled-components';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 20px 0px;
  background: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${media.md`
  margin: 0px;
  width: 75%;
`}

  ${media.xl`
  margin: 0px;
  width: 50%;
`}
`;

export const Title = styled.h2`
  text-align: center;
  font-family: HaptikBold;
  font-weight: bold;
  text-transform: uppercase;

  ${media.md`
  font-size: 20px;
`}
`;

export const Text = styled.div`
  font-family: HaptikRegular;
  text-align: center;

  ${media.md`
  font-size: 20px;
`}
`;
