import { useLayoutEffect } from 'react';
import NoImage from '../assets/images/no_image.png';

export const useImageLazyLoading = (imageClass = '', shouldRun = false) => {
  const preloadImage = (img) => {
    const src = img.getAttribute('data-src');

    if (!src) {
      return;
    }
    img.onerror = () => {
      img.src = NoImage;
    };
    img.src = src;
  };

  const imgOptions = {
    threshold: 0.1,
  };

  const imgObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        return;
      }
      preloadImage(entry.target);
      observer.unobserve(entry.target);
    });
  }, imgOptions);

  useLayoutEffect(() => {
    if (shouldRun) {
      const images = document.querySelectorAll(`.${imageClass}[data-src]`);

      images.forEach((image) => {
        imgObserver.observe(image);
      });
    }

    return () => {
      const images = document.querySelectorAll(`.${imageClass}[data-src]`);

      images.forEach((image) => {
        imgObserver.unobserve(image);
      });
    };
  }, [shouldRun]);
};
