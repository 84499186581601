/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { EInventory } from '../..';
import { TColorWithSkuUid } from '../../typings/types/Color';
import { moveItemToFront } from '../../utils/moveItemToFront';

import * as Ui from './ColorPicker.styles';

type ColorPickerProps = {
  /**
   * Color click handler
   */
  onClick: (color: TColorWithSkuUid) => void;
  /**
   * Colors
   */
  colors: Array<TColorWithSkuUid>;
  /**
   * Name of color which is active by default
   */
  defaultActive: string;
  /**
   * Show the name of currently selected color
   */
  showActiveColor?: boolean;

  /**
   * Show x color variants
   */
  visibleColors?: number;
};

export const ColorPicker = ({
  onClick,
  colors,
  defaultActive,
  showActiveColor = true,
  visibleColors = 7,
}: ColorPickerProps) => {
  const childrenWrapper = useRef<HTMLDivElement>(null);
  const [movedFromLeft, setMovedFromLeft] = useState(0);
  const [activeColorIndex, setActiveColorIndex] = useState(0);

  const onArrowClick = (arrowType: 'left' | 'right') => {
    const moveAbout = arrowType === 'left' ? -30 : 30;
    setMovedFromLeft(movedFromLeft + (arrowType === 'left' ? -1 : 1));
    childrenWrapper.current?.scrollTo({
      left: childrenWrapper.current.scrollLeft + moveAbout,
      behavior: 'smooth',
    });
  };

  const handleColorClick = (index, color) => {
    setActiveColorIndex(index);
    onClick(color);
  };
  const sortedColors = useMemo(
    () => colors.sort(moveItemToFront<TColorWithSkuUid>((x) => x.skuUid === defaultActive)),
    [colors],
  );

  useEffect(() => {
    setMovedFromLeft(0);
  }, [colors.length]);

  useEffect(() => {
    const index = sortedColors.findIndex((color) => color.skuUid === defaultActive);

    setActiveColorIndex(index);
  }, [defaultActive, sortedColors, setActiveColorIndex]);

  const isRightArrowVisible =
    colors.length > visibleColors && movedFromLeft !== colors.length - visibleColors;

  return (
    <Ui.Wrapper>
      <Ui.ColorsWrapper visibleColors={visibleColors}>
        <Ui.ArrowButton
          direction="left"
          onClick={() => onArrowClick('left')}
          visible={movedFromLeft !== 0}
        >
          <Ui.Arrow direction="left" />
        </Ui.ArrowButton>

        <div ref={childrenWrapper}>
          {sortedColors.map((color, index) => (
            <button
              aria-label="Color variant"
              onClick={() => handleColorClick(index, color)}
              className={`circle ${
                activeColorIndex === index &&
                color.inventory.toUpperCase() === EInventory.ACTIVE &&
                'active'
              }`}
              key={color?.name}
              style={{ background: color?.hex_value }}
              data-index={index}
              type="button"
            >
              {color.inventory.toUpperCase() !== EInventory.ACTIVE && <Ui.CloseIcon />}
            </button>
          ))}
        </div>
        <Ui.ArrowButton
          direction="right"
          visible={isRightArrowVisible}
          onClick={() => onArrowClick('right')}
        >
          <Ui.Arrow direction="right" />
        </Ui.ArrowButton>
      </Ui.ColorsWrapper>
      {showActiveColor && (
        <Ui.ColorName color={sortedColors[activeColorIndex]?.hex_value}>
          {sortedColors[activeColorIndex]?.name}
        </Ui.ColorName>
      )}
    </Ui.Wrapper>
  );
};
