import React, { FC, useEffect } from 'react';
import getOrFp from 'lodash/fp/getOr';
import {
  CenteredCard,
  StatusWrapper,
  useQueryParams,
  LookNavigationHeader,
  LookProductsList,
  analyticsProductClick,
  analyticsProductImpression,
  analyticsSetPageDataLook,
  analyticsSetPageTypeCategory,
  useAddProductToCart,
} from '@elc/common';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { fetchBundle } from '../../store/actions/bundle.actions';
import { bundleAction } from '../../store/slices/bundle.slice';

import { GlobalState, useGlobalState, useThunkDispatch } from '../../store';
import { Metatags } from '../../components/Metatags/Metatags';

import { increaseEntriesCounter } from '../../utils/increaseEntriesCounter';
import * as Ui from './Look.styles';
import { BasketIcon } from '../../components/basket/basket';
import { useGetLookOwner } from '../../hooks/useGetLookOwner';

type LookRouteParams = { shortId: string; artistName?: string };

const LookScreen: FC = () => {
  const { shortId = '', artistName } = useParams<LookRouteParams>();
  const { token = '', owner_token: ownerPageToken, preview, feedback } = useQueryParams();
  const { t } = useTranslation(['app']);
  const dispatch = useThunkDispatch();
  const { status, item: look } = useGlobalState(getOrFp({} as GlobalState['bundle'], 'bundle'));
  const { owner: artist } = look;

  const addProductToCart = useAddProductToCart();

  useEffect(() => {
    dispatch(fetchBundle({ shortId, token: `${token}` }));
    analyticsSetPageDataLook(shortId);

    return () => {
      dispatch(bundleAction.resetBundle());
    };
  }, []);

  useGetLookOwner(look.owner.vanity_url, ownerPageToken);

  useEffect(() => {
    if (!preview && look._id) {
      // eslint-disable-next-line no-underscore-dangle
      increaseEntriesCounter(look._id, 'LOOK');
    }
  }, [look._id]);

  const shouldAddToken = ownerPageToken || (token && artist.profile_page_token);
  let artistPageUrl = `/a/${artist.vanity_url}${
    shouldAddToken ? `?token=${ownerPageToken || artist.profile_page_token}` : ''
  }`;
  if (feedback) {
    const feedSeparator = artistPageUrl.indexOf('?') !== -1 ? '&' : '?';
    artistPageUrl = `${artistPageUrl}${feedSeparator}feedback=true`;
  }
  if (preview) {
    const separator = artistPageUrl.indexOf('?') !== -1 ? '&' : '?';
    artistPageUrl = `${artistPageUrl}${separator}preview=true`;
  }

  useEffect(() => {
    if (!look.products.length) {
      return;
    }
    analyticsProductImpression(look);
  }, [look.products]);

  const lookBelongsToArist =
    artistName && artist.vanity_url
      ? artist.vanity_url === artistName || artist.full_name === artistName
      : true;

  return (
    <StatusWrapper
      status={lookBelongsToArist ? status : 404}
      componentsForStatus={{
        403: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenNeeded')}
          />
        ),
        400: (
          <CenteredCard
            title={t('app:errors.accessDenied')}
            content={t('app:errors.tokenExpired')}
          />
        ),
      }}
      defaultNotFound={
        <CenteredCard title={t('app:errors.notFound')} content={t('app:errors.lookNotFound')} />
      }
    >
      <div style={{ background: '#fff' }}>
        <Metatags
          type="bundle"
          model={look}
          title={`${look.name} | ${artist.full_name}`}
          description={look.message}
          image={look.pictures[0]}
        />
        <Ui.LookHeaderWrapper>
          <LookNavigationHeader
            imageUrl={artist.picture}
            linkText={artist.full_name}
            linkHref={artistPageUrl as string}
            ready
          >
            <BasketIcon colorRevers />
          </LookNavigationHeader>
        </Ui.LookHeaderWrapper>
        <LookProductsList
          onModalClose={analyticsSetPageTypeCategory}
          onProductDetailsClick={analyticsProductClick}
          onAddToCart={addProductToCart(look.short_id)}
          look={look}
        />
      </div>
    </StatusWrapper>
  );
};

export default LookScreen;
