import styled from 'styled-components';
import CarouselComponent from '@brainhubeu/react-carousel';
import { media } from '../../utils/rwd';

const CarouselWrapper = styled.div`
  width: 100%;
  max-width: 943px;
  position: relative;
`;

const Carousel = styled(CarouselComponent)`
  position: relative;
  height: 100%;

  .BrainhubCarouselItem {
    position: relative;

    ${media.sm.down`
      width: 100%;
    `}

    img {
      max-height: 400px;
      object-fit: contain;

      ${media.sm.down`
        width: 100%
    `}
    }
  }
`;

const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0;
`;

const Dot = styled.div<{ active: boolean }>`
  outline: 0;
  padding: 10px;
  border: none;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none;
  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    padding: 0;
    border: none;
    background: #000;
  }
`;

const ThumbnailsWrapper = styled.div`
  position: absolute;
  ${media.sm.down`
  top: 5px;
  left: 5px;
`}
  top: 25px;
  left: 25px;
  max-height: 400px;
  ${media.sm.down`
  max-height: 300px;

`}
  overflow: hidden;
`;

const ThumbnailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  overflow: auto;
  overflow-x: hidden;
`;

const Thumbnail = styled.img<{ active: boolean }>`
  width: 50px;
  height: 50px;
  margin-bottom: 16px;
  opacity: ${(props) => (props.active ? 1 : 0.5)};
  &:hover {
    transform: scale(1.1);
    opacity: 1;
  }
  object-fit: contain;
`;

export {
  CarouselWrapper,
  Carousel,
  DotsWrapper,
  Dot,
  ThumbnailsWrapper,
  Thumbnail,
  ThumbnailsContainer,
};
