import React, { ChangeEvent, useRef, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { GoCheck } from 'react-icons/go';
import { IoCloseSharp } from 'react-icons/io5';
import { BiMessageAdd, BiInfoCircle } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { toastr } from 'react-redux-toastr';
import { approve, postFeedback, reject } from '../../api/requests';
import {
  FeedbackWrapper,
  PopupContent,
  PopupHeader,
  RightCorner,
  StyledPopup,
  Text,
  TextArea,
  ButtonWrapper,
  Disclaimer,
  ApproveButton,
  RejectButton,
} from './Feedback.styles';

export const Feedback = ({ uid, token }: { uid: string; token: string }): JSX.Element => {
  const { t } = useTranslation(['components']);

  const [open, setOpen] = useState(false);

  const feedback = useRef('');

  const onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    feedback.current = event.target.value;
  };

  const closeModal = () => setOpen(false);

  const judgeUser = (isPositive: boolean) => {
    const judgeMethod = isPositive ? approve : reject;

    judgeMethod(uid, token)
      .then((axiosResponse) => axiosResponse)
      .then((response) => {
        if (response.status === 200) {
          toastr.success(t('feedback.success'), t('feedback.feedbackAdded'));
        } else {
          toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
        }
        closeModal();
      })
      .catch(() => {
        toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
        closeModal();
      });
  };

  const sendFeedback = (isPositive) => {
    postFeedback(uid, feedback.current, token)
      .then((axiosResponse) => axiosResponse)
      .then((response) => {
        if (response.status === 200) {
          judgeUser(isPositive);
          feedback.current = '';
        } else {
          toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
          closeModal();
        }
      })
      // eslint-disable-next-line
      .catch((_) => {
        toastr.error(t('feedback.error'), t('feedback.somethingWentWrong'));
        closeModal();
      });
  };

  return (
    <>
      <FeedbackWrapper onClick={() => setOpen((o) => !o)}>
        <BiMessageAdd fill="black" style={{ marginRight: '16px' }} />
        {t('feedback.enterFeedback')}
      </FeedbackWrapper>
      <StyledPopup open={open} closeOnDocumentClick onClose={closeModal}>
        <>
          <RightCorner />
          <PopupHeader>
            <h4>{t('feedback.add')}</h4>
            <MdClose
              fill="white"
              size={30}
              style={{ cursor: 'pointer' }}
              onClick={() => closeModal()}
            />
          </PopupHeader>
          <PopupContent>
            <Text>{t('feedback.addComment')}</Text>
            <TextArea placeholder={t('feedback.placeholder')} onChange={onCommentChange} rows={8}>
              {feedback.current}
            </TextArea>
            <ButtonWrapper>
              <ApproveButton onClick={() => sendFeedback(true)}>
                <span>
                  <GoCheck fill="white" size={24} />
                </span>
                {t('feedback.approve')}
              </ApproveButton>
              <RejectButton onClick={() => sendFeedback(false)}>
                <span>
                  <IoCloseSharp fill="white" size={24} />
                </span>
                {t('feedback.reject')}
              </RejectButton>
            </ButtonWrapper>
            <Disclaimer>
              <span>
                <BiInfoCircle size={18} />
              </span>
              <p>{t('feedback.disclaimer')}</p>
            </Disclaimer>
          </PopupContent>
        </>
      </StyledPopup>
    </>
  );
};
