import { Link } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';
import { media } from '../../utils/rwd';

export const ShowMore = styled(Link)<{ theme: DefaultTheme }>`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.14286;
  display: inline-block;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  align-self: center;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: initial;
  }

  ${media.sm.down`
    align-self: end;
  `}
`;

export const StyledDiv = styled.div`
  line-height: 15px;
  font-size: 12px;
  color: #3d3f42;
  text-decoration: none;
  background: none;
  padding: 0;
  padding-left: 4px;
  margin-top: 5px;
  border-radius: 0px;
  cursor: pointer;

  &:hover {
    color: #3d3f42;
  }
`;

export const StyledShowMore = styled(ShowMore)`
  margin-top: 10px;
  display: inline-block;
`;

// override inline style of any child
export const InlineStylesOverridingDiv = styled.div`
  *[style] {
    color: ${({ theme }) => theme?.singleBundle?.description?.color || '#ffffff'} !important;
    opacity: ${({ theme }) => theme?.singleBundle.description?.opacity || '1.0'} !important;
    line-height: ${({ theme }) =>
      theme?.singleBundle.description?.lineHeight.small || '15px'} !important;
    font-size: ${({ theme }) =>
      theme?.singleBundle.description?.fontSize.small || '12px'} !important;
  }

  ${media.md`
    *[style] {
      line-height: ${({ theme }) =>
        theme?.singleBundle.description?.lineHeight.big || '18px'} !important;
      font-size: ${({ theme }) =>
        theme?.singleBundle.description?.fontSize.big || '16px'} !important;
    }
  `}
`;
