import React from 'react';
import { Link } from 'react-router-dom';

import * as Ui from './LearningCenter.styles';
import { HtmlTruncatedText } from '../TruncateText/HtmlTruncatedText';
import { TLearningCenter } from '../../typings';

type LearingCenterProps = {
  center: TLearningCenter;
};
export const LearningCenter = ({ center }: LearingCenterProps) => (
  <Ui.Wrapper>
    <Ui.PrimarySection>{center.primary_section}</Ui.PrimarySection>
    <Ui.PrimarySubtitle>{center.primary_subtitle}</Ui.PrimarySubtitle>

    <Ui.PostsGrid>
      {center.posts.map((post) => (
        <Ui.PostCard key={post.uid}>
          <Link to={`/how-to-hub/posts/${post.uid}`}>
            <Ui.PostImage>
              <img src={post.cover_image} alt="post_image" />
            </Ui.PostImage>
          </Link>
          <Ui.PostColumn>
            <Ui.PostTitle>{post.title}</Ui.PostTitle>
            <Ui.PostDescription>
              <HtmlTruncatedText html={post.body} pageUrl={`/how-to-hub/posts/${post.uid}`} />
            </Ui.PostDescription>
          </Ui.PostColumn>
        </Ui.PostCard>
      ))}
    </Ui.PostsGrid>
    {center.secondary_section && (
      <Ui.SecondarySection>{center.secondary_section}</Ui.SecondarySection>
    )}
    {center.secondary_subtitle && (
      <Ui.SecondaryTitle>{center.secondary_subtitle}</Ui.SecondaryTitle>
    )}
    {center.button_link && center.button && (
      <Ui.ButtonLink href={center.button_link}>{center.button}</Ui.ButtonLink>
    )}
  </Ui.Wrapper>
);
