import { useEffect } from 'react';
import { useThunkDispatch } from '../store';
import { fetchArtist, fetchPrivateArtistProfile } from '../store/actions/artist.actions';

export const useGetLookOwner = (ownerVanity: string, ownerPageToken?: string) => {
  const dispatch = useThunkDispatch();

  useEffect(() => {
    if (!ownerVanity) return;

    if (ownerPageToken) {
      dispatch(
        fetchPrivateArtistProfile({
          vanity_url: ownerVanity,
          token: `${ownerPageToken}`,
        }),
      );
    } else {
      dispatch(fetchArtist(ownerVanity));
    }
  }, [ownerVanity]);
};
