import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import * as Ui from './WelcomePopup.styles';

export const WelcomePopup = () => {
  const { t } = useTranslation(['components']);
  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('welcome')) {
      setOpen(true);
    }
  }, [searchParams]);

  const handleModalClose = () => {
    setOpen(false);
    searchParams.delete('welcome');
    setSearchParams(searchParams);
  };

  return (
    <Ui.StyledPopup open={open} closeOnDocumentClick onClose={() => setOpen(false)}>
      <Ui.Header>{t('welcomeBack')}</Ui.Header>
      <Ui.Content>{t('welcomeBackText')}</Ui.Content>
      <Ui.Button onClick={handleModalClose}>{t('close')}</Ui.Button>
    </Ui.StyledPopup>
  );
};
