import styled from 'styled-components';
import { transparentButton } from '../../utils/styled-components/mixins';
import Container from '../Container/Container';
import { media } from '../../utils/rwd';

export const SalonTopbarWrapper = styled.div`
  background: #000;
  position: sticky;
  top: 0;
  z-index: 102;
  width: 100%;
`;

export const SalonTopbarContainer = styled.div<{ $fullWidth: boolean }>`
  ${media.lg`
    max-width:  ${({ $fullWidth }) => ($fullWidth ? 'unset' : '1440px')};
    padding: ${({ $fullWidth }) => ($fullWidth ? '0' : '0 80px')};
    box-sizing: border-box;
    margin: 0 auto;
  `}
`;

export const SalonTopbar = styled(Container)`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.lg`
    display:flex;


  `}
`;

export const LogosWrapper = styled.div<{ $fullWidth: boolean }>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'unset')};
  margin-bottom: ${({ $fullWidth }) => ($fullWidth ? '10px' : 'unset')};
  display: ${({ $fullWidth }) => ($fullWidth ? 'grid' : 'flex')};
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;
`;

export const LinksWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};

  flex: 1;
  justify-content: center;
  gap: 12px;
`;

export const BasketWrapper = styled.div`
  justify-self: end;
`;

export const BrandLogo = styled.img<{ $fullWidth: boolean }>`
  height: ${({ $fullWidth }) => ($fullWidth ? '55px;' : 'unset')}
  margin-right: 5px;
  cursor: pointer;
  ${media.sm`
    margin-right: 10px;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: flex-end;
  ${media.sm`
    gap: 33px;
  `}
`;

export const BurgerMenu = styled.button`
  ${transparentButton}
`;

export const Logo = styled.img<{ $fullWidth: boolean }>`
  height: ${({ $fullWidth }) => ($fullWidth ? '55px;' : '30px')};
  margin-right: 10px;
  justify-self: start;

  ${media.sm`
    margin-right: 20px;
  `}
`;

export const Link = styled.button`
  ${transparentButton}
  color: white;
  text-transform: uppercase;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 16px;
`;
