import { EInventory, TSelectedSku } from '../typings';

export const getSkuInventoryBadgeText = (sku: TSelectedSku, t) => {
  switch (sku.inventory.toUpperCase()) {
    case EInventory.OUT_OF_STOCK:
      return t('inventory.outOfStock');
    case EInventory.COMING_SOON:
      return t('inventory.comingSoon');
    case EInventory.SOLD_OUT:
      return t('inventory.soldOut');
    default:
      return '';
  }
};
