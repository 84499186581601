import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTerms } from '@elc/common';

export const fetchTerms = createAsyncThunk('terms/fetchTerms', async () => {
  try {
    const { data: response } = await getTerms();

    if (response) {
      return response;
    }
    throw Error();
  } catch (error) {
    return Promise.reject(error);
  }
});
