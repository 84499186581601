import { createAsyncThunk } from '@reduxjs/toolkit';
import { createStartedCartRequest } from '../../api';
import { TSelectedSku } from '../../typings';

export const startCartAction = createAsyncThunk(
  'startedCart/startCart',
  async ({
    lookShortId,
    variants,
  }: {
    lookShortId: string;
    variants: TSelectedSku | TSelectedSku[];
  }) => {
    const productsUids = Array.isArray(variants)
      ? variants.map((v) => v.parent_product)
      : [variants.parent_product];

    const { data: response } = await createStartedCartRequest({
      store_short_id: lookShortId,
      products_uids: productsUids,
    });

    if (response) return response;

    throw Error();
  },
);
