import React, { FC } from 'react';
import { TColorWithSkuUid } from '../..';

import * as Ui from './ColorSearch.styles';

type TColorSearchProps = {
  colors: Array<TColorWithSkuUid>;
  selectedDefault: (option) => boolean;
  onClick: (color: TColorWithSkuUid) => void;
};

const ColorSearchInput = ({
  color,
  active = false,
}: {
  color: TColorWithSkuUid;
  active?: boolean;
}) => (
  <Ui.Select>
    <Ui.Color color={color?.hex_value} />

    <Ui.Value color={color?.hex_value}>{color?.name}</Ui.Value>
    <Ui.Arrow active={active}>
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.64784 1.46745L2.11528 8L8.64784 14.5326L8.13877 15.0416L1.09718 8.00003L8.13877 0.95843L8.64784 1.46745Z"
          fill="black"
          stroke="black"
          strokeWidth="0.5"
        />
      </svg>
    </Ui.Arrow>
  </Ui.Select>
);

const ColorSearchDropdownItem = ({
  color,
  onClick,
}: {
  color: TColorWithSkuUid;
  onClick: () => void;
}) => (
  <Ui.Item onClick={onClick}>
    <Ui.ItemColor color={color.hex_value} />
    <Ui.ItemValue>{color.name}</Ui.ItemValue>
  </Ui.Item>
);

const ColorSearch: FC<TColorSearchProps> = ({ colors, onClick, selectedDefault }) => (
  <Ui.Dropdown
    options={colors}
    selectedDefault={selectedDefault}
    renderTrigger={(selectedColor, open) => (
      <ColorSearchInput active={open} color={selectedColor} />
    )}
    renderOption={(option) => (
      <ColorSearchDropdownItem color={option} onClick={() => onClick(option)} />
    )}
  />
);

export { ColorSearch };
