import React, { ReactNode } from 'react';
import { analyticsExternalLinkClick } from '../../utils/googleAnalitics';

const handleClick = (e, href, target, rel) => {
  e.preventDefault();
  analyticsExternalLinkClick(href);
  if (target) {
    return window.open(href, target, rel);
  }
  window.location.href = href;
};

export const ExternalLink = ({
  href,
  children,
  target,
  rel,
  ...rest
}: {
  href: string;
  children: ReactNode;
  target?: string;
  rel?: string;
}) => (
  <a href={href} onClick={(e) => handleClick(e, href, target, rel)} {...rest}>
    {children}
  </a>
);
