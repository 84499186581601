import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFeaturedArtistsRequest } from '../../api/requests';
import { GET_FEATURED_ARTIST } from './featuredArtists.constants';

export const fetchFeaturedArtistAction = createAsyncThunk(GET_FEATURED_ARTIST, async () => {
  try {
    const { data: response } = await getFeaturedArtistsRequest();

    if (response) {
      return response;
    }
    throw Error();
  } catch (error) {
    throw Error();
  }
});
