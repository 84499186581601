import React from 'react';
import {
  featuredAritstsReducer,
  FEATURED_ARTISTS_REDUCER_KEY,
  modalReducer,
  basketReducer,
  BASKET_REDUCER_KEY,
} from '@elc/common';
import {
  AnyAction,
  configureStore,
  EnhancedStore,
  ThunkAction,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { Provider, TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import bundleSlice from './slices/bundle.slice';
import artistSlice from './slices/artist.slice';
import learningCenterSlice from './slices/learningCenter.slice';
import termsSlice from './slices/terms.slice';

const reducer = {
  artist: artistSlice.reducer,
  bundle: bundleSlice.reducer,
  learningCenter: learningCenterSlice.reducer,
  terms: termsSlice.reducer,
  toastr: toastrReducer,
  modal: modalReducer,
  [FEATURED_ARTISTS_REDUCER_KEY]: featuredAritstsReducer,
  [BASKET_REDUCER_KEY]: basketReducer,
};

export const store = configureStore({
  reducer,
});

export type GlobalState = ReturnType<typeof store.getState>;

export function createStore(): EnhancedStore {
  return configureStore({ reducer });
}

export type ThunkResult<R> = ThunkAction<R, GlobalState, undefined, AnyAction>;

export type ReduxDispatch = ThunkDispatch<GlobalState, unknown, AnyAction>;

export const useGlobalState: TypedUseSelectorHook<GlobalState> = useSelector;

export const useThunkDispatch = (): ReduxDispatch => useDispatch<ReduxDispatch>();

export const GlobalStateProvider: React.FC = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);
