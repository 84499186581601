import { useRef, useEffect } from 'react';

export const useEffectInitialized = (fallback: () => void, dependecies: unknown[] = []) => {
  const isInitialized = useRef(false);

  useEffect(() => {
    if (isInitialized.current) {
      fallback();
    } else {
      isInitialized.current = true;
    }
  }, [...dependecies]);
};
