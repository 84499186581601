import styled, { DefaultTheme } from 'styled-components';

export const Button = styled.button.attrs(({ className = '' }) => ({
  className,
}))<{
  theme?: DefaultTheme;
  disabled: boolean;
  padding?: string;
  fontWeight?: string;
  background?: string;
  color?: string;
  fontSize?: string;
  uppercase?: boolean;
}>`
  padding: ${({ theme, padding }) => padding || theme.button.padding};
  width: auto;
  white-space: nowrap;
  background: ${({ theme, background }) => background || theme.button.background};
  color: ${({ theme, color }) => color || theme.button.color};
  border: none;
  border-radius: ${({ theme }) => theme.button?.borderRadius};
  font-weight: ${({ theme, fontWeight }) => fontWeight || theme.button.fontWeight};
  line-height: ${({ theme }) => theme.button.lineHeight};
  font-size: ${({ theme, fontSize }) => fontSize || theme.button.fontSize};
  font-family: ${({ theme }) => theme.button.fontFamily};
  text-transform: ${({ theme, uppercase }) =>
    uppercase ? 'uppercase' : theme.button.textTransform || 'none'};
  text-align: center;
  cursor: pointer;
  ${({ disabled }) => disabled && `opacity: 0.5; cursor: not-allowed;`}

  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.07), 0 7px 5px rgba(0, 0, 0, 0.04),
      0 12px 10px rgba(0, 0, 0, 0.03), 0 22px 18px rgba(0, 0, 0, 0.03),
      0 42px 33px rgba(0, 0, 0, 0.02), 0 100px 80px rgba(0, 0, 0, 0.02);
  }
`;
