import styled from 'styled-components';

import { Container, media } from '@elc/common';

export const LookHeaderWrapper = styled(Container)<{ offsetTop?: number }>`
  padding-top: 8px;
  width: 100%;
  position: sticky;
  top: 41px;
  background: #fff;
  z-index: 101;

  ${media.md`
    padding: 0 20px;
    top: 50px;
  `}

  ${media.lg`
      box-sizing: border-box;
      position:relative;
      top:0;
  `} 
  ${media.xl`
      gap: 75px;
  `}
`;
