import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import TagManager from 'react-gtm-module';
import { AppInitializer } from '@elc/common';

import { GlobalStateProvider } from './store';
import './index.css';
import './i18n';
import App from './App';
import { dataLayer } from './constants/dataLayer';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  dataLayer,
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStateProvider>
      <Router>
        <AppInitializer>
          <App />
        </AppInitializer>
      </Router>
      <ReduxToastr timeOut={3000} position="top-right" />
    </GlobalStateProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
