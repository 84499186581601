import styled from 'styled-components';
import { media, breakpoints } from '../../../utils/rwd';

export const Wrapper = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  display: flex;
  flex-direction: column;
  padding: 36px 8px;
  ${media.md`
  padding: 36px
`}
`;

export const Banner = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  ${media.md`
    flex-direction: row;
  `}
`;

export const CoverImage = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  object-fit: cover;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 36px;
  ${media.md.down`
    padding: 36px 0;
`}
`;

export const Title = styled.h1`
  font-weight: bold;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  text-transform: ${({ theme }) => theme?.learningCenter?.post?.title?.textTransform || 'none'};
  font-size: 45px;
  line-height: 60px;
  letter-spacing: 0;
  margin: 0;
  margin-bottom: 4px;
  text-align: center;
  ${media.lg`
    font-size: 60px;
    line-height: 75px;
  `}
`;

export const Text = styled.p`
  margin-bottom: 50px;
  > p {
    margin-bottom: 4px;
  }
`;

export const Description = styled.h4`
  letter-spacing: 0;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  margin-top: 16px;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.07;
  background-color: ${({ theme }) => theme.colors.black};
  margin-top: 22px;
`;

export const Attachments = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  gap: 12px;
`;

export const Images = styled(Attachments)``;

export const VideosWrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  font-family: inherit;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  margin: 24px 0;
`;

export const Video = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  width: inherit;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%;
  }
  ${media.lg`
  max-width: 50%;
`}
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  ${media.md`
  max-width: calc(50% - 6px);
`}
  ${media.lg`
  max-width: calc(33% - 6px);
`}
  height: auto;
  border-radius: ${({ theme }) => theme.borderRadius};
`;

export const EmbeddedPDF = styled.iframe`
  display: initial;
  width: 100%;
  height: 90vh;
  padding-top: 12px;
`;
