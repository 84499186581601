import Popup from 'reactjs-popup';
import styled from 'styled-components';

export const StyledPopup = styled(Popup)`
  &-content {
    width: 100%;
    max-width: 600px;
    max-height: 90%;
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: 32px 20px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
  }
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Header = styled.h1`
  font-family: ${({ theme }) => theme.fontFamily?.bold};
  text-transform: uppercase;
`;

export const Content = styled.p``;

export const Button = styled.button`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: none;
  color: white;
  background: black;
  align-self: center;
  padding: 10px 60px;
  margin-top: 16px;
`;
