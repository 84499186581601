import styled from 'styled-components';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))``;

export const TopBar = styled.div.attrs(({ className = '' }) => ({
  className,
}))<{ larger: boolean }>`
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 50px;
  z-index: 9998;
  width: 100%;
  height: ${({ theme, larger }) =>
    larger ? theme.header.topBar.size.big : theme.header.topBar.size.small};
  background: ${({ theme }) => theme?.header?.topBar?.background || '#ffffff'};
  transition: ${({ theme }) => theme.transitionTime};
`;

export const RowElement = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  display: flex;
  align-items: center;
  width: 33%;
`;

export const BrandLogoContainer = styled(RowElement)`
  justify-content: center;
`;

export const SalonLogo = styled.img.attrs(({ className = '' }) => ({
  className,
}))<{ larger?: boolean }>`
  margin-left: 5px;
  background: black;
  height: ${({ theme, larger }) => (larger ? theme.header.logos.big : theme.header.logos.small)};
  transition: ${({ theme }) => theme.transitionTime};

  ${media.sm.down`
    display: none;
  `}

  ${media.md`
    margin-left: 64px;
  `}
`;

export const BrandLogo = styled.img.attrs(({ className = '' }) => ({
  className,
}))<{ larger?: boolean }>`
  height: ${({ theme, larger }) => (larger ? theme.header.logos.big : theme.header.logos.small)};
  transition: ${({ theme }) => theme.transitionTime};
`;

export const IconsContainer = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  margin-left: auto;
  margin-right: 5px;
  ${media.md`
    margin-right: 62px;
  `}
`;
