import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { EBrand } from '../typings/enums/Brands';

export const getSizeName = (__sizeName: string): string => {
  const travelSize = '- travel size';
  const { brand } = useContext(ThemeContext);
  const [sizeInUsaUnits, sizeInEuUnits] = (__sizeName?.replace(travelSize, '') || '').split('/');

  // TO DO : should depend on region unit
  let sizeName;
  if (brand === EBrand.Aveda) {
    sizeName = sizeInUsaUnits || sizeInEuUnits;
  } else {
    sizeName = sizeInEuUnits || sizeInUsaUnits;
  }

  return sizeName;
};
