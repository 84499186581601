export const pages = {
  ROOT: '/',
  BUNDLE: 'look/:shortId',
  LOOK_NEW: '/a/:artistName/look/:shortId',
  ARTIST: 'a/:vanity_url',
  ABOUT: 'about',
  FAQ: 'customer-service-faq',
  FAQ_CONTENT_CREATORS: 'faq',
  CONTACT: 'contact',
  TERMS_OF_SERVICE: 'terms/:tabName',
  POLICY: 'policy',
  LEARNING_CENTER: 'how-to-hub',
  LEARNING_CENTER_POST: 'how-to-hub/posts/:postUid',
  FROM_LINK: 'from-link/:slug',
  PRODUCT: 'a/:vanityUrl/p/:shortId',
};
