import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSalonGroupsRequest } from '../../api/requests';
import { GET_SALON_GROUP } from './salonGroup.constants';

export const fetchSalonGroupAction = createAsyncThunk(
  GET_SALON_GROUP,
  async (uid: string, { rejectWithValue }) => {
    try {
      const { data: response } = await getSalonGroupsRequest(uid);

      if (response) {
        return response;
      }
      throw Error();
    } catch (error) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return rejectWithValue((error as any).response.status);
    }
  },
);
