import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';

const Skeleton: FC<{
  width?: number;
  height?: number;
  viewBox?: string;
  backgroundColor?: string;
  foregroundColor?: string;
  style?: React.CSSProperties;
}> = ({ children, backgroundColor = '#f3f3f3', foregroundColor = '#ecebeb', ...rest }) => (
  <ContentLoader
    speed={2}
    backgroundColor={backgroundColor}
    foregroundColor={foregroundColor}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </ContentLoader>
);

const SkeletonRectangle: FC<{
  width?: string;
  height?: string;
  x?: string;
  y?: string;
  style?: React.CSSProperties;
  // eslint-disable-next-line react/jsx-props-no-spreading
}> = ({ ...rest }) => <rect x="0" y="0" rx="2" ry="2" {...rest} />;

const ImageSkeleton = () => (
  <Skeleton
    style={{
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    }}
  >
    <SkeletonRectangle
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  </Skeleton>
);

export { Skeleton, SkeletonRectangle, ImageSkeleton };
