import { breakpoints, media, useScrollToTop } from '@elc/common';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import image from '../assets/img/about.png';

const Wrapper = styled.div`
  align-items: center;
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  height: 100%;
  min-height: calc(100vh - 380px);
  background: #f1f3f6;
  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 100%;
  margin: 20px 0px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background: #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;

  ${media.md`
    margin: 0px;
    width: 75%;
  `}

  ${media.xl`
    margin: 0px;
    width: 50%;
  `}
`;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;

  ${media.md`
    font-size: 20px;
  `}
`;

const Text = styled.p`
  ${media.md`
    font-size: 20px;
  `}
`;

const TextUppercase = styled(Text)`
  text-transform: uppercase;
`;

const About: React.FC = () => {
  const { t } = useTranslation(['app']);

  useScrollToTop();

  return (
    <Wrapper>
      <img
        alt="banner"
        src={image}
        style={{ width: '100%', aspectRatio: '16/4', objectFit: 'cover' }}
      />
      <Card className="my-5">
        <Title>{t('about.title')}</Title>
        <TextUppercase>{t('about.headline')}</TextUppercase>
        <Text>{t('about.content')}</Text>
      </Card>
    </Wrapper>
  );
};

export default About;
