import { getAvailableSocialsLinks, getFullArtistName, IFetchableItem } from '@elc/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import * as artistActions from '../actions/artist.actions';
import { TArtistState } from '../../types/MacArtist';

const initialState: IFetchableItem<TArtistState> = {
  item: {
    about: '',
    approval_status: 'APPROVED',
    uid: '',
    vanity_url: '',
    facebook_id: '',
    instagram_id: '',
    last_name: '',
    name: '',
    pinterest_id: '',
    picture: '',
    profile_page_token: '',
    locations: [],
    related_brand_content: [],
    second_name: '',
    twitter_id: '',
    tiktok_id: '',
    username: '',
    profile_uid: '',
    connected_location: {
      uid: '',
      id: 0,
      name: '',
      businessId: '',
      vanity_url: '',
      logo: '',
    },
    stores: [],
    looks: [],
    favorites: null,
    publishing_id: '',
    advertising_id: '',
    id: 0,
    artist_id: '',
    is_brand_owner: false,
    is_brand_manager: false,
    fullName: '',
    socials: [],
    is_onboarding_process_finished: false,
    regions: [],
  },
  loading: 'pending',
  status: 200,
};

const artistSlice = createSlice({
  name: 'artist',
  initialState,
  reducers: {
    setStore: (state, action: PayloadAction<TArtistState>) => {
      state.item = action.payload;
      state.loading = 'fullfield';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(artistActions.fetchArtist.fulfilled, (state, { payload }) => {
        state.loading = 'fullfield';
        state.item = payload;
        state.item.fullName = getFullArtistName(payload);
        state.item.socials = getAvailableSocialsLinks(
          pick(payload, ['facebook_id', 'instagram_id', 'twitter_id', 'pinterest_id', 'tiktok_id']),
        );
      })
      .addCase(artistActions.fetchArtist.rejected, (state, { payload }) => {
        state.loading = 'rejected';
        state.status = payload as number;
      })
      .addCase(artistActions.setArtistFavorites.fulfilled, (state) => {
        state.item.favorites = [];
      })
      .addCase(
        artistActions.fetchArtistLook.fulfilled,
        (state, { payload: { look, entityType } }) => {
          if (entityType === 'looks') {
            state.item.looks = state.item.looks.map((i) => {
              // @ts-ignore
              if (look.short_id === i.short_id) {
                return look;
              }
              return i;
            });
          } else if (look.products.length) {
            const favorite = {
              ...look.products[0],
              lookId: look.short_id,
              created_at: look.created_at,
              lookUid: look._id,
            };
            if (state.item.favorites === null) {
              state.item.favorites = [favorite];
            } else {
              state.item.favorites = [...state.item.favorites, favorite];
            }
          }
        },
      );
    builder
      .addCase(artistActions.fetchPrivateArtistProfile.fulfilled, (state, { payload }) => {
        state.loading = 'fullfield';
        state.item = payload;
        state.item.fullName = getFullArtistName(payload);
        state.item.socials = getAvailableSocialsLinks(
          pick(payload, ['facebook_id', 'instagram_id', 'twitter_id', 'pinterest_id', 'tiktok_id']),
        );
      })
      .addCase(artistActions.fetchPrivateArtistProfile.rejected, (state, { payload }) => {
        state.loading = 'rejected';
        state.status = payload as number;
      });
  },
});

export const artistAction = {
  ...artistSlice.actions,
};

export default artistSlice;
