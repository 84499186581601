import React from 'react';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { PopupProps } from 'reactjs-popup/dist/types';

const StyledPopup = styled(Popup)`
  &-content {
    background: ${({ theme }) => theme.colors.black};
    color: white;
    z-index: 9999 !important;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
  }
`;

export const Tooltip: React.FunctionComponent<PopupProps> = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledPopup position="bottom left" closeOnDocumentClick {...props}>
    {props.children}
  </StyledPopup>
);
