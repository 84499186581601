import React, { FC } from 'react';
import {
  basketSubtotalSelector,
  Button,
  formatPrice,
  TCartItem,
  useImageLazyLoading,
} from '@elc/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyBasket } from '../../assets/svg/empty-basket.svg';
import * as Ui from './basket.styles';
import { Item } from './item';

export const Content: FC<{
  basket: Array<TCartItem>;
  onCheckout: () => void;
}> = ({ basket, onCheckout }) => {
  const basketSubtotal = useSelector(basketSubtotalSelector);
  useImageLazyLoading('basket-item-image', !!basket.length);

  const { t } = useTranslation(['app']);

  return (
    <Ui.BasketContent>
      {basket.length ? (
        basket.map((item: TCartItem) => <Item key={item.sku} item={item} />)
      ) : (
        <Ui.EmptyBasketWrapper className="d-flex flex-column justify-content-center alignt-items-center">
          <Ui.EmptyBasketImageWrapper>
            <EmptyBasket />
          </Ui.EmptyBasketImageWrapper>
          <div>
            <h3>{t('basket.empty')}</h3>

            <p>Looks like you haven&apos;t added anything to your cart yet</p>
          </div>
        </Ui.EmptyBasketWrapper>
      )}
      {!!basket.length && (
        <Ui.BasketButtonContainer>
          <div className="d-flex justify-content-between">
            <h5>{t('basket.subtotal')}</h5>
            <h5>{basket.length ? formatPrice(basketSubtotal, basket[0].currency) : '-'}</h5>
          </div>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: t('basket.checkoutText') }} />
          <Button disabled={!basket.length} text={t('basket.proceed')} onClick={onCheckout} />
        </Ui.BasketButtonContainer>
      )}
    </Ui.BasketContent>
  );
};
