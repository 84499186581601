import React from 'react';

import FacebookIcon from '../../../assets/svg/socials/facebook.svg';
import InstagramIcon from '../../../assets/svg/socials/instagram.svg';
import PinterestIcon from '../../../assets/svg/socials/pinterest.svg';
import TiktokIcon from '../../../assets/svg/socials/tiktok.svg';
import { TSocial } from '../../../typings/types/Social';
import { Skeleton } from '../../Skeleton/Skeleton';
import * as Ui from './HorizontalSocials.styles';

// TODO remove after merge of MAC-325_fix

const icons = {
  facebook: <img src={FacebookIcon} alt="" />,
  instagram: <img src={InstagramIcon} alt="" />,
  pinterest: <img src={PinterestIcon} alt="" />,
  tiktok: <img src={TiktokIcon} alt="" />,
};

const validSocials = ['facebook', 'instagram', 'pinterest', 'tiktok'];

export type SocialsProps = {
  socials: Array<TSocial>;
  className?: string; // isRequired to force passing so it can be overriden in each component it is using
  ready?: boolean;
};

export const HorizontalSocials = ({ socials = [], className, ready = true }: SocialsProps) => (
  <Ui.Socials className={className}>
    {ready
      ? socials
          .filter(({ name }) => validSocials.includes(name))
          .map(({ name, link }) => (
            <a href={link} key={name} target="_blank" rel="noreferrer" className={name}>
              {icons[name]}
            </a>
          ))
      : Array.from(Array(4)).map((i) => (
          <Skeleton key={i} width={32} height={32} viewBox="0 0 32 32">
            <circle r="16" cx="16" cy="16" />
          </Skeleton>
        ))}
  </Ui.Socials>
);
