import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col } from 'react-bootstrap';
import _, { groupBy } from 'lodash';
import { sanitize } from 'dompurify';

import { useTranslation } from 'react-i18next';
import * as Ui from './LearningCenterPost.styles';
import { breakpoints } from '../../../utils/rwd';
import { youtubeUrlFromInput } from '../../../utils/youtube';
import { TPost } from '../../../typings';

const renderAttachment = ({ type, url }) => {
  const address = Array.isArray(url) ? _.flattenDeep(url)[0] : url;

  switch (type) {
    case 'video':
      // eslint-disable-next-line jsx-a11y/media-has-caption
      return <video src={address} controls />;
    case 'youtube':
      return (
        <iframe
          allowFullScreen
          width="100%"
          height="100%"
          src={youtubeUrlFromInput(address)}
          title="youtube_video"
        />
      );
    default:
      return <img src={url} alt="attachment_image" />;
  }
};

export const LearningCenterPost = ({ post }: { post: TPost }) => {
  const { t } = useTranslation(['app', 'components']);

  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });
  const groupedContent = groupBy(post.attachments, 'type');

  const images = groupedContent.image || [];

  const [pdf] = groupedContent.PDF || [];

  const videos = (groupedContent.video || []).concat(groupedContent.youtube || []);

  const columns = isSmallerThenMd ? {} : { md: 6 };

  const titleWihtoutNsbp = post.title.replaceAll('\xa0', ' ');

  return (
    <div className="w-100">
      <Ui.Wrapper>
        <Ui.Banner>
          <Col md={columns?.md}>
            <Ui.CoverImage>
              <img src={post.cover_image} alt="cover_image" />
            </Ui.CoverImage>
          </Col>
          <Col md={columns?.md}>
            <Ui.Content>
              <Ui.Title>{titleWihtoutNsbp}</Ui.Title>
            </Ui.Content>
          </Col>
        </Ui.Banner>
      </Ui.Wrapper>
      <Ui.ContentWrapper>
        <Ui.Text dangerouslySetInnerHTML={{ __html: sanitize(post.body) }} />
        {pdf && isSmallerThenMd && (
          <p>
            {t('components:postContainsPDF')}&nbsp;
            <a href={pdf.url} download="attachment.pdf">
              {t('components:clickToDownload')}
            </a>
          </p>
        )}
        <Ui.Images>
          {!!images.length && images.map((image) => <Ui.Image key={image.url} src={image.url} />)}
        </Ui.Images>
        <Ui.Attachments>
          {!!videos.length &&
            videos.map((video) => (
              <Ui.VideosWrapper key={video.url}>
                <Ui.Video>{renderAttachment(video)}</Ui.Video>
              </Ui.VideosWrapper>
            ))}
        </Ui.Attachments>
        {pdf && !isSmallerThenMd && <Ui.EmbeddedPDF src={pdf.url} />}
      </Ui.ContentWrapper>
    </div>
  );
};
