function formatPrice(
  price: string | number,
  currency: string,
  onlyDigitValues = false,
  textForZeroValue = '',
): string {
  if (!currency) {
    return '-';
  }

  const priceAsNumber = Number(price);
  const formattedPrice = Number.isNaN(priceAsNumber)
    ? '-'
    : new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency,
      }).format(priceAsNumber);

  if ((price === '0.00' || price === 0) && !onlyDigitValues) {
    return textForZeroValue || 'Free';
  }
  return formattedPrice === '-' ? '-' : formattedPrice;
}

export { formatPrice };
