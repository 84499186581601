import Popup from 'reactjs-popup';
import styled, { DefaultTheme } from 'styled-components';
import { MdClose } from 'react-icons/md';

import { media } from '../../../../utils/rwd';
import { EVariant } from '../../../../typings';
import { AddToCartButton } from '../../../AddToCartButton/AddToCartButton';

export const Wrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  width: 100%;
  height: 100%;
  min-width: 275px;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #f9f9f9;
  background-clip: border-box;
  box-sizing: border-box;
  color: #050505;
  > * {
    margin: 0;
  }
  border: 1px solid transparent;
  &:hover {
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

export const ProductDataWrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  ${media.lg`
    padding: 40px;
  `}
`;

export const StarRatingWrapper = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const Image = styled.img.attrs(({ className = '' }) => ({
  className,
}))<{ isLoading: boolean }>`
  @keyframes Pulsate {
    from {
      opacity: 0.7;
    }
    50% {
      opacity: 0.3;
    }
    to {
      opacity: 0.7;
    }
  }

  width: 100%;
  height: auto;
  margin: 0 auto;
  cursor: pointer;

  ${({ isLoading }) => isLoading && `  animation: Pulsate 1s linear infinite;`}
`;

export const ColorPicker = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  width: 100%;
  margin-top: 10px;
`;

// How to handle long names of colors that doesn't fit one line?
export const ColorName = styled.h4.attrs(({ className = '' }) => ({
  className,
}))<{ color: string; theme: DefaultTheme }>`
  font-size: ${({ theme }) => theme.productCard.colorName.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.productCard.colorName.lineHeight};
  color: ${({ color }) => color};
  margin: 2px 0 0 0;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Name = styled.h4.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  font-size: ${({ theme }) => theme.productCard.name.fontSize};
  font-family: ${({ theme }) => theme.productCard.name.fontFamily};

  font-weight: 700;
  line-height: ${({ theme }) => theme.productCard.name.lineHeight};
  text-align: center;
  text-transform: ${({ theme }) => theme.productCard.name.textTransform};
  text-transform: uppercase;
  margin: 0;
  color: #050505;
`;

export const Description = styled.div.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  font-family: ${({ theme }) => theme.productCard.description?.fontFamily};
  font-size: ${({ theme }) => theme.productCard.description.fontSize};
  line-height: ${({ theme }) => theme.productCard.description.lineHeight};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  letter-spacing: 0;
  text-align: center;
  ${media.sm.down`
      font-size: 14px;
  `}
`;

export const VariantPickerWrapper = styled.div<{ type: EVariant }>`
  margin-top: ${({ type }) => (type === EVariant.COLOR ? '8px' : '0')};
  .base-box {
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
`;

export const Price = styled.p.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  font-size: ${({ theme }) => theme.productCard.price.fontSize};

  font-weight: 400;
  line-height: ${({ theme }) => theme.productCard.price.lineHeight};
  letter-spacing: 0;
  margin: 0;
  text-align: center;
`;

export const Button = styled(AddToCartButton)`
  margin: 16px auto 0 auto;
`;

export const StyledPopup = styled(Popup)`
  &-content {
    width: 100%;
    max-height: 100vh;
    overflow: auto !important;
    border-radius: none;
    padding: 0 !important;
    ${media.md`
      max-width: 1000px;
      max-height: 80vh;
      border-radius: ${({ theme }) => theme.borderRadius};

    `}

    ${media.xxl`
      width: 60%;
    `}
  }
  &-content::-webkit-scrollbar {
    display: none !important;
  }
  &-overlay {
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const Close = styled(MdClose).attrs({
  size: 30,
})`
  margin: 10px;
  position: absolute;
  top: 0;
  right: 12px;
  cursor: pointer;
  z-index: 1;
`;
