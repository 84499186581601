import { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { EVariant, TColorWithSkuUid, TProduct, TSizeWithSkuUid, TSelectedSku } from '../../typings';
import {
  calculateColors,
  calculateSizes,
  calculateVariantsType,
  createSelectedSku,
} from '../../utils/products';

export const useProductSelection = (
  product: TProduct,
  onVariantClick?: (selectedSku: TSelectedSku) => void,
) => {
  const { brand } = useContext(ThemeContext);

  const { type, skus, variants } = useMemo(() => {
    const calculatedType = calculateVariantsType(product.sub_skus, brand);

    const productSkus = [
      product.primary_sku,
      ...product.sub_skus.filter((sku) => sku.id !== product.primary_sku.id),
    ];

    return {
      type: calculatedType,
      skus: productSkus,
      variants:
        calculatedType === EVariant.SIZE
          ? calculateSizes(productSkus)
          : calculateColors(productSkus),
    };
  }, [product._id]);

  const [selectedSku, setSelectedSku] = useState(createSelectedSku(skus[0], product, type));

  useEffect(() => {
    setSelectedSku(createSelectedSku(skus[0], product, type));
  }, [product._id]);

  const handleVariantSelection = useCallback(
    (variant: TSizeWithSkuUid | TColorWithSkuUid) => {
      const skuSelected = createSelectedSku(
        skus.filter((sku) => sku.uid === variant.skuUid)[0],
        product,
        type,
      );
      if (onVariantClick) {
        onVariantClick(skuSelected);
      }
      setSelectedSku(skuSelected);
    },

    [product._id, onVariantClick],
  );

  return {
    selectedSku,
    handleVariantSelection,
    skus,
    variants,
    type,
  };
};
