import React from 'react';
import { sanitize } from 'dompurify';
import { useTranslation } from 'react-i18next';
import { Accordion } from '../Accordion/Accordion';
import { Disclaimer, Ingredients } from './ProductDetails.styles';
import { splitIngredients } from '../../utils/products';

type ProductDetailsAccordionProps = {
  details: string | null;
  ingredients: string | null;
};

export const ProductDetailsAccordion = ({ details, ingredients }: ProductDetailsAccordionProps) => {
  const { t } = useTranslation(['components']);

  const splittedIngredients = splitIngredients(ingredients);

  const middleIndex = Math.ceil(splittedIngredients.length / 2);

  const firstHalf = splittedIngredients.slice().splice(0, middleIndex);
  const secondHalf = splittedIngredients.slice().splice(-middleIndex);

  const detailsItem = {
    header: t('details'),
    body: (
      <span
        className="pb-3 d-block"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: sanitize(details || ''), // details can be null
        }}
      />
    ),
  };

  const ingredientsItem = {
    header: t('ingredients'),
    body: (
      <Ingredients>
        <div className="column column-left">
          {firstHalf?.map((i) => (
            <li key={i}>{i}</li>
          ))}
        </div>
        <div className="column">
          {secondHalf?.map((i) => (
            <li key={i}>{i}</li>
          ))}
        </div>
        <Disclaimer>{t('ingredientsDisclaimer')}</Disclaimer>
      </Ingredients>
    ),
  };

  const accordionItems = [
    ...(details ? [detailsItem] : []),
    ...(ingredients?.length ? [ingredientsItem] : []),
  ];

  return <Accordion items={accordionItems} />;
};
