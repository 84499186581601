/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo } from 'react';
import { sanitize } from 'dompurify';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import {
  breakpoints,
  formatPrice,
  ShortArtistProfileSection,
  TProduct,
  TArtist,
  EVariant,
  EInventory,
  splitIngredients,
  analyticsChangeVariant,
  analyticsProductDetails,
} from '../..';

import ProductImageGallery from '../ProductImageGallery/ProductImageGallery';
import { Carousel } from '../Carousel/Carousel';
import * as Ui from './ProductPage.styles';
import { SizeSearch } from '../SizeSearch/SizeSearch';
import { Container } from '../Container/Container.styles';
import { useProductSelection } from '../../hooks/product/useProductSelection';
import { TColorWithSkuUid, TSelectedSku, TSizeWithSkuUid } from '../../typings';
import { getSkuInventoryBadgeText } from '../../utils/getSkuBadgeText';
import { Disclaimer, Ingredients } from '../ProductDetails/ProductDetails.styles';
import { E2EWrapper } from '../E2EWrapper/E2EWrapper';

type ProductPageProps = {
  product: TProduct;
  onAddToCart: (sku: TSelectedSku) => void;
  artist: TArtist;
  noImage: string;
};

export const ProductPage = ({ product, onAddToCart, artist, noImage }: ProductPageProps) => {
  const { t } = useTranslation(['components']);
  const isSmallerThenMd = useMediaQuery({
    query: `(max-width: ${breakpoints.md}px)`,
  });

  const { selectedSku, handleVariantSelection, variants, type } = useProductSelection(
    product,
    analyticsChangeVariant,
  );

  useEffect(() => {
    analyticsProductDetails(selectedSku);
  }, []);

  const isActive = selectedSku.inventory.toUpperCase() === EInventory.ACTIVE;

  const headerComponentForSmallerDevices = () =>
    isSmallerThenMd && (
      <div>
        <Ui.ShortArtistProfileSection
          artistPageUrl={`/a/${artist.vanity_url}`}
          artist={artist}
          showDescription={false}
          noImage={noImage}
        />

        <Ui.ProductNameWrapper>
          <Ui.ProductName dangerouslySetInnerHTML={{ __html: sanitize(product.name) }} />
          <Ui.Price>{formatPrice(selectedSku.retail_price, product.currency)}</Ui.Price>
        </Ui.ProductNameWrapper>
        {selectedSku.size?.name && <Ui.Size> {selectedSku.size?.name}</Ui.Size>}
      </div>
    );

  const selectedDefault = useCallback(
    (option) => option.skuUid === selectedSku?.uid,
    [selectedSku],
  );

  const tabsMemoized = useMemo(() => {
    const tabs: Array<{ text: string; id?: string }> = [];

    if (product.details) {
      tabs.push({ text: 'Full description', id: 'favoriteDescription' });
    }
    tabs.push({ text: 'Ingredients', id: 'favoriteIngredients' });

    if (product.recommended_usage) {
      tabs.push({ text: 'Usage', id: 'favoriteUsage' });
    }
    return tabs;
  }, [product]);

  return (
    <Container>
      <Ui.PageWrapper>
        <Ui.Container>
          {headerComponentForSmallerDevices()}
          <Ui.Content>
            {isSmallerThenMd ? (
              <Carousel images={selectedSku.images} withThumbnails={false} />
            ) : (
              <Ui.ImageGalleryColumn>
                <ProductImageGallery images={selectedSku.images} />
              </Ui.ImageGalleryColumn>
            )}

            <Ui.ProductDetailsColumn>
              {!isSmallerThenMd && (
                <ShortArtistProfileSection
                  artistPageUrl={`/a/${artist.vanity_url}`}
                  artist={artist}
                  showDescription={false}
                  noImage={noImage}
                />
              )}
              <Ui.StarRating
                count={product?.review_number || 0}
                rating={product?.review_rating || 0}
              />

              {!isSmallerThenMd && (
                <>
                  <Ui.ProductNameWrapper>
                    <Ui.ProductName dangerouslySetInnerHTML={{ __html: sanitize(product.name) }} />
                    <Ui.Price>{formatPrice(selectedSku.retail_price, product.currency)}</Ui.Price>
                  </Ui.ProductNameWrapper>
                  {type === EVariant.COLOR && selectedSku.size?.name && (
                    <Ui.Size> {selectedSku.size.name}</Ui.Size>
                  )}
                </>
              )}

              <Ui.VariantSearchWrapper>
                {type === EVariant.COLOR ? (
                  <Ui.VariantPicker
                    colors={variants as TColorWithSkuUid[]}
                    onClick={handleVariantSelection}
                    initialColorSku={selectedSku.uid}
                  />
                ) : (
                  <SizeSearch
                    sizes={variants as TSizeWithSkuUid[]}
                    onClick={handleVariantSelection}
                    selectedDefault={selectedDefault}
                  />
                )}
              </Ui.VariantSearchWrapper>

              <Ui.Description>{product.description}</Ui.Description>
              <E2EWrapper id="addToBagButton">
                <Ui.AddToBagButton
                  disabled={!isActive}
                  onClick={() => onAddToCart(selectedSku)}
                  text={isActive ? t('addToBag') : getSkuInventoryBadgeText(selectedSku, t)}
                />
              </E2EWrapper>
            </Ui.ProductDetailsColumn>
          </Ui.Content>
        </Ui.Container>
        {/* // to do add description */}
        <Ui.Tabs breakPointForColumnDirection="sm" tabs={tabsMemoized}>
          {product.details && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: product.details || 'No data',
              }}
            />
          )}
          <div>
            <Ingredients>
              {splitIngredients(product.primary_sku?.iln_listing || '')?.map((i, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <li key={idx}>{i}</li>
              ))}
            </Ingredients>
            <Disclaimer>{t('ingredientsDisclaimer')}</Disclaimer>
          </div>
          {product.recommended_usage && (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: product.recommended_usage || 'No data',
              }}
            />
          )}
        </Ui.Tabs>
      </Ui.PageWrapper>
    </Container>
  );
};
