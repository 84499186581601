import React from 'react';
import {
  EVariant,
  SAddToCart,
  SNamedOrderedProdcutDetailsClicked,
  TColorWithSkuUid,
  TProduct,
  TSelectedSku,
  TSizeWithSkuUid,
} from '../../typings';
import { ProductDetails } from '../ProductDetails/ProductDetails';
import { StyledPopup, Close } from './ProductCard.styles';

type ProductDetailsPopupProps = {
  trigger: JSX.Element;
  product: TProduct;
  type: EVariant;
  selectedSku: TSelectedSku;
  handleVariantSelection: (variant: TSizeWithSkuUid | TColorWithSkuUid) => void;
  variants: TSizeWithSkuUid[] | TColorWithSkuUid[];
  onAddToCart: SAddToCart;
  onProductDetailsClick?: SNamedOrderedProdcutDetailsClicked;
  onModalClose?: () => void;
  isActive: boolean;
};

// what is the purpose of this variables
let isOpen = false;
let isClosed = true;

export const ProductDetailsPopup = ({
  trigger,
  product,
  type,
  selectedSku,
  handleVariantSelection,
  variants,
  onAddToCart,
  onProductDetailsClick,
  onModalClose,
  isActive,
}: ProductDetailsPopupProps) => (
  <StyledPopup
    trigger={(open: boolean) => {
      if (open) {
        isClosed = false;
        if (!isOpen) {
          isOpen = true;
          onProductDetailsClick?.(selectedSku);
        }
      } else {
        if (!isClosed) {
          isClosed = true;
          onModalClose?.();
        }
        isOpen = false;
      }
      return trigger;
    }}
    modal
    contentStyle={{ overflow: 'scroll' }}
    overlayStyle={{ zIndex: 9999 }}
  >
    {(close: () => void) => (
      <>
        <Close onClick={close}>&times;</Close>
        <ProductDetails
          product={product}
          selectedSku={selectedSku}
          close={close}
          variants={variants}
          handleVariantSelection={handleVariantSelection}
          type={type}
          onAddToCart={onAddToCart}
          isActive={isActive}
        />
      </>
    )}
  </StyledPopup>
);
