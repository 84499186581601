/* eslint-disable */
/**
 * Truncates text to at most `count` sentences
 * @param text text to be shortened
 * @param count number of sentences that can be left
 * @returns truncated text or orginal one if it has less sentences than sentencesCount
 */
export const removeText = (text: string, count: number): string => {
  const sentences = text.split('.');
  if (sentences.length < count) {
    return text;
  }
  return `${sentences.slice(0, count).join('.')}.`;
};
/**
 * If tree represented by element reference has more than `count` descendants they are removed in place. Function
 * returns true if any child was removed and false otherwise.
 * E.g. :
 * <div>
 *  <span>
 *    <span>
 *      <span />
 *    </span>
 *  </span>
 *  <span />
 * </div>
 * will be transformed to:
 * <div>
 *  <span>
 *    <span />
 *  </span>
 * </div>
 * if children count is 2
 * @param element reference to html elment which descendants should be removed
 * @param count number of children that should be left
 * @returns true if at least one descendant was removed false if tree represented by element was untouched
 */
export const removeChildren = (element: HTMLElement, count = 1): boolean => {
  let removed = false;
  if (element.childNodes.length === 1) {
    const shortText = removeText(element.innerText, 5);
    if (shortText !== element.innerText) {
      element.innerText = shortText;
      removed = true;
      count = 1;
    }
  }
  const grandChildrenCount = element.children[0]?.childElementCount;

  if (grandChildrenCount > 0) {
    if (!removed) {
      removed = removeChildren(element.children[0] as HTMLElement, count);
    } else {
      count = 1;
    }
  }

  if (grandChildrenCount < 2 && element.children[1]?.childElementCount > 0) {
    if (!removed) {
      removed = removeChildren(element.children[1] as HTMLElement);
    }
  }

  let childrenCount = element.childElementCount || 0;
  while (childrenCount > count && element.lastElementChild) {
    element.removeChild(element.lastElementChild);
    childrenCount -= 1;
    removed = true;
  }
  return removed;
};

/**
 * Use DOMParser to check if given text is a valid HTML
 * @param text string which should be check if is a vaild HTML
 * @returns true if text is HTML, false otherwise
 */
export const isHTML = (text: string): boolean => {
  try {
    const fragment = new DOMParser().parseFromString(text, 'text/html');
    return fragment.body.children.length > 0;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return false;
};

/**
 * Parseres given text using DOMParser and returns list of HTML nodes.
 * WARNING - this method should be used when you are certain that text is valid HTML - use isHTML
 * @param text string which should be parsed to list of HTML nodes
 * @returns list of HTML nodes
 */
export const parseHTML = (text: string): NodeListOf<ChildNode> => {
  const fragment = new DOMParser().parseFromString(text, 'text/html');
  return fragment.body.childNodes;
};
