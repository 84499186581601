import React, { FC, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faBox } from '@fortawesome/free-solid-svg-icons';
import * as Ui from './AddToCartButton.styles';

type AddToCartButtonProps = {
  text: string;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
};

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  text,
  disabled = false,
  onClick,
  className,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleOnClick = () => {
    setClicked(true);
    onClick();
    setTimeout(() => {
      setClicked(false);
    }, 1500);
  };
  return (
    <Ui.AddToCartButton
      onClick={handleOnClick}
      type="button"
      className={`cart-button ${clicked && 'clicked'} ${className}`}
      disabled={disabled}
    >
      <span className="add-to-cart">{text}</span>
      <FontAwesomeIcon icon={faCartShopping} color="white" />
      <FontAwesomeIcon icon={faBox} color="white" />
    </Ui.AddToCartButton>
  );
};
