import styled, { DefaultTheme } from 'styled-components';
import { media } from '../../utils/rwd';

export const Wrapper = styled.div.attrs(({ className = '' }) => ({
  className,
}))`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
  background: ${({ theme }) => theme.colors.white};
`;

export const Image = styled.div`
  width: inherit;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  border-radius: ${({ theme }) => theme.borderRadius};

  img {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  ${media.lg.down`
      flex-direction: column;
  `}
`;

export const TitleWrapper = styled.div`
  padding: 12px 15px 12px 15px;
`;

export const Title = styled.h1.attrs(({ className = '' }) => ({
  className,
}))<{ theme: DefaultTheme }>`
  font-style: normal;
  font-weight: bold;
  color: ${({ theme }) => theme.singleBundle.title.color || '#ffffff'};
  font-size: 16px;
  line-height: 1.5;
  margin: 0 0 4px 0;
  text-transform: uppercase;

  ${media.md.down`
     font-size: 14px;
  `}
`;

export const SpaceBetweenDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Description = styled.div<{ theme: DefaultTheme }>`
  color: ${({ theme }) => theme.singleBundle.description.color || '#ffffff'};
  opacity: ${({ theme }) => theme.singleBundle.description.opacity || '1.0'};
  line-height: 1.5;
  font-size: 14px;
  width: 100%;
`;
