import { validSocials } from '../constants/socials/validSocials';
import { IWithSocialsHandles, IWithSocialsIds } from '../typings/interfaces/Socials';
import { TSocial } from '../typings/types/Social';

export const getAvailableSocialsLinks = (
  element: IWithSocialsIds | IWithSocialsHandles,
): Array<TSocial> =>
  validSocials
    .filter((name) => element[`${name}_id`] || element[`${name}_handle`])
    .map((name) => {
      switch (name) {
        case 'tiktok':
          return {
            name,
            link: `https://www.${name}.com/@${element[`${name}_id`]}`,
          };
        default:
          return {
            name,
            link: `https://www.${name}.com/${element[`${name}_id`] || element[`${name}_handle`]}`,
          };
      }
    });
