import React from 'react';
import YouTube from 'react-youtube';
import { TRelatedContent } from '../../../typings';
import { ImageWrapper } from '../LooksList.styles';
import { youtubeIdFromInput } from '../../../utils/youtube';
import { analiticsVideo } from '../../../utils/googleAnalitics';

export const YoutubeContent = ({ item }: { item: TRelatedContent }) => {
  const youtubeId = youtubeIdFromInput(item.url);
  return (
    <ImageWrapper disabled>
      <YouTube
        className="iframe"
        videoId={youtubeId}
        opts={{
          width: '100%',
        }}
        onPlay={(event) => analiticsVideo(event, item.resource_type, youtubeId)}
        onPause={(event) => analiticsVideo(event, item.resource_type, youtubeId, false)}
      />
    </ImageWrapper>
  );
};
