import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  div {
    flex-basis: calc(50% - 5px);
  }

  div:nth-child(5),
  div:nth-child(6),
  div:nth-child(7) {
    flex-basis: calc(33.3% - 7px);
  }

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
