import { Metatags as MetatagsComponent, TMetatags } from '@elc/common';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DEFAULT_IMAGE =
  'https://res.cloudinary.com/hausmartstaging/image/upload/v1629113568/mac-black-logo_kblurg.png';

const Metatags: React.FC<TMetatags & { image?: string }> = ({
  title,
  description,
  keywords,
  image,
  ...restProps
}) => {
  const { t } = useTranslation(['metatags']);

  return (
    <MetatagsComponent
      title={title || t('title')}
      description={description || t('description')}
      keywords={keywords || t('keywords')}
      siteName={t('siteName')}
      {...restProps}
    >
      <meta property="og:image" content={image || DEFAULT_IMAGE} />
      <meta name="twitter:image" content={image || DEFAULT_IMAGE} />
    </MetatagsComponent>
  );
};

export { Metatags };
