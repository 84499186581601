import { Accordion, breakpoints, useScrollToTop } from '@elc/common';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import enTranslations from '../translations/en.json';

const Wrapper = styled.div`
  align-items: center;
  width: 100%;
  max-width: ${breakpoints.xxl}px;
  height: 100%;
  min-height: calc(100vh - 380px);
  background: #f1f3f6;
  display: flex;
  flex-direction: column;
`;

const Answer = styled.div`
  color: #5c5c5c;
`;

const Faq: FC<{ type: 'contentCreators' | 'customers' }> = ({ type }) => {
  const { t } = useTranslation('faq');

  useScrollToTop();

  const faqData: Array<{ header: string; body: JSX.Element | string }> =
    Object.keys(enTranslations.faq[type])
      .map((key) => {
        if (key.includes('question')) {
          const index = key.split('question')[1];

          return {
            header: t(`${type}.${key}`),
            body: (
              <Answer
                dangerouslySetInnerHTML={{
                  __html: t(`${type}.answer${index}`),
                }}
              />
            ),
          };
        }
        return {
          header: '',
          body: '',
        };
      })
      .filter((i) => i.header) || [];

  return (
    <Wrapper>
      <div className="container my-5">
        <h2
          className="mb-5"
          style={{
            letterSpacing: 4,
            textAlign: 'center',
            textTransform: 'uppercase',
          }}
        >
          {t('faq')}
        </h2>
        <Accordion items={faqData} />
      </div>
    </Wrapper>
  );
};

export default Faq;
