import React from 'react';

import * as Ui from './ProductImageGallery.styles';

type TProductImageGalleryProps = {
  images: Array<string>;
};

const ProductImageGallery = ({ images = [] }: TProductImageGalleryProps) => (
  <Ui.Wrapper>
    {images.map((url) => (
      <div key={url}>
        <img src={url} alt="presentation" />
      </div>
    ))}
  </Ui.Wrapper>
);

export default ProductImageGallery;
